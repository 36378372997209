.CatalogPage-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f2f3f4;
}
.CatalogPage-inner {
  max-width: 1096px;
  width: 100%;
}
.CatalogPage-inner h2 {
  font-size: 48px;
  font-weight: 600;
  color: #001020;
}
.hideCatalogItem {
  display: none;
}

.CatalogGrid-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -32px;
  background-color: #f2f3f4;
}
.CatalogGrid-inner {
  max-width: 1096px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 64px;
}
.CatalogItem {
  animation: openup2 0.5s ease-in-out;
}
.CatalogGrid-outer {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.CatalogOpenBtn,
.CatalogCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border: 0px;
  background-color: #04468c;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #001020;
  cursor: pointer;
}
.CatalogCloseBtn {
  background-color: #cccfd2;
}

.CatalogItem {
  display: flex;
  justify-content: space-between;
}
.CatalogItem-inner {
  max-width: 648px;
}
.CatalogItem img {
  max-width: 200px;
}
.CatalogGrid-item:first-child {
  border-top: 1px solid #cccfd2;
}
.CatalogGrid-item h4 {
  font-size: 24px;
  font-weight: 600;
  color: #001020;
  margin-bottom: 8px;
}
.CatalogGrid-item p {
  color: #667079;
}

.CatalogGrid-item a:last-child {
  background-color: #cccfd2;
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 24px;
  color: #001020;
  margin-left: 8px;
}
.CatalogGrid-item a:first-child {
  margin-left: 0px;
  padding: 8px 16px;
  border-radius: 24px;

  background-color: #04468c;
  color: white;
  font-size: 13px;
  border: none;
}

.CatalogGrid-item {
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid #cccfd2;
}
.CatalogItem-Links {
  margin-top: 32px;
}
.CatalogItem {
  padding-top: 32px;
  padding-bottom: 32px;
}
