.Header-top {
  display: grid;
  z-index: 10;
  grid-template-columns: 60% 40%;
}
.Header-menu1 {
  z-index: 3;
}
.Header-menu1 ul {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: white;
}
.Header-menu1 ul li a {
  color: white;
  text-decoration: none;
}
#product-card-link-icon {
  padding-top: 3px;
  margin-left: 4px;
}
.stickyScroll {
  position: fixed !important;
  margin-top: 0px !important;
  top: 72px;
  animation: SlideHeaderAnimation2 400ms;
}
.Header-menu1 ul li {
  padding-left: 24px;
}
.Header-menu1 ul li:nth-child(2) {
  padding-left: 40px;
  margin-left: 0px;
}
.Header-menu1 ul li:first-child {
  padding-left: 1px;
}
.Header-menu2 ul {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 115px;
  justify-content: right;
  color: white;
  max-width: 1000px;
}
.Header-menu2 ul li {
  padding-right: 16px;
}
.Header-wrapper {
  position: relative;
}
.header-banner {
  width: 100%;
  margin-top: -73px;
  height: 720px;
}
.header-image {
  width: 102%;
  height: 720px;
  background-size: cover;
  position: relative;
  z-index: -2;
}
.header-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.header-texts {
  width: 100%;
  max-width: 1096px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 67px;
  z-index: 1;
}
.Header-wrapper .sticky {
  position: fixed;
  width: 100%;
  max-width: unset;
  top: 0px;
  left: 0px;
}
.header-text-left div {
}
.header-text-left div h2,
.header-text-left div h1 {
  font-size: 72px;
  letter-spacing: -2px;
  /*overflow-wrap: break-word;*/
  hyphens: auto;
  color: white;
  font-weight: 600;
}
.header-text-left div h1 span {
  font-weight: 600;
  white-space: nowrap;
}
.header-text-left div button {
  background-color: #04468c;
  border-radius: 24px;
  margin-top: 32px;
  color: white;
  font-size: 13px;

  padding: 16px 32px 16px 32px;
  border: 0px;
  cursor: pointer;
}
.header-text-left div h4,
.header-text-left div p {
  margin-top: 24px;
  font-size: 16px;
  color: white;
  letter-spacing: -0.32px;

  line-height: 24px;
}

#brand-line {
  color: white;
  opacity: 1;
  font-size: 12px;
  letter-spacing: 0.1em;
  margin-top: 80px;
  height: 16px;
}
.header-selection {
  width: 100%;

  position: absolute;
}
#languagemenu {
  position: relative;
  z-index: 2;
}
.smalllanguagemenu {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: white;
  min-width: 164px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px;
  border-radius: 16px;
}

.SelectLangMenuSmall-Square {
  width: 16px;
  height: 16px;
  background-color: white;
  position: absolute;

  bottom: -8px;
  left: 0px;

  transform: rotate(45deg);
}
.smalllanguagemenu button img {
  margin-left: 5px;
  margin-top: 9px;
}
.smalllanguagemenu .normal {
  background-color: white;
}
#changeregion {
  background-color: white;
}
#changeregion img {
  margin-left: 5px;
}
.header-selection-menu a {
  color: black;
  text-decoration: none;
}
.header-selection-menu {
  float: right;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 600px;
  margin-top: -80px;
  background-color: white;
  height: 80px;
  z-index: 40;
}
.header-selection-menu div {
  display: flex;
  justify-content: center;
  position: relative;
}
.header-selection-menu div div {
  display: block;
  justify-content: center;
  position: relative;
}
.header-selection-menu div a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}
.header-selection-menu div a svg {
  margin-left: 8px;
}
.header-selection-menu div a:hover {
  transition: 0.5s;
}
#Header-menu-shopcards {
  display: none !important;
}
.header-selection-menu div a svg {
  fill: #f2f3f4 !important;
}
.header-selection-menu div a svg path {
  color: #001020 !important;
}
.header-selection-menu div a:hover svg {
  fill: #04468c !important	;
  transition: 0.5s;
}
.header-selection-menu div a:hover svg path {
  fill: #f2f3f4 !important;
  transition: 0.5s;
}
.header-selection-menu p {
  margin-top: 20px;
  color: #333;
  opacity: 0.8;
}
#languagemenu img {
  cursor: pointer;
}
.smalllanguagemenu button {
  position: relative;
  cursor: pointer;
  padding: 16px 8px 16px 8px;
}
.smalllanguagemenu button img {
  width: 20px;
  position: absolute;
  left: -2px;
  top: 3px;
}
.header-text-wrap {
  width: 100%;
  max-width: 2400px;
}
.Header-wrapper .sticky {
  height: 72px;
  /*animation: SlideHeaderAnimation 200ms ease-in-out;*/

  width: 100%;
}
.HeaderMainpage-Submenuul-headline {
  font-weight: 600;
  color: #667079;
  text-transform: uppercase;
  margin-bottom: 24px;
  padding-left: 24px;
}

#HeaderMainpage-Submenudiv-1 .HeaderMainpage-Submenuul-headline {
  padding-left: 0px;
}
#HeaderMainpage-Submenudiv-1 ul li {
  padding-left: 0px !important;
}
#HeaderMainpage-Submenudiv-1 {
  border-right: 1px solid #cccfd2;
}
#img-fake-configurator {
  background-position-y: -37px !important;
  background-image: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/configrailing00.webp');
}
.sticky #Header-top {
  max-width: 1320px;
  margin: 0 auto;
  width: 100%;
}
.HeaderSlim-wrapper {
  height: 72px;
}
.Header-wrapper {
  width: 100%;
}
#LanguageMenuFlags {
  margin-top: 24px;
}

#LanguagemenuInternationalMenu svg,
#LanguageMenuFlags svg {
  width: 24px;
  height: 18px;
  border-radius: 3px;
  margin-right: 8px;
}
.lang-dev-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.activelang {
  border: 1px solid #04468c;
  background-color: #04468c;
  color: white;
}
#searchmenu {
  display: flex;
  margin-top: -5px;

  z-index: 300;
}
#searchmenu img {
  cursor: pointer;
}
.suggestrender {
  position: absolute;
  left: -100px;
  z-index: 100;
}
.suggestmainpage {
  position: absolute;
  margin-top: 70px;
  width: 100%;
  left: 0%;
  margin-top: -27px;
  background-color: white;
  z-index: 200;
}
.langtogglemenu button img {
  height: 20px;
}
.Header-menu2 ul li:first-child a {
  text-decoration: none;
  color: white;
}
.SuggestProduct a {
  display: flex;
  text-decoration: none;
  font-size: 12px;
  color: black;
  align-items: center;
}
.SuggestProduct {
  width: 350px;
  background-color: white;
}

.HeaderSlim .Header-menu1 ul li a,
.HeaderSlim .Header-menu2 ul li:first-child a {
  color: #001020;
}
#Qrailings {
  display: flex;
  height: 100%;
  align-items: center;
}
#Qrailingshop #QrailingshopLink,
#Qrailingsconfigurator #QrailingsconfiguratorLink,
#Qrailings #QrailingsLink,
#Qrailingservice #QrailingserviceLink {
  padding-bottom: 5px;
}

#QrailingInspiration {
  padding-top: 20px !important;
  margin-top: 0px !important;
}
#QrailingInspiration #QrailingInspirationLink {
  padding-bottom: 5px;
}

#Qrailingshop #QrailingshopLink,
#Qrailingsconfigurator #QrailingsconfiguratorLink,
#QrailingInspiration #QrailingInspirationLink,
#Qrailings #QrailingsLink,
#Qrailingservice #QrailingserviceLink {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px; /* adjust as needed */

  transition: background-size 0.3s ease-in-out;
}

#Qrailingshop:hover #QrailingshopLink,
#Qrailingsconfigurator:hover #QrailingsconfiguratorLink,
#QrailingInspiration:hover #QrailingInspirationLink,
#Qrailings:hover #QrailingsLink,
#Qrailingservice:hover #QrailingserviceLink {
  background-size: 100% 2px;
}

#Qrailingshop,
#Qrailingsconfigurator {
  display: flex;
  height: 100%;
  align-items: center;
}
.Header-menu2 ul li:last-child {
  padding-right: 0px !important;
}
#Qrailings:hover #Qrailingssubmenu {
  display: flex;
  width: 100%;
  animation: SlideHeaderAnimation2 300ms ease-in-out;
}
.ResponsiveMenu-PlusButton {
  background: white !important;
  height: 48px !important;
  margin-right: 16px !important;
  width: 48px !important;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ResponsiveInspirationMenu a:last-child {
  max-width: 100px;
  background: #ffffff !important;
  height: 48px !important;
  margin-right: 16px !important;
  width: 48px !important;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResponsiveMenuHeaderLink-button {
  width: calc(100% - 96px);
  font-size: 32px;
  height: 80px;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: #001020;
  display: flex;
  margin-left: 16px;
  justify-content: space-between;
}
#Qrailingservice:hover #Qrailingssubmenu2 {
  display: flex;
  width: 100%;
  animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Qrailingsconfigurator:hover #Qrailingssubmenu4 {
  display: flex;
  width: 100%;
  animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Qrailingshop:hover #QrailingssubmenuShop {
  display: flex;
  width: 100%;
  animation: SlideHeaderAnimation2 300ms ease-in-out;
}
#Q-railing-menu-shadow,
#Q-railing-menu-shadow-1,
#Q-railing-menu-shadow-2 {
  /*display: none;
    position: fixed;
    background-color: #001020;
    opacity: 0.6;
    transition: 0.3s;
    height: calc(100vh - 648px);
    width: 100%;
    z-index: 10000;
    backdrop-filter: blur(1000px);
    bottom: 0px;
    animation: SlideHeaderAnimation3 700ms ease-in-out;*/

  display: none;
  position: fixed;
  background-color: rgba(0, 16, 32, 0.6); /* Use rgba for background color with opacity */
  transition: 0.3s;
  height: calc(100vh - 648px);
  width: 100%;
  z-index: 10000;
  backdrop-filter: blur(8px); /* Adjust blur value */
  bottom: 0px;
  animation: openup2 500ms ease-in-out;
}

#Q-railing-menu-shadow-2 {
  height: calc(100vh - 644px);
}

.Headerpopup {
  height: calc(100vh - 678px) !important;
}
.ResponsiveMenu-BottomLinks-1 svg,
.ResponsiveMenu-BottomLinks-2 svg {
  margin-right: 16px;
}

@media (max-width: 1024px) {
  #Q-railing-menu-shadow-2 {
    display: none !important;
  }
}
.stickysearch {
  position: fixed;
  top: 72px;
}
.stickysearchlink {
  position: fixed !important;
  top: 582px;
}

#Qrailingssubmenu,
#Qrailingssubmenu2,
#Qrailingssubmenu3,
#Qrailingssubmenu4,
#QrailingssubmenuShop {
  position: absolute;
  display: none;
  left: 0px;
}
#Qrailingssubmenu ul,
#Qrailingssubmenu2 ul,
#Qrailingssubmenu3 ul,
#Qrailingssubmenu4 ul,
#QrailingssubmenuShop ul {
  display: flex;

  flex-direction: column;
  background-color: #f2f3f4;
}
#Qrailingssubmenu ul li a,
#Qrailingssubmenu2 ul li a,
#Qrailingssubmenu3 ul li a {
  color: black;
}

#Qrailingssubmenu ul li,
#Qrailingssubmenu2 ul li,
#Qrailingssubmenu3 ul li,
#Qrailingssubmenu4 ul li {
  text-align: start;
  width: 300px;

  padding-left: 0px;
}
#Qrailingservice {
  display: flex;
  height: 100%;
  align-items: center;
}
.systemheader-image {
  height: 720px;
  background-image: none;
}
.systemheader-text {
  margin-top: 250px;
}
#responsivemenuitems {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  background-color: #f2f3f4;
  color: black;
  z-index: 2;

  animation: 200ms slideinrespmenu;
  margin-top: 107px;
  overflow-y: scroll;
  height: 100%;
}

.HeaderMainpage-CardWrapInner {
  text-align: left;
  width: 100%;
  padding-left: 24px;
}

#responsivemenuitems .HeaderMainpage-Submenu-Card img {
  margin-right: 16px;
}
.HeaderMainpage-whatsNewLink {
  color: #667079 !important;
  font-size: 16px;
  margin-top: 8px;
}
.HeaderMainpage-Submenu-Card h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}
.HeaderMainpage-Submenuul li {
}
#Qrailingshop li {
  margin-top: 16px;
}
#Qrailingsconfigurator li {
  margin-top: 16px;
  font-weight: 600;
}

#RegisterLinkResponsive {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0px;
}
#RegisterLinkResponsive a svg {
  margin-right: 0px;
}
#RegisterLinkResponsive a {
  margin-right: 16px;
  border-top: 1px solid #cccfd2;
}
#Qrailings li {
  font-weight: 600;
  margin-top: 16px;
}
#Qrailings li:first-child,
#Qrailingsconfigurator li:first-child,
#Qrailingshop li:first-child {
  margin-top: 0px;
}
#Qrailingservice .HeaderMainpage-Submenuul li {
  margin-bottom: 8px;
}

.ResponsiveMenu-BottomLinks-1 {
  position: absolute;
  bottom: 47px;
  background-color: white;
  left: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  margin-right: 16px;
  width: calc(100% - 16px) !important;
}
.ResponsiveMenu-BottomLinks-2 {
  position: absolute;
  bottom: 111px !important;
  background-color: white;
  left: 0px;
  font-size: 16px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  margin-right: 16px;
  width: calc(100% - 16px) !important;
}

.Qrailingssubmenu-innerService {
  max-width: 1096px !important;
  padding-bottom: 72px;
  height: 100%;
}
.ResponsiveMenu-BottomLinks-2 {
  position: absolute;
  bottom: 53px;
  background-color: white;
  left: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: calc(100% - 16px) !important;
  padding-left: 16px;
}
#responsivemenuitems li {
  display: flex;
  align-items: center;
}
#responsivemenuitems li:first-child {
  margin-top: 64px;
}

#responsivemenuitems .HeaderMainpage-SwoopMenu {
  margin-top: 0px !important;

  animation: 200ms openup2;
}
#responsivemenuitems li a {
  width: calc(100% - 72px);

  font-size: 32px;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: #001020;
  display: flex;
  justify-content: space-between;
}
#responsivemenuitems .HeaderMainpage-CardWrapInner a,
#responsivemenuitems .HeaderMainpage-Submenu-Card a,
.ResponsiveMenu-BottomLinks-1 {
  font-size: 16px !important;
  color: #001020 !important;
}
.ResponsiveMenuHeaderLink {
  height: 80px;
}

#responsivemenuitems .ResponsiveMenuHeaderLink:first-child svg {
}
#resp-menu li button:hover {
  transform: scale(1);
  opacity: 0.9;
  cursor: pointer;
  transition: 0.3s;
}
#ResponsiveSearchIcon {
  margin-right: 16px;
  cursor: pointer;
}

#resp-menu .Searchresults-search img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}
.HeaderMainpage-SwoopMenu {
  min-height: 528px !important;
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: flex-start !important;
  padding-left: 16px;
  height: 100%;
  overflow-y: scroll;
  background-color: #f2f3f4;
  padding-bottom: 32px;
}
#SupportResponsive-Header-line2 {
  padding-top: 40px;
  border-top: 1px solid #cccfd2;
  margin-top: 24px;
}
.HeaderMainpage-whatsNewLink {
  height: unset !important;
  margin-top: 16px;
  text-decoration: underline;
}
.HeaderMainpage-Submenu-Cards {
  background-color: #f2f3f4;

  width: 100%;
  margin-top: 48px;
}
.HeaderMainpage-Submenu-Card2 {
  margin-top: 24px;
}
.HeaderMainpage-Submenu-Card {
  display: flex;
  width: 100%;

  background-color: white;
  justify-content: space-between;
}
#Qrailingsconfigurator:hover #Q-railing-menu-shadow-animation-fix,
#Qrailingshop:hover #Q-railing-menu-shadow-animation-fix,
#Qrailingservice:hover #Q-railing-menu-shadow-animation-fix,
#Qrailings:hover #Q-railing-menu-shadow-animation-fix {
  display: block;
}
#Q-railing-menu-shadow-animation-fix {
  display: none;
  position: absolute;
  background-color: #f2f3f4; /* Use rgba for background color with opacity */
  height: 578px;
  width: 100%;
  z-index: 999;
  backdrop-filter: blur(8px); /* Adjust blur value */
  top: 72px;
  left: 0px;
}

.HeaderMainpage-CardWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.HeaderMainpage-SwoopMenu div h4 {
  color: #667079;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
}
#HeaderMainpage-SwoopMenu-close {
  margin-top: 24px;
  margin-bottom: 32px;
}
.HeaderMainpage-Submenu li {
  border: none !important;
  box-shadow: none !important;
}
.HeaderMainpage-Submenu li a {
  margin-left: 0px !important;
  font-size: 32px;
  letter-spacing: -0.96px;
  line-height: 40px;
  color: #667079 !important;
  font-weight: 600;
}
.HeaderMainpage-Submenu li a:hover {
  color: #001020 !important;
}
#responsivemenuitems li a:first-child {
  margin-left: 16px;
}
#responsivemenuitems li button {
  background-color: transparent;
  border: none;
  margin-right: 16px;
}
.HeaderMainpage-Submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#responsivemenuitems li a img {
  width: 32px;
  height: 32px;
  opacity: 0.8;
  margin-right: 16px;
}
#HeaderSystemDetail-buynowbutton {
  display: none;
  margin-top: 24px;
  padding: 16px 24px;
  background-color: #04468c;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 184px;
  border-radius: 24px;
}
#HeaderSystemDetail-Configure {
  display: none;
  margin-top: 8px;
  padding: 16px 24px;
  background-color: #001020;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 184px;
  border-radius: 24px;
}
.systemheader-text #SystemCategoryHeaderNormal {
  font-size: 72px;
  line-height: 80px;
  font-weight: 200 !important;
}
.systemheader-text {
  color: #ffffff;
}
.systemheader-text h2 h3,
.systemheader-text h1 h3 {
  font-weight: 600 !important;
  font-size: 72px;
  margin-bottom: 24px;
}
.systemheader-text h4 {
  font-size: 16px !important;
  max-width: 536px;
  font-weight: 300;
  color: white;
  letter-spacing: -0.32px;
}
.systemheader-text-wrap {
  top: 36%;
}
.SystemsSpecials-wrap {
  width: 100%;
  display: flex;
  background-color: #f2f3f4;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}
.SystemSpecials ul li {
  list-style: none;
  width: 100px;
  display: flex;
  align-items: center;
}
.SystemSpecials {
  display: flex;
  justify-content: center;
  max-width: 1096px;
  width: 100%;
}
.SystemSpecials ul:last-child {
  display: flex;
  justify-content: flex-end;
}
.SystemSpecials ul:last-child button {
  background-color: #04468c;
  color: white;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
.SystemSpecials ul {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 50%;
}
.SystemSpecials ul li button {
  border: none;

  cursor: pointer;
  text-align: left;
}
.SystemSpecials ul li:nth-child(4) {
  width: 55px;
}
.SystemSpecials ul li:nth-child(1) {
  width: 80px;
}
.SystemSpecials ul li:nth-child(5) {
  width: 75px;
}
.Stickcenter {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  left: 50%;
  z-index: 10000;
}
.sticky2 {
  position: fixed;
  top: 50px;
  z-index: 999;
  background-color: #f2f3f4;
  width: 100%;
  border-bottom: 1px solid #eee !important;
}
#registerHeaderMenu a:visited {
  color: unset;
}
#registerHeaderMenu a {
  color: white;
}
.subheader-headernew {
  max-width: 560px;
}

#Header-Customer-Support {
  z-index: 300;
  margin-top: -5px;
  border-right: 1px solid white;
  margin-right: 24px;
  padding-right: 24px;
}
#Header-Customer-Support button,
#Header-Customer-Support a {
  background-color: transparent;
  border: 0px;
  color: white;
  font-size: 15px;
  position: relative;
}

.sticky #Header-Customer-Support {
  border-right: 1px solid #001020;
}
.sticky #Header-Customer-Support button {
  background-color: transparent;
  border: 0px;
  color: #001020;
  font-size: 15px;
  position: relative;
}
#CloseChatBtnHeader {
  position: fixed;
  right: 32px;
  background-color: transparent;
  border: 0px;

  bottom: 457px;
  padding: 16px;
  z-index: 9999;
  cursor: pointer;
} /*
#Header-Customer-Support button::after {
    content: "";
    position: absolute;
    top: -1px;
    right:-10px;
    width: 2px;
    height: 2px;
    background-color: darkgreen;
    border-radius: 50%;
    border: 3px solid green
  }*/

#ChatIFrameLoading {
  position: fixed;
  width: 338px;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0px;
  background-color: #04468c;
  z-index: 100;
}
#ChatLinkResponsive {
  margin-right: 16px;
}

.HeaderSlim-wrapper #registerHeaderMenu a {
  color: #001020 !important;
}
.HeaderSlim-wrapper #Header-Customer-Support button {
  color: #001020 !important;
}
.HeaderSlim-wrapper #Header-Customer-Support {
  border-right: 1px solid #001020 !important;
}

#ChatLogoHeader {
  position: absolute;
  left: -24px;
  margin-top: -1px;
}
#ChatIFrameLoading img {
  animation: rotate 2s linear infinite;
}
.blackwhiteheader #Header-Customer-Support {
  border-right: 1px solid #001020 !important;
}
.blackwhiteheader #Header-Customer-Support button {
  color: #001020;
}
#ChatLogo-anchor {
  color: #001020 !important;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUpChat {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
#Header-Customer-Support button,
#Header-Customer-Support a {
  background-color: transparent;
  border: 0px;
  color: white;
  font-size: 15px;
  margin-top: -10px;
}
.fully {
  position: fixed !important;
  z-index: 3000;
  top: 0px;
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
}
.fully div {
  width: 200px;

  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#Q-railing-Mainpage-Header-Wrap .header-top-wrap {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.header-top-wrap {
  height: 72px;
}

#SystemCategoryHeaderMain {
  font-weight: 600 !important;
  font-size: 72px;
  line-height: 80px;
  max-width: 648px;
}
#SystemCategoryHeaderSubtext {
  font-size: 16px;
  margin-top: 24px;
}
#Qrailingssubmenu .Qrailingssubmenu-wrap,
#Qrailingssubmenu2 .Qrailingssubmenu-wrap,
#Qrailingssubmenu3 .Qrailingssubmenu3-wrap,
.ShopmenuHeaderWrap,
#Qrailingssubmenu4 .Qrailingssubmenu4-wrap {
  height: unset;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f3f4;
  z-index: 1000;
  height: 576px;
  color: #001020;
  margin-top: 34px;
  /*box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
   border-bottom: 2px solid rgba(255, 255, 255, 0.1);*/
  flex-direction: column;
  animation: 600ms openup2;
}
.ShopmenuHeaderWrap {
  margin-top: 34px;
}

.HeaderMainpage-Submenu-Card #product-card-link-icon {
  width: 15px;
  height: 15px;
}
#Qrailingssubmenu ul,
#Qrailingssubmenu2 ul {
  height: unset;
}
.Qrailingssubmenu-inner .img-fake,
.Qrailingssubmenu-inner .img-fake {
  max-height: 448px;
  height: 448px;
  animation: 700ms SlideHeaderAnimation4;
  background-size: cover;
  background-position-y: -24px;
  background-image: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/railingg0.webp');
}
.Qrailingssubmenu-innerService-wrap {
}

.Qrailingssubmenu-inner {
  width: 100%;
  max-width: 1440px;
  display: grid;
  padding-top: 64px;
  animation: 500ms SlideHeaderAnimation4;
  margin: 0 auto;
  grid-template-columns: 378px 1fr 1fr;
}
.Qrailingssubmenu-innerService {
  width: 100%;
  max-width: 1440px;
  display: grid;
  padding-top: 64px;
  animation: 500ms SlideHeaderAnimation4;
  margin: 0 auto;
  grid-template-columns: 312px 440px 1fr;
}
.Qrailingssubmenu3-inner {
  height: 420px !important;
  animation: 500ms SlideHeaderAnimation4;
}
.header-submenu-headline {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.Qrailingssubmenu-inner h2 {
  width: 200px;

  text-align: left;
}
.HeaderMainpage-Submenuul li a {
  color: #cccfd2 !important;
  font-weight: 600;
  letter-spacing: -0.48px;
}
.HeaderMainpage-Submenuul li a:hover {
  color: #001020 !important
    ;
  font-weight: 600;
}
.Qrailingssubmenu-innerService ul:first-child {
  border-right: 1px solid #cccfd2;
}
#HeaderMainpage-Submenudiv-1 ul:first-child {
  border-right: 0px solid #cccfd2;
}
.Qrailingssubmenu-innerService ul:first-child li:last-child {
}
.Qrailingssubmenu-innerShop ul:first-child li:last-child {
}
.Qrailingssubmenu-innerService .HeaderMainpage-Submenu-Cards {
  padding-left: 24px;
  border-left: 1px solid #cccfd2;
}
.Submenu-buttons-Shop {
  position: absolute;
  bottom: 0px;
}
.Submenu-buttons-Shop-inner {
}
#Qrailingssubmenu ul li,
#Qrailingssubmenu2 ul li,
#Qrailingssubmenu3 ul li,
#Qrailingssubmenu4 ul li,
#QrailingssubmenuShop ul li {
  width: 100%;
  display: flex;
  padding-left: 24px;
  justify-content: flex-start;
  position: relative;
  z-index: 1000;
}

#Qrailingssubmenu ul li:first-child a,
#Qrailingssubmenu2 ul li:first-child a,
#Qrailingssubmenu3 ul li:first-child a,
#Qrailingssubmenu4 ul li:first-child a {
  color: #001020;
}
#Qrailingssubmenu ul li a,
#Qrailingssubmenu2 ul li a,
#Qrailingssubmenu3 ul li a,
#Qrailingssubmenu4 ul li a,
#QrailingssubmenuShop ul li a {
  font-size: 24px;
  height: 100%;
  color: #cccfd2;
}

#Qrailingssubmenu ul li:first-child,
#Qrailingssubmenu2 ul li:first-child,
#Qrailingssubmenu4 ul li:first-child,
#Qrailingssubmenu3 ul li:first-child,
#QrailingssubmenuShop ul li:first-child {
  margin-top: 0px;
}

.Submenu-buttons {
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #f2f3f4;
}
.Submenu-buttons-Shop-inner {
  grid-template-columns: unset !important;
  max-width: unset !important;
}
.Submenu-buttons-inner div a {
  color: #001020 !important;
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
}
.Submenu-buttons-inner div a:first-child {
  margin-left: 32px;
  margin-right: 40px;
}
.Submenu-buttons-inner div a img,
.Submenu-buttons-inner div a svg {
  margin-left: 8px;
}
.Submenu-buttons-inner div a svg {
  fill: #f2f3f4 !important;
}
.Submenu-buttons-inner div a svg path {
  fill: #001020 !important;
}
.Submenu-buttons-inner div a:hover {
  opacity: 0.8;
}
.Submenu-buttons-inner div a:hover svg {
  fill: #04468c !important;

  transition: 0.8s;
}
.Submenu-buttons-inner div a:hover svg path {
  fill: white !important;
  transition: 0.8s;
}
.Submenu-buttons-inner div {
  display: flex;
}
.Submenu-link-side {
  background-color: white;
}

.suggestmainpage {
  animation: SlideHeaderAnimation2 300ms ease-in-out;
}
.Submenu-buttons-inner {
  width: 100%;
  display: grid;
  grid-template-columns: 172px 1fr;
  margin: 0 auto;
  text-align: left;
}

#responsive-logo {
  width: 50px;
  height: 50px;
}
.Header-menu2 {
  max-height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Header-menu2 a {
  font-size: 15px;
}

.Qrailingssubmenu-inner .img-fake,
.Qrailingssubmenu-inner .img-fake {
  max-height: 448px;
  height: 448px;
  width: 562px;
  background-size: cover;
  background-image: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/railingg0.webp');
}
.SystemsSpecials-wrap {
  display: none;
}
#registerHeaderMenu {
  margin-top: -5px;
  z-index: 300;
}
#registerHeaderMenu {
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid white;
}
.blackwhiteheader #registerHeaderMenu,
.HeaderSlim-wrapper #registerHeaderMenu {
  border-right: 1px solid #001020;
}
.sticky #registerHeaderMenu,
.HeaderSlim-wrapper #registerHeaderMenu {
  border-right: 1px solid #001020;
}
.HeaderMainpage-Service-headline {
  color: #667079;
  width: 100%;
  padding-bottom: 24px;
}
.HeaderMainpage-Service-headline h4 {
  font-weight: 600;
  text-transform: uppercase;
}
.HeaderMainpage-Submenuul {
  position: relative;
}
#HeaderMainpage-ServiceHeadline2 {
  padding-left: 24px;
}
.Qrailingssubmenu-innerShop {
  height: 100%;
}
.Qrailingssubmenu-innerService .HeaderMainpage-Submenu-Cards {
  margin-top: 0px;
}
.Qrailingssubmenu-innerShop {
  /*grid-template-columns: 1fr 1fr !important;*/
}
#Qrailingshop,
#Qrailingsconfigurator,
#QrailingInspiration,
#Qrailings,
#Qrailingservice {
  padding-bottom: 26px !important;
  margin-top: 10px;
  padding-top: 16px;
}
#Headerprojects-second-system {
  margin-left: 8px;
  background-color: #001020;
}

.Header-SystemDetail-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.SystemDetailHeaderWrap {
  height: 720px;
}
.ProjectsNewFlags {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
}
.ProjectsNewFlag-flag {
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 8px 12px;

  margin-top: 8px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 24px;
}
#UsedSystem {
  opacity: 0;
  display: none;
  pointer-events: none;
}

.stickybannernobar {
  margin-top: 0px;
}
.stickybanner {
  margin-top: -55px;
}

@keyframes SlideHeaderAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes SlideHeaderAnimation2 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes SlideHeaderAnimation3 {
  0% {
    opacity: 0;

    transform: translateY(3000px);
  }
  80% {
    transform: scaleX(1.1);
  }
  100% {
    transform: translateY(0px);
    opacity: 0.6;
  }
}
@keyframes SlideHeaderAnimation4 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes openup3 {
  from {
    opacity: 0.6;
    height: 0px;
  }
  to {
    opacity: 1;
    height: 648px;
  }
}

@keyframes openup2 {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes foldDown {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes openup4 {
  0% {
    opacity: 0;

    z-index: -1;
    transform: scale(0);
  }
  70% {
    opacity: 0.5;

    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    z-index: 3;

    transform: scale(1);
  }
}

@keyframes slideinrespmenu {
  0% {
    opacity: 1;

    transform: translateX(-300px);
  }
  80% {
    opacity: 1;

    transform: translateX(5px);
  }
  100% {
    opacity: 1;

    transform: translateX(0px);
  }
}
.HeaderShopSubcategoryClass a {
  height: unset;
  justify-content: left;
  width: 100% !important;
  background-color: #f2f3f4 !important;
  color: #001020 !important;
}
