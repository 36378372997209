.CategoryPage-body-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
}
.CategoryPage-body-inner {
  display: grid;
  max-width: 1096px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}
.CategoriePage-Card {
  padding: 16px;
}
.CategoriePage-Card img {
  width: 100%;
  height: 100%;
}
#CategoriePage-Header p {
  color: #667079;
  margin-top: 16px;
}
.CategoriePage-Card p {
  color: #001020 !important;
  font-weight: 600;
}
.CategoriePage-Card:hover {
  opacity: 0.8;
  transition: 0.4s;
}
