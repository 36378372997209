.VideoList-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 120px;
}
.VideoList-innerWrap {
  display: flex;
  width: 100%;
  justify-content: center;
}
.VideoList-innerWrap:first-child {
  margin-top: 16px;
}
#VideoList-Grey {
  padding-top: 16px;
  margin-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  background-color: #f2f3f4 !important;
}
.VideoList-Table {
  margin-top: 8px;
}
.VideoList-Table tr {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-top: 8px;
}
.VideoList-Table tr td:first-child {
  font-weight: 600;
  min-width: 130px;
}
.VideoList-Table tr td:last-child {
  color: #667079;
  min-width: 110px;
}
