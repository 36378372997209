.ProductCategoryGrid-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 120px;
}
.ProductCategoryGrid-inner {
  width: 100%;
  max-width: 1096px;
  display: flex;
  flex-direction: column;
}
.ProductCategoryGrid-inner h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  color: #001020;
  margin-bottom: 48px;
}

.ProductCategoryGrid-ShowMoreBtn {
  padding: 8px 16px;
  color: #001020;
  border: 1px solid #001020;
  background-color: #fff;
  border-radius: 24px;
  position: absolute;
  bottom: 16px;
  max-width: 125px;
  margin-top: 16px;
}
.ProductCategoryGridCard {
  overflow: hidden;
  text-align: center;
  position: relative;
  min-width: 240px;
  min-height: 240px;
  max-width: 240px;
  background-color: #f2f3f4;
}
#ProductCategoryGrid-filter-headline {
  gap: 48px;
  align-items: center;
  justify-content: space-between;
}

#ProductCategoryFilterNotActive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0px;
  background-color: #04468c;
  min-width: 32px;
  min-height: 32px;
}

#ProductCategoryGridId {
  grid-template-columns: repeat(4, 1fr);
}
#ProductCategoryGrid-ShowMoreItemsBtn {
  display: none;
  justify-content: center;
  padding: 16px 32px;
  border: 0px;
  min-width: 160px;
  border-radius: 24px;
  background-color: #04468c;
  color: #fff;
}
#ProductCategoryGrid-ShowMoreRespDiv {
  display: none;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
}
#ProductCategoryFilterActive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0px;

  min-width: 32px;
  min-height: 32px;
}
.ProductCategoryGrid-ShowMoreItemsBtnActive {
  padding-top: 164px !important;
}
@media screen and (max-width: 1024px) {
  #ProductCategoryGridId {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 840px) {
  #ProductCategoryGridId {
    grid-template-columns: repeat(1, 1fr);
  }
  #ProductCategoryGrid-ShowMoreItemsBtn {
    display: flex;
  }
  #ProductCategoryGrid-ShowMoreRespDiv {
    position: relative;
    display: flex;
    margin-top: -120px;
  }
}
#ProductCategoryGrid-RespOverlay {
  display: flex;

  width: 100%;
  height: 128px;
  background: linear-gradient(180deg, transparent 0, white 100%);
  z-index: 1000;
  justify-content: center;
  padding-bottom: 48px;
  align-items: center;
}
.ProductCategoryGridCard h3 {
  text-align: left;
  font-size: 16px;
  color: #001020;
}
.ProductCategoryGridCard h3:hover {
  transition: 0.32s;
  opacity: 0.7;
}

.ProductCategoryGridCard-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
#ProductCatecoryGridBlock {
  font-size: 48px;
}
.ProductCategoryGridCard-Link:hover {
  transition: 0.32s;
  opacity: 0.7;
  text-decoration: underline;
}
.ProductCategoryFilterActiveAdjust {
  background-color: #04468c !important;
}
.ProductCategoryGridCard-Link {
  color: #667079;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.ProductCategoryGridCard-headline {
  font-size: 16px;
  margin: 16px 0;
  margin-left: 16px;
  padding-right: 16px;
}

.ProductCategoryGridCard-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 16px;
  font-size: 12px;
  color: #001020;
  text-decoration: underline;
}
.ProductCategoryGridCard-links a {
  color: #001020;
}
.ProductCategoryGridCard-link {
  color: #007bff;
  text-decoration: none;
}

.ProductCategoryGridCard-link:hover {
  text-decoration: underline;
}
.ProductCategoryGrid-Sublink {
  text-align: left;
  color: #667079;
}
.ProductCategoryGrid-Sublink:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.product-category-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

@media (max-width: 1200px) {
  .product-category-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .product-category-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .ProductCategoryGrid-wrap {
    padding-top: 0px;
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 860px) {
  .product-category-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .ProductCategoryGridCard {
    min-width: 100%;
    max-width: 100%;
    min-height: 160px;
    background-position: calc(100%);
  }
}

@media (max-width: 768px) {
  .product-category-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .product-category-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
@media (max-width: 380px) {
  .ProductCategoryGridCard {
    min-width: 100%;
    max-width: 100%;
    min-height: 160px;
  }
}

.ProductCategoryGridCard-links.show {
  position: relative;
}

.ProductCategoryGridCard-links.hide {
  max-height: 60px; /* Adjust as needed */
  opacity: 0;
}

#ActiveDiv {
  height: auto;
}

.triggeredBtn {
  position: relative;
}
#activeBtn {
  position: relative;
  margin-top: 24px;
  animation: appearProjectgrid 0.9s;
}

@keyframes appearProjectgrid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.CategoryGridPage-Card {
  height: unset;
}

.CategoryGridPage-wrap-head {
  display: flex;
  justify-content: center;
  width: 100%;
}
