#root {
  overflow-x: hidden;
}
.Configure-block-wrap {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
}
.specifics-jump-div {
  position: absolute;
  margin-top: -90px;
}
/*TextPages*/
b {
  font-weight: 600;
}
.Contenttext-inner-inner a {
  color: #04468c !important;
  text-decoration: underline;
}
#ordering-textpage .Contenttext-inner-inner strong {
  color: #667079 !important;
}

#ordering-textpage .Contenttext-inner-inner strong u {
  margin-top: 16px !important;
  font-weight: 600 !important;

  border-bottom: 16px solid transparent !important;
  line-height: 56px !important;
  color: #667079 !important;
}
#architects-textpage .Contenttext-inner-inner a {
  color: white !important;
  position: absolute;
  margin-top: 16px !important;
}
#architects-textpage .Contenttext-inner-inner {
  max-width: 1096px;
}
#ordering-textpage .Contenttext-inner-inner h3 {
  margin-top: 16px;
  font-size: 24px;
  color: #001020;
}
#ordering-textpage .Contenttext-inner-inner p {
  margin-top: 24px;
  color: #667079 !important;
}
#ordering-textpage .Contenttext-inner-inner p u {
  color: #001020 !important;
}

#returns-textpage h2 {
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 40px !important;
  color: #667079 !important;
  word-wrap: break-word !important;
}
#returns-textpage p {
  color: #667079 !important;
}
#returns-textpage strong {
  line-height: 32px !important;
  font-size: 18px !important;
}

.Recentprojects-wrap {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
}
.SystemHeadline-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f3f4;
}
.Configure-block-inner {
  max-width: 1096px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Configure-block-cards {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 24px;
  margin-top: 48px;
}
.Configure-block-cards img {
  width: 100%;
}
.Configure-block-head h2 {
  font-size: 48px;
  font-weight: 600;
}
.Configure-block-card a {
  text-decoration: none;
  color: black;
  background-color: #f2f3f4;
}
.Configure-block-card p {
  color: #667079;
  padding-top: 24px;
  padding-bottom: 8px;
  background-color: #f2f3f4;
}
.Configure-block-card Link {
  padding-left: 24px;
  padding-right: 24px;
}
.Configure-block-card {
  min-height: 350px;
}
.Configure-block-card-link p,
.Configure-block-card-link h3 {
  padding-left: 24px;
  padding-right: 24px;
}
.Configure-block-card-btnrow a {
  margin-right: 24px;
}
.Configure-block-card-btnrow {
  padding-bottom: 24px;
  background-color: #f2f3f4;
  height: 88px;
}
.Configure-block-card-btnrow a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  padding: 8px, 10px, 8px, 10px;
  align-items: center;
  min-width: 32px;
  justify-content: center;
  background-color: #04468c;
}
.Configure-block-card-btnrow {
  display: flex;
  width: 100%;

  justify-content: space-between;
}
.Configure-block-card h3 {
  line-height: 32px;
  font-size: 24px;
  font-weight: 600;
  max-width: 290px;
}

/*Serviceblock*/

.service-block-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 120px;
  padding-top: 120px;
  background-color: #f2f3f4;
}
.service-block-inner,
.SystemHeadline-inner {
  max-width: 1096px;
  width: 100%;
}
.service-block-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.service-block-card img {
}
.service-block-text {
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}
.service-block-text div {
  width: calc(100% - 96px);
  height: calc(100% - 144px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service-block-text div h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
.service-block-text div p {
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 32px;
  letter-spacing: -0.02em;
  color: #667079;
}
.service-block-header {
  display: flex;
  justify-content: center;
}
.service-block-header h2 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 48px;
}
.service-block-text div button {
  padding: 16px 32px;
  gap: 8px;
  color: white;
  border: 0px;
  font-size: 13px;
  width: 148px;
  height: 48px;
  background: #04468c;
  border-radius: 24px;
}
.service-block-card-2 {
}

/*Servicecards*/

.servicecards-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 680px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('https://www.q-railing.com/files/thumbnails/system-slide-esg-smart-1.1500x637x1.jpg');
  background-size: cover;
}
.servicecards-inner {
  max-width: 1096px;
  width: 100%;
}
.servicecards-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}
.servicecards-card {
  background-color: white;
  width: 100%;
  height: 264px;
}
.servicecards-header {
  margin-top: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.servicecards-header h2 {
  font-size: 48px;
  font-weight: 600;
  color: white;
  margin-bottom: 24px;
}
.servicecards-header p {
  max-width: 536px;
  margin-bottom: 48px;
  color: #f2f3f4;
}
.servicecards-card-inner h3 {
  margin-top: 6px;
}
.servicecards-card-inner p {
  margin-top: 6px;
  color: #667079;
  text-decoration: underline;
}
.servicecards-card-inner {
  display: flex;
  justify-content: center;
  text-align: center;
}
.servicecards-card-inner svg {
  height: 50%;
  margin-top: 58.5px;
}
.inspectproduct-inner img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 130px;
}
.inspectproduct-inner {
  margin-top: 120px;
  margin-bottom: -10px;
}
#btn1,
#btn2,
#btn3 {
  position: absolute;
  padding: 7px 15px;
  border-radius: 50%;
  color: white;
  background-color: #04468c;
  cursor: pointer;
  font-size: 28px;
}
#btn1:hover,
#btn2:hover,
#btn3:hover {
  transform: scale(1);
  opacity: 0.9;
  transition: 0.1s;
}
.inspectproduct-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.inspectproduct-inner {
  max-width: 1320px;
  position: relative;
  height: 100%;
}
.inspectproduct-inner h3 {
  margin-bottom: 16px;
}
.inspectproduct-card-2,
.inspectproduct-card-3,
.inspectproduct-card-1 {
  background-color: white;

  padding: 20px 15px 20px 15px;
}
.Recentprojects-head-wrap a:hover {
  opacity: 0.8;
  transition: 0.5s;
}
.inspectproduct-card-1 {
  position: absolute;
  width: 250px;
  left: 44.5%;
  top: 18%;
  transform: translateY(-50%);
  z-index: 10;
  display: none;
}
.inspectproduct-card-2 {
  position: absolute;
  width: 250px;
  left: 59.5%;
  top: 35%;
  transform: translateY(-50%);
  z-index: 10;
  display: none;
}
.inspectproduct-card-3 {
  position: absolute;
  width: 250px;
  left: 49.5%;
  transform: translateY(-50%);
  top: 60%;
  z-index: 10;
  display: none;
}
.activeprodbtn {
  background-color: white !important;
  border: 1px solid #333;
  color: #333 !important;
  position: relative;
  font-size: 22px !important;
  padding: 11px 18px !important;
}
/*howwework*/
.howwework-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.howwework-inner {
  max-width: 1096px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}
.howwework-cards {
  display: grid;
  gap: 24px;
  margin-bottom: 120px;
  grid-template-columns: 1fr 1fr 1fr;
}
.howwework-card img {
  max-width: 100%;
}
.howwework-header {
  margin-bottom: 48px;
  font-size: 48px;
  font-weight: 600;
}
.howwework-card-head {
  background-color: #f2f3f4;
  padding: 40px;
}
.howwework-card-head p {
  color: #667079;
}
.howwework-card-head a {
  text-decoration: underline;
  margin-top: 46px;
  color: #001020;
  font-size: 16px;
}
.howwework-card-head h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}
.header-text-left {
  max-width: 800px;
}
.header-text-projects button {
  width: unset !important;
  border-radius: 0px !important;
  font-size: 18px !important;
  margin-bottom: 8px;
}
.header-text-projects h2 {
  font-weight: 600 !important;
}

/*ProjectDetail page*/

.projectdetail-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.projectdetail-inner {
  max-width: 1096px;
  width: 100%;
}
.hiddenMenu {
  display: none;
}
.projectdetail-header button,
.projectdetail-header a {
  padding: 16px 32px;
  gap: 8px;
  color: white;
  border: 0px;
  font-size: 13px;
  max-width: 180px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 48px;
  background: #04468c;
  border-radius: 24px;
}
.projectdetail-table {
  border-collapse: collapse;
}
.projectdetail-table tr {
  border-top: 1px solid #f2f3f4;
}

.projectdetail-table tr:last-child {
  border-bottom: 1px solid #f2f3f4;
}
.projectdetail-header {
  display: flex;
  margin-top: 120px;
  margin-bottom: 72px;
  justify-content: space-between;
}
.projectdetail-header h2 {
  font-size: 48px;
  font-weight: 600;
}
.projectdetail-table {
  width: 100%;
}
.projectdetail-table tr {
  height: 96px;
}
.projectdetail-table tr td:first-child {
  font-size: 24px;
}
.Landingpage-stats-text {
  font-size: 16px;
  width: 50%;
}
.spacer {
  width: 0px !important;
  padding-left: 0px !important;
}

/* Footer-new*/

.footer-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #001020;
}
.footer-inner {
  max-width: 1096px;
  width: 100%;
  color: white;
}
.footer-inner li {
  list-style-type: none;
}
#Footer-scrollupButton {
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 16px;
  width: 48px;
  height: 48px;
  border: none;
  cursor: pointer;
}
.footer-first-row {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 48px;
}
.footer-first-row img {
  width: 90px;
  height: 24px;
}
.service-block-text a {
  background-color: #04468c;
  color: white;
  border: none;
  text-decoration: none;
  max-width: 134px;
  padding: 16px 32px;
  font-size: 13px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
}
.footer-second-row {
  display: grid;
  gap: 124px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 80px;
}
.footer-second-row ul li {
  margin-top: 24px;
  color: #cccfd2;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}
.footer-second-row h4 {
  font-size: 14px;
  font-weight: 600;
}
.footer-second-row-left {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}
.footer-second-row-right {
  font-size: 13px;
}
.footer-second-row-right p {
  color: #cccfd2;
}
.footer-second-row-right h3 {
  font-size: 14px;
}
.footer-second-row-right p span {
  font-weight: 600;
}
.footer-second-row-offices-office3 {
}
#footer-mobile-termsandcond li {
  font-size: 14px;
}
.footer-mobile-office-1 p,
.footer-mobile-office-2 p {
  font-size: 14px;
}
#footer-mobile-termsandcond button {
  background-color: #001020;
  color: #667079;
  border: none;
  font-size: 14px;
  cursor: pointer;
}
#footer-company-toggle-menu li,
#footer-information-toggle-menu li,
#footer-support-toggle-menu li {
  margin-top: 8px;
}
#footer-company-toggle-menu li a,
#footer-information-toggle-menu li a,
#footer-support-toggle-menu li a {
  color: #667079;
}
.footer-third-row-icons a:first-child {
  margin-left: 0px !important;
}
.EmailLineOffices span:last-child {
  font-weight: 400;
  border-bottom: 0.5px solid #cccfd2;
}
.EmailLineOffices span {
  text-decoration: none !important;
}
.footer-second-row-left li:hover a {
  opacity: 0.4;
  transition: 0.2s;
}
#Footer-headline-adjustment {
  font-weight: 600 !important;
}

.LanguagemenuFooter #changeregion {
  width: 100%;
  border-radius: 0px !important;
  border-top: 1px solid #f2f3f4;
}
.LanguagemenuFooter button {
  color: #001020 !important;
}
.LanguagemenuFooter .activelang,
.LanguagemenuFooter .normal {
  width: 100% !important;
}
.LanguagemenuFooter .activelang img,
.LanguagemenuFooter .normal img {
  width: 32px;
}
.LanguagemenuFooter {
  padding: 16px;
}
.footer-second-row-offices {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}
.footer-second-row-offices p {
  margin-top: 4px;
  max-width: 256px;
}
#footer-second-row-3address-id {
  gap: 48px;
  grid-template-columns: 40% 58%;
}
#footer-second-row-3address {
  grid-template-columns: 1fr 1fr 1fr;
}
.Footer-include-spacer {
  height: 16px;
}
.footer-second-row-offices {
  margin-top: 16px;
}
.footer-third-row {
  display: flex;
  justify-content: space-between;
}
.footer-last-row {
  display: flex;
  justify-content: space-between;
  color: #667079;
  margin-bottom: 40px;
}
.footer-last-row-agb ul {
  display: flex;
}
.footer-last-row-agb ul li {
  margin-right: 24px;
}

.footer-last-row-agb ul li:last-child {
  margin-right: 0px;
}
.footer-third-row-icons {
  display: flex;
  align-items: center;
}
.footer-third-row-icons a {
  margin-left: 32px;
}
.footer-third-row-icons svg:first-child {
  margin-left: 0px;
}
.footer-third-row {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-third-row-buttons {
  display: flex;
  gap: 24px;
  position: relative;
}
.SwiperSlider-2-greybg {
  background-color: #f2f3f4 !important;
}

.SwiperSlider-2-greybg #Sliderprevbtn20 {
  background-color: white !important;
}
.SwiperSlider-2-greybg #Sliderprevbtn21 {
  background-color: white !important;
}
.footer-third-row-buttons button {
  gap: 8px;
  border-radius: 24px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 14px;
}

#Footer-scrollupButton svg {
  margin-right: unset;
}
.mobile-last-row p {
  color: #667079;
}
.footer-third-row-buttons button:first-child {
  background-color: transparent;
  border: none;

  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  border-radius: 24px;
}

.footer-last-row a {
  text-decoration: none;
  font-size: 13px;
  color: #667079 !important;
}
.footer-last-row-copyright p,
.mobile-last-row p {
  font-size: 13px;
}
.footer-last-row a:hover {
  color: white !important;
}
/*Mobile footer*/
#footer-company-toggle-menu,
#footer-support-toggle-menu,
#footer-information-toggle-menu {
  display: none;
}
.footer-mobile-row-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-mobile-row-buttons-inner button {
  background-color: transparent !important;
  border: none !important;
  color: #cccfd2;
  display: flex;
  gap: 8px;
  align-items: center;
}
.footer-mobile-row-buttons-inner {
  display: flex;
  font-size: 14px;
  gap: 16px;
  padding-left: 16px;
  justify-content: flex-start;
}
#mobile-footer-scroll {
  background-color: #f0f0f0;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-right: 16px;
  border: none;
}
#footer-mobile-termsandcond {
  margin-bottom: 24px;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-mobile-office-1 {
  margin-top: 24px;
}
.footer-mobile-office-1 p:first-child,
.footer-mobile-office-2 p:first-child {
  max-width: 215px;
}
.footer-mobile-offices p {
  padding-top: 24px;
  color: #cccfd2;
}
.footer-mobile-offices p:first-child {
  padding-top: 0px;
  color: #cccfd2;
  max-width: 199px;
}
.footer-mobile-offices p:nth-child(2) {
  padding-top: 0px;
  margin-top: 16px;
  color: #cccfd2;
}
.footer-mobile-offices p span {
  font-weight: 600;
}

#footer-mobile-termsandcond li {
  margin-top: 24px;
  color: #667079;
}
#footer-mobile-termsandcond li a {
  color: #667079;
}
#footer-mobile-termsandcond li:first-child {
  margin-top: 0px;
}

.footer-mobile-offices {
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-mobile-office-2 {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-last-row {
  text-align: center;
  margin-bottom: 32px;
}
.footer-mobile-list h3 {
  margin-left: 16px;
}
.footer-mobile-list button {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 200;
}
.footer-mobile-list {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#mobile-footer-logo {
  margin-top: 64px;
  margin-bottom: 32px;
  margin-left: 16px;
}
.footer-mobile-design {
  display: none;
}
.footer-mobile-list {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
}
.footer-mobile-list h3 {
  cursor: pointer;
}
/*RecentProjects*/

.Recentprojects-inner {
  max-width: 1096px;
  width: 100%;
}
#ConfiBlock-shadowdiv {
  height: 710px;
  width: 100%;
}
#Howwework2-shadowdiv {
  height: 700px;
  width: 100%;
}
#RecentProjects-shadowdiv {
  height: 710px;
  width: 100%;
  background-color: #f2f3f4;
}
#NeedHelp-shadowdiv {
  width: 100%;
  height: 510px;
}
#Categoryslider-shadowdiv {
  height: 710px;
  width: 100%;
}

.Recentprojects-projects {
  display: grid;
  column-gap: 32px;
  row-gap: 32px;
  grid-template-columns: 1fr 1fr;
}
.Recentprojects-project-card a {
  text-decoration: none;
  color: #001020;
  font-size: 24px;
  line-height: 32px;
}
.Recentprojects-project-card p {
  margin-top: 8px;
  font-weight: 600;
}
.Recentprojects-project-card img {
  width: 100%;
}
.Recentprojects-head {
}
.Recentprojects-head p {
  max-width: 536px;
  font-size: 16px;
  color: #667079;
  margin-bottom: 48px;
}
.Recentprojects-head h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  margin-bottom: 24px;
}

.Recentprojects-head-wrap {
  display: grid;
  grid-template-columns: 1fr 300px;
}
.Recentprojects-head-link {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
}
.Recentprojects-head-wrap a {
  margin-bottom: 48px;
  padding: 16px 32px;
  background-color: #04468c;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  font-size: 13px;
  height: 48px;
  flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 0;
}

/*textpage*/

.hidefootermenu {
  display: block !important;
  margin-left: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.footer-inner a {
  text-decoration: none;
  color: white;
}
.footer-mobile-office-1,
.footer-mobile-office-2,
.footer-mobile-offices h3 {
  margin-left: 16px;
  margin-right: 16px;
}
#footer-mobile-termsandcond li {
  margin-left: 16px;
}

.Contenttext-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Contenttext-inner {
  width: 100%;
  max-width: 1096px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Contenttext-inner-inner {
  max-width: 648px;
  width: 100%;
}
.Contenttext-inner h2 {
  width: 100%;
  font-size: 48px;
  font-weight: 600;
  color: #001020;
}
#LanguageMenuFlags a,
#LanguagemenuInternationalMenu a {
  color: #001020 !important;
}
.Contenttext-wrap-head {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f2f3f4;
}
.Contenttext-inner-inner {
  margin-top: 120px;
  margin-bottom: 120px;
}
.SystemHeadline-text {
  padding-top: 80px;
  padding-bottom: 64px;
}
.SystemHeadline-text p {
  max-width: 536px;
  color: #667079;
  margin-top: 24px;
  font-size: 16px;
  letter-spacing: -0.32px;
}
.SystemHeadline-text h2 {
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -3, 6px;
}
.Configure-block-cards div .Configure-block-card-btnrow a {
}
#Configure-block-card1:hover {
  opacity: 0.9;
  transition: 0.2s;
}
#Configure-block-card2:hover {
  opacity: 0.9;
  transition: 0.2s;
}
#Configure-block-card3:hover {
  opacity: 0.9;
  transition: 0.2s;
}
.Configure-block-cards div svg path {
  fill: #001020;
  transition: 0.6s;
}
.Recentprojects-project-card:hover {
  opacity: 0.7;
  transition: 0.5s;
}
.Configure-block-cards div .Configure-block-card-btnrow a {
  background-color: #f2f3f4;
  transition: 0.6s;
}
.Configure-block-cards div:hover svg path {
  fill: white;
  transition: 0.6s;
}
.Configure-block-cards div:hover .Configure-block-card-btnrow a {
  background-color: #04468c;
  transition: 0.6s;
}
.Configure-block-cards div:first-child:hover .Configure-block-card-btnrow a {
  display: flex;
}
.Configure-block-cards div:nth-child(2):hover .Configure-block-card-btnrow a {
  display: flex;
}
.Configure-block-cards div:last-child:hover .Configure-block-card-btnrow a {
  display: flex;
}
.DownloadsInSystems-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: white;
}
.DownloadsInSystems-inner {
  max-width: 1096px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.DownloadsInSystems-Headline {
  margin-bottom: 24px;
}
.DownloadsInSystems-Headline h2 {
  font-size: 32px;
}
.ServiceSearchinclude-wrap input {
  background-color: #f2f3f4;
}
.ConfiBlock-ResponsiveLink {
  display: none;
}
@media (min-width: 1024px) {
  .Configure-block-card {
    max-width: 31%;
  }
  .Configure-block-cards {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .Configure-block-card {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Configure-block-card {
    min-height: unset !important;
  }
}

@media (max-width: 639px) {
  .Configure-block-cards {
    grid-template-columns: 48% 48% !important;
  }
  .Configure-block-card {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Configure-block-card p {
    display: none;
  }
  .Configure-block-card-btnrow a {
    display: none;
  }
  .Configure-block-card h3 {
    font-size: 16px !important;
    line-height: 20px;
  }
  .Configure-block-cards {
    gap: 16px !important;
  }
  .Configure-block-cards div:first-child:hover .Configure-block-card-btnrow a,
  .Configure-block-cards div:nth-child(2):hover .Configure-block-card-btnrow a {
    display: none !important;
  }
  .ConfiBlock-ResponsiveLink {
    display: block !important;
    padding-top: 8px !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  .Configure-block-card-btnrow {
    flex-direction: column;
    gap: 0px;
  }
  .Configure-block-cards div:last-child:hover .Configure-block-card-btnrow a {
    display: none !important;
  }
}
@media (max-width: 380px) {
  .Configure-block-card h3 {
    font-size: 14px !important;
  }
}
@media (max-width: 320px) {
  .Configure-block-card h3 {
    font-size: 12px !important;
  }
  .ConfiBlock-ResponsiveLink {
    font-size: 11px !important;
  }
  .ConfiBlock-ResponsiveLink svg {
    display: none;
  }
}
@media (max-width: 295px) {
  .Configure-block-card h3 {
    font-size: 10px !important;
  }
}
