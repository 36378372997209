.Catalogue-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f2f3f4;
  padding-top: 80px;
}
#whiteCatalogWrap {
  background-color: white;
}
.Catalogue-inner {
  width: 100%;
  max-width: 1096px;
}
.Catalogue-blöcke {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Catalogue-textside {
  display: flex;
  max-width: 536px;
  flex-direction: column;
  justify-content: flex-start;
}
.Catalogue-wrap h2 {
  font-size: 48px;
  font-weight: 600;
}
.Catalogue-Benefits-wrap {
  display: flex;
  flex-wrap: wrap;
}
.Catalogue-image img {
  margin-bottom: -4px;
}
.Catalogue-textside h2 {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: -1.92px;
}
.Catalogue-textside p {
  margin-bottom: 24px;
  color: #667079;
  font-size: 16px;
  line-height: 24px;
}
.Cataloge-view-buttons a:last-child {
  padding: 16px 32px;
  background-color: #cccfd2;
  font-size: 13px;
  border-radius: 24px;
  cursor: pointer;
  margin-left: 8px;
  color: #001020;
  border: none;
}
.Cataloge-view-buttons a:first-child {
  background-color: #04468c;
  padding: 16px 32px;
  font-size: 13px;

  color: white;
  border-radius: 24px;
  cursor: pointer;
  border: none;
}
.Cataloge-view-buttons {
  margin-top: 32px;
}
.Catalogue-Benefits {
  display: flex;
  align-items: center;
}
.Catalogue-Benefits-wrap {
  margin-top: 32px;
}
.Catalogue-Benefits-wrap div svg {
  margin-right: 16px;
}
.Catalogue-Benefits-wrap div {
  margin-left: 40px;
}
.Catalogue-Benefits-wrap div:first-child {
  margin-left: 0px;
}
