* {
  box-sizing: border-box;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif !important;
}
/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap');*/
:root {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif;
  }
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
h1,
h2,
h3 {
  font-weight: 600;
}

p,
a,
button,
span {
  font-weight: 400;
}

.slide-up {
  animation: slide-up 0.5s ease-in-out;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes jump-and-scale {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.jump-and-scale {
  animation: jump-and-scale 1s ease-in-out infinite;
}
#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}
#CybotCookiebotDialogPoweredByText {
  display: none !important;
}
