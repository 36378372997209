#images {
  width: 60%;
}
.refff {
  width: 300px;
  display: flex;
  overflow: hidden;
  overflow: scroll;
}
.product-card {
  width: 300px;
}
.draganddropslider-wrap {
  width: 60%;
  position: relative;
  height: 330px;

  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
}
.draganddropslider {
  display: flex;
  gap: 16px;
  position: absolute;
}
.fader {
  width: 120px;
  background-image: linear-gradient(to right, rgba(211, 211, 211, 0), rgba(211, 211, 211, 1));
  border-right: 5px solid #eee;
  position: absolute;
  height: 80%;
  right: 18%;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 8px;
  width: 8px;
  border-radius: 0px !important;
  background: #ffffff;
  cursor: pointer;
  margin-top: -1px;
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;

  background: #ffffff;
  cursor: pointer;
}

.draganddropslider-wrap::-webkit-scrollbar-button {
  display: none;
  height: 5px;
}

/* Track */
.draganddropslider-wrap::-webkit-scrollbar-track {
  height: 10px;
}

/* Handle */
.draganddropslider-wrap::-webkit-scrollbar-thumb {
  background-color: #04468c;
  height: 10px;
  width: 1%;
  cursor: pointer;
}

/* Handle on hover */
.draganddropslider-wrap::-webkit-scrollbar-thumb:hover {
  background-color: #04468c;
  cursor: pointer;
  height: 10px;
  width: 1%;
}

/* Adjust scrollbar width */
.draganddropslider-wrap::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.sliderino {
  position: relative;
}

/*HIER FÜR SYSTEMSLIDER*/
.product-card-system {
  width: 1010px;
}
.product-card2 {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 333px;
  padding-top: 0px;
  padding-bottom: 16px;
  margin-top: 48px;
  margin-bottom: 32px;
}
.product-card2:first-child {
  padding-left: 0px;
}
.product-card2:last-child {
  padding-left: 0px;
}
.product-card-system:hover {
  opacity: 1;
  transform: none;
}
.systemimageslider {
  width: 100%;
  height: 100%;
}
#draganddropslider-wrap img {
  height: 568px;
}
.draganddropslider-wrap-systems {
  height: 440px;
  width: 100%;
  max-width: 1096px;
}
#comparebtn {
  display: none;
}
.visiblecomparebtn {
  display: block !important;
  position: absolute;
  top: 10px;
  right: 24px;
  width: 48px;
  cursor: pointer;
  height: 48px;
  z-index: 300;
  background-color: #f2f3f4;
}
#CategorySlider #product-card {
  min-width: unset !important;
}
.Systems-slider-top-text a:hover {
  opacity: 0.8;
  transition: 0.5s;
}
/*systemslider - modernslider3*/
.Systems-slider-top-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}
.Systems-slider-top-inner {
  max-width: 1096px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Systems-slider-top-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Systems-slider-top-text div:last-child {
  display: flex;
  justify-content: flex-end;
}
.Systems-slider-top-text p {
  color: #667079;
}

.Systems-slider-top-text a {
  padding: 16px 32px;
  font-size: 13px;
  background-color: #04468c;
  height: 48px;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.Systems-slider-top-text button:first-child svg {
  transform: rotate(180deg);
}
.Systems-slider-top-text button {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f4;
}

.Systems-slider-top-text button:first-child {
  color: white;
  margin-right: 8px;
}
.Systems-slider-top-header h2 {
  margin-bottom: 24px;
  font-size: 48px;
  color: #001020;
}
.comparepopup {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 550px;
  left: 0px;
  z-index: 999;
  display: flex;
  justify-content: center;

  box-shadow: 3px 4px 16px #333;
}
.comparepopup-inner {
  width: 100%;
  background-color: white;
}
.comparepopup button {
  float: right;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.Modernslider3-wrap {
  position: relative;
}
.thecompareitems {
  display: flex;

  overflow-x: scroll;
  margin-left: 3%;
}
.thecompareitems div tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.thecompareitems div tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thecompareitems div {
  min-width: 33%;
  padding: 20px;
  border: 1px solid #f2f3f4;
}
.overlay {
  position: absolute;
  background: linear-gradient(to right, transparent, white);
  width: 10%;
  height: 330px;
  top: 28%;
  right: 0px;
  margin-top: 16px;
}
.Categories-head-wrap {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
}
.Categories-head-inner {
  max-width: 1096px;
  width: 100%;
}
.Systems-slider-top-wrap a {
  border-radius: 24px;
  background-color: #04468c;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 16px 32px;
  font-size: 13px;
  margin-right: 8px;
}
.ResponsiveButtonSystems a {
  text-decoration: none;
  color: white;
}
.ResponsiveButtonSystems {
  border-radius: 24px;
  background-color: #04468c;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 16px 32px;
  font-size: 13px;
  margin-top: 48px;
  margin-left: 16px;
  margin-right: 16px;
  display: none;
}

.draganddropslider-wrap-outerer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Categoryslider {
  padding-top: 120px;
  padding-bottom: 120px;
}
.draganddropslider a {
  text-decoration: none;
  color: #001020;
}

.Project-slider-qr {
  height: 628px;
}
.Categoryslider-text {
  margin-top: 0px !important;
}
.LayoverProjectSlider {
  width: 110px;
  height: 568px;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

  position: absolute;
  z-index: 1000;
  right: 0px;
  top: 0px;
}
.ProjectSlider-outer-wrap {
  position: relative;
}
