.SearchDropdown-wrap {
  display: flex;
  justify-content: space-between;
  gap: 48px;
}
#SearchDropdown-results-ok {
  min-height: 517px;
}

.SearchDropdown-left .Searchresults-Products-Wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SearchDropdown-right .docs-card {
  background-color: white;
  max-width: 424px;
}
.SearchDropdown-Noresults {
  min-height: 578px;
}
.SearchDropdown-right .Searchresults-Systems-Grid {
  grid-template-columns: 1fr;
}
.SearchDropdown-right .searchresults-link-systems {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 424px;
}
.SearchDropdown-Systems-card-inner p {
  font-size: 15px;
  width: 100%;
}
#SearchDropdown-wrap2 {
  height: 72px;
}
.SearchDropdown-right .Searchresults-Sites h2,
.SearchDropdown-right .Searchresults-Downloads h2,
.Searchresults-results h2 {
  margin-bottom: 16px !important;
  text-align: left;
}
.SearchDropdown-right .Searchresults-Downloads h2 {
  margin-top: 32px;
}
.SearchDropdown-left .product-cardy,
.SearchDropdown-right .product-cardy {
  display: flex;
  width: 100%;
  max-width: 424px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: none;
  border: 0px;
  padding: 0px;
}
.SearchDropdown-Products-card-inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #001020;
  width: 100%;
  word-break: break-all;
  z-index: 300;
  max-width: 151px;
}
.SearchDropdown-Products-card-inner span {
  margin-bottom: 0px !important;
  text-align: left;
  font-size: 12px;
  line-height: 24px;
  color: #667079;
  display: inline;
  text-decoration: underline;
}

.SearchDropdown-right .searchresults-link-systems img {
  width: 100px;
  height: 100px;
}
.SearchDropdown-right {
  width: 100%;
}
.SearchDropdown-right h2 {
  margin-left: 24px;
}
#SearchDropdown-seeSearchpage {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 32px;
  color: #667079;
  font-size: 16px !important;
  text-decoration: underline;
}

#SearchDropdown-seeSearchpage-respbtn {
  text-align: left;
  width: 100%;
  display: none;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #667079;
  font-size: 14px !important;
  text-decoration: underline;
}
.SearchDropdown-wrap {
  max-width: 1096px;
  padding-top: 48px;
  padding-bottom: 64px;
}

.SearchDropdown-Systems-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  padding-right: 24px;
}
#SearchDropdown-wrap {
  background: #f2f3f4 !important;
}
#SearchDropdown-wrap .Searchresults-container {
  background-color: #f2f3f4;
}

#SearchDropdown-wrap .Searchresults-Downloads .docs-card:nth-child(1) {
  margin-top: 0px;
  margin-bottom: 8px;
}
.SearchDropdown-Noresults {
  padding-top: 48px;
  padding-bottom: 64px;
}
.SearchDropdown-Noresults h3 {
  color: #001020;
  font-size: 24px;
}
.SearchDropdown-wrap .product-cardy h3 {
  margin-top: 0px;
  text-align: left;
  line-height: 24px;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 600;
}

.SearchDropdown-wrap .docs-card span {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding-left: 16px;
  padding-right: 24px;
}
.SearchDropdown-wrap h2 {
  font-size: 12px;
  text-transform: uppercase;
  color: #667079;
  margin-top: 8px;
}
.SearchDropdown-Systembtn-wrap {
  width: 100%;
  position: relative;
  display: flex;
  background-color: #f2f3f4;
  justify-content: center;
  height: 56px;
}
.SearchDropdown-Systembtn-inner a {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  height: 56px;
  color: #001020;
  padding-left: 29px;
  font-size: 16px !important;
  font-weight: 600;
  background-color: white;
  width: 80%;
}
.SearchDropdown-Systembtn-inner a svg {
  margin-left: 16px;
}
.Qrailingssubmenu-innerShop .HeaderMainpage-whatsNewLink {
  text-decoration: none !important;
  margin-top: 16px;
}
.Qrailingssubmenu-innerShop .HeaderMainpage-Submenu-Card-Service h3 {
  margin-bottom: 8px !important;
}
.SearchDropdown-ProductCategories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  padding-bottom: 24px;
  max-width: 1096px;
  min-width: 794px;
}
.SearchDropdown-ProductCategories img {
  width: 100%;
  height: 100%;
  max-width: 151px;
  max-height: 151px;
}

.HeaderDropdown-Componentgrid-lastitem {
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 151px;
  max-height: 151px;
  max-width: 151px;
  min-height: 151px;
}
.HeaderDropdown-Componentgrid-lastitem h3 {
  max-width: 80%;
  height: 100%;
  color: #667079;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 24px;
  margin: 0px auto;
  letter-spacing: 10%;
  font-weight: 400;
}
.HeaderDropdown-Componentgrid-lastitem h3 span svg {
  margin-left: -2px;
  border-bottom: 1px solid #667078;

  padding-left: 8px;
  margin-bottom: -2px;
}
.Searchresults-Products-Wraps .HeaderDropdown-Componentgrid-lastitem h3 span svg {
  margin-bottom: -2px;
}
.HeaderDropdown-Componentgrid-lastitem h3 span {
  text-decoration: underline;
  text-align: left !important;
}

.SearchDropdown-Products-card-inner span img {
  border-bottom: 1px solid #667078;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  padding-bottom: 2px;

  margin-left: -2px !important;
}
.HeaderDropdown-CategoryGridCardWrap {
  position: relative;
}
.HeaderDropdown-CategoryGridCardWrap h3 {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 16px;
}
.SearchDropdown-Systems-card-New {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  position: relative;
  width: 100%;
}
.SearchDropdown-Systems-card-New svg {
  position: absolute;
  right: -16px;
  z-index: 4;
  display: none;
}
.SearchDropdown-Systems-card-New:hover svg {
  display: block;
}
.SearchDropdown-Systems-card-New h4 {
  font-weight: 400;
}
.SearchDropdown-wrap .Searchresults-Systems-Grid {
  gap: 8px;
}
.Searchresults-ProductCategories {
  flex-direction: row;
  display: flex;
  gap: 8px;
}
.SearchDropdown-left .Searchresults-Products-Wraps {
  display: grid;
}
.SearchDropdown-left .product-cardy {
  flex-direction: column;
}
.SearchDropdown-wrap .Searchresults-Products-Wraps {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  min-width: 780px;
}
.SearchDropdown-wrap .product-cardy {
  background-color: transparent;
}
.SearchDropdown-Systembtn-inner a {
  left: calc(50% + 311px);
  height: 80px;
  bottom: 1px;
}
.SearchDropdown-left {
  max-width: 800px;
}
.HeaderDropdown-CategoryGridCardWrap h3 {
  color: #001020;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.searchresults-link-systems:hover .SearchDropdown-Systems-card-New {
  margin-left: 16px;
  border-left: 5px solid #04468c;
}
.searchresults-link-systems:hover .SearchDropdown-Systems-card-New h4 {
  margin-left: 16px;
}
.SearchDropdown-right .SearchDropdown-Systems-card-New h4 {
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.ResponsiveNavigation {
  position: fixed;
  top: 47px;
  width: calc(100% - 5px);
  left: 0px;
  z-index: 1;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: none;
  gap: 16px;
  background-color: #f2f3f4;
}
.ResponsiveNavigation div {
  display: flex;
  gap: 16px;
  width: 100%;
}
.ResponsiveNavigation div button {
  text-decoration: underline;
  color: #667079;
  font-size: 16px;
}

.HeaderDropdown-ShowMoreSystems {
  width: 100%;
  color: #667079 !important;
  text-align: left;
  display: block;
  padding-left: 24px;
  font-size: 12px !important;
  margin-top: 16px;
  text-decoration: underline;
}
.HeaderDropdown-ShowMoreSystems svg {
  margin-left: 8px;
  margin-left: -2px;
  border-bottom: 1px solid #667078;
  padding-left: 8px;
  margin-bottom: -3px;
}
.ProductCatsSearchClass {
  max-height: 59px !important;
  min-height: 59px !important;
  padding: 0px !important;
}
.ProductCatsSearchClass h3 {
  display: none;
}
#SearchDropdown-results-ok .ProductCategoryGridCard-image {
  max-height: 150px;
}
#SearchDropdown-inputbuffer {
  width: 48px;
}

.Blackoverlay {
  position: fixed;
  bottom: 0px;
  background-color: rgba(0, 16, 32, 0.6); /* Use rgba for background color with opacity */
  backdrop-filter: blur(10px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px);
  left: 0px;
  z-index: 300;
  width: 100%;
  height: calc(100% - 71px);
}

.Blackoverlay-Blur {
  bottom: 0px;
  backdrop-filter: blur(1000px);
  left: 0px;
  z-index: 300;
  width: 100%;
}

@media (max-width: 1024px) {
  .Searchresults-Products-Wraps {
    min-width: unset;
  }
}
@media (max-width: 480px) {
  .SearchDropdown-Noresults {
    height: 100vh;
  }
  .SearchDropdown-Noresults h3 {
    font-size: 20px;
    padding-top: 72px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
