.category-management {
  display: flex;
  flex-direction: column;
}

.category-display {
  display: flex;
  flex-direction: column;
}

.category-management > .title {
  display: flex;
}

.category-management > .title {
  display: flex;
}

.category-display > .hierarchy-control {
  display: flex;
  flex-direction: row;
}

.category-management > .title {
  display: flex;
  flex-direction: row;
}
