.Servicesearch-wrap {
  width: 100%;
  display: flex;
  background-color: #f2f3f4;
  justify-content: center;
  position: relative;
}
.Servicesearch-inner {
  max-width: 1096px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding-top: 80px;
  padding-bottom: 60px;
}
.Servicesearch-inner h2 {
  font-size: 32px;
  font-weight: 600;
  word-break: break-word;
  color: #001020;
  margin-bottom: 32px;
}
.Servicesearch-table-wrap .docs-card {
  border: none;
  border-top: 1px solid #f2f3f4;
}
.ServiceSearchFilterBtns {
  width: 100%;
  max-width: 478px;
  margin-top: 8px;
}
.ServiceSearchFilterBtns button {
  color: #667079;
  background-color: transparent;
  border: 1px solid #667079;
  padding: 4px 8px;
  font-size: 11px;
  border-radius: 24px;
}
.ServiceSearchFilterBtns #ActiveFilterBtn {
  background-color: #04468c;
  font-weight: 400;
  color: white;
  padding: 4px 8px;
  font-size: 11px;
  border: 0px;
  border-radius: 24px;
}
.Svg-ServiceSearch-icon {
  background-color: #f2f3f4;
  border-radius: 50%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 16px;
}
.Svg-ServiceSearch-icon svg {
  margin-right: 0px;
}
.paginationbutton {
  background-color: transparent !important;
}
.ServiceSearch-Downloads-inner .docs-card:last-child {
  border-bottom: 1px solid #f2f3f4;
}
.Servicesearch-table-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Servicesearch-table-wrap {
  margin-top: 64px;
}

.Servicesearch-inner input {
  max-width: 360px;
  width: 360px;
  border-radius: 24px;
  padding-left: 32px;
  height: 48px;

  border: none !important;
}
.Servicesearch-Downloads {
  background-color: white;
}
.Servicesearch-search button {
  padding: 16px 32px;
  border-radius: 24px;
  height: 48px;
  background-color: #04468c;
  color: white;
  border: none;
}
.Servicesearch-search {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: center;
}
.Servicesearch-Downloads {
  width: 100%;
  max-width: 1096px;
}
.Servicesearch-wrap .projectblock-filter button {
  border: 1px solid #333;
}
.ServiceSearch-x-button-label {
  position: relative;
}
#ServiceSearch-x-button {
  position: absolute;
  background-color: transparent;
  right: -16px;
}
.SystemEntryWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  flex-wrap: wrap;
}

.SystemSearchLinks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  gap: 8px;
  overflow: hidden;
}
.SystemEntryWrap button {
  border: 0px;
  background-color: transparent;
}
.SystemEntryWrap a {
  background-color: white;
  color: #001020;
  border: 1px solid #cccfd2;
  padding: 4px 4px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  max-height: 32px;
}
.SystemSearchLinksSvg svg {
  margin-left: 8px;
  transform: rotate(90deg);
  transition: 0.4s;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .SystemSearchLinks {
      display: flex;
      flex-wrap: wrap;
    }
    .SystemSearchLinks .SystemEntry {
      padding: 8px 16px;
    }
  }
}

#RotateSpan svg {
  transform: rotate(-90deg);
  transition: 0.4s;
}
#ServiceGeneralSearchOutput {
  margin-top: 0px;
}
#ServiceGeneralSearchOutput .Svg-ServiceSearch-icon {
  background-color: white;
}
#ServiceGeneralSearchOutput .docs-card {
  border: none;

  border-top: 1px solid #cccfd2;
}
.ServiceSearchinclude-wrap {
  background-color: white;
}

#ServiceGeneralSearchOutput .paginationproducts {
  margin-top: 0px;
}

.docs-card:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.ExternalLink {
  bottom: 8px;
  max-width: 1096px;
  width: 100%;
  margin-top: 48px;
  position: absolute;
  bottom: -50px;
}
.ExternalLink a {
  color: #667079;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 404px;
  justify-content: center;
  border: 1px solid #cccfd2;
}
.ExternalLink a span {
  font-weight: 600 !important;
}
.ExternalLink p {
  color: #667079;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
}
.ExternalLink svg {
  width: 10px;
  height: 10px;
  margin-left: 16px;
}
