#SeoSlider-inject-text p a{
    padding: 0px !important;
    background-color: unset;
    color: #667079;
    font-size: 16px;
    margin: 0px !important;
    width: unset;
    display: inline;
    text-decoration: underline;
}

#GreySeoSlider{
    background-color: #f2f3f4;
}
#GreySeoSlider #Sliderprevbtn46,#GreySeoSlider #Sliderprevbtn45{
 background-color: white;
}