.i18n-management .language-display {
  display: flex;
  flex-direction: row;
}

.i18n-management .language-display .dropable-container {
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: calc(100vh - 200px - 128px);
  overflow: auto;
}

.draggable-item {
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
