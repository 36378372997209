.page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.page-wrap {
  max-width: 1170px;
}

.BannerWarp {
  max-width: 1500px;
}
.overviewsystemswrap {
  display: flex;
  width: 100%;
  justify-content: center;
}
.overviewsystems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1096px;
  margin-top: 55px;
  margin-bottom: 0px;
}
.overviewsystems h2 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-weight: bolder;
}
.overviewsystems p {
  max-width: 550px;
  width: 100%;
  color: #333;
  opacity: 0.7;
  margin-right: 30px;
  padding: 20px;
}
.specifics-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.specifics {
  width: 100%;
  max-width: 1320px;
  background-color: #f4f3f2;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 112px;
  padding-right: 112px;
}
.specifics h2 {
  font-size: 48px;
  margin-bottom: 64px;
  font-weight: 600;
}
.specificsrow {
  display: flex;
  justify-content: center;

  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: #ccc 1px solid;
}
.specificsrow:nth-child(2) {
  border-top: #ccc 1px solid;
}

.specificsrow div:first-child {
  flex-basis: 35%;
}
#greybg {
  background-color: #f2f3f4;
}
#greybg #Sliderprevbtn10,
#greybg #Sliderprevbtn11 {
  background-color: white;
}
.specificsrow div:last-child {
  flex-basis: 65%;
  line-height: 24px;
  color: #001020;
}
#specifics:nth-child(2) {
  border-top: 0px !important;
}
.specificsrow li {
  list-style-position: outside;
  margin-left: 16px;
}
.specificsrow div:first-child p {
  font-weight: 600;
  font-size: 24px;
}
.specificsspecialrow {
  align-items: normal;
  border-bottom: none;
}
