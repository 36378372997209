.AdminCustomerAddresses-wrap {
  display: flex;
  padding-top: 120px;
  width: 100%;
  justify-content: center;
  padding-bottom: 120px;
  background-color: #f2f3f4;
}
.AdminCustomerAddresses-inner {
  width: 100%;
  max-width: 1320px;
  display: flex;
  flex-direction: column;
}
.AdminCustomerAddresses-adresswrap {
  display: flex;
  gap: 128px;
}
.AdminCustomerAddresses-Address span {
  display: flex;
  margin-top: 4px;
}
.AdminCustomerAddresses-Address span p:last-of-type {
  font-weight: 600;
}
.AdminCustomerAddresses-Address span p:first-of-type {
  min-width: 150px;
}
.AdminCustomerAddresses-Address {
  margin-top: 32px;
}
