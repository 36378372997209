.jobcenter-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.jobcenter-inner {
  max-width: 1096px;
  width: 100%;
  margin-bottom: 120px;
}
.jobcenter-jobcards {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.jobcenter-jobcard {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PaddingTop {
  padding-top: 64px;
}
.jobcenter-jobcard-joblink {
  text-decoration: none;
  background-color: #04468c;
  border-radius: 50%;

  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}
.jobcenter-jobcard-joblink img {
  position: absolute;
}
.jobcenter-jobcard-outer-link {
  color: #001020;
}
.jobcenter-jobcard-joblink img {
  width: 16px;
  height: 16px;
}
.jobcenter-wrap {
  overflow: hidden;
}
.jobcenter-jobcard-desctiption {
  display: flex;
  flex-basis: 40%;
  align-items: center;
  justify-content: flex-end;
}

.jobcenter-jobcard-desctiption p {
  color: #667079;
}
.jobcenter-banner div {
  display: flex;
  width: 100%;
}
.jobcenter-header h1 {
  font-size: 72px;
  font-weight: 600;
  margin-bottom: 16px;
}
.jobcenter-header a {
  background-color: #04468c;
  border-radius: 50%;
  transform: rotate(90deg);
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
}
.jobcenter-header {
  margin-bottom: 120px;
  margin-top: 80px;
  max-width: 1096px;
  width: 100%;
}
.jobcenter-header-subheader-inner {
  display: flex;
  align-items: center;
}
.jobcenter-header-subheader-inner h3 {
  margin-right: 16px;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
}
.jobcenter-header-subheader-inner img {
  width: 16px;
  height: 16px;
}
#jobcenter-list-headline {
  margin-top: 120px;
  margin-bottom: 48px;
  font-size: 48px;
  position: relative;
  font-weight: 600;
}
#jobcenter-list-headline span {
  position: absolute;
  color: #cccfd2;
  margin-top: -2px;
  margin-left: 4px;
  font-size: 36px;
}

.jobcenter-banner img {
  max-height: 432px;
}
.jobcenterdetail-detail {
  max-width: 1096px;
  width: 100%;
}
.jobcenter-header-subheader {
  display: flex;
  justify-content: space-between;
}
.jobcenter-header-subheader p {
  flex-basis: 60%;
  color: #667079;
}
/*Jobcenterdetail*/
#JobNotAvailable {
  margin-top: 120px;
}

#jobcenterdetail-introtext {
  max-width: 648px;
  margin-top: 16px;
  color: #667079;
}

.jobcenterdetail-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.jobcenterdetail-inner {
  max-width: 1096px;
  width: 100%;
}
.jobcenterdetail-banner {
  max-width: 1096px;
}
.Jobcenter-fallback-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
}
.Jobcenter-fallback-wrap h2 {
  font-weight: 600;
  font-size: 24px;
}
.Jobcenter-fallback-wrap p {
  color: #667079;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
}
.Jobcenter-fallback-wrap a {
  padding: 16px 32px;
  background-color: #04468c;
  color: white;
  font-size: 13px;
  border-radius: 24px;
  cursor: pointer;
}
.jobcenterdetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
}
.jobcenterdetail button {
  background-color: #04468c;
  border-radius: 24px;
  margin-top: 32px;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  padding: 16px 32px 16px 32px;
  border: 0px;
  cursor: pointer;
}
.jobcenterdetail h2 {
  font-size: 48px;
  font-weight: 600;
  color: #001020;
  margin-top: 80px;
  margin-bottom: 24px;
}
.jobcenterdetail-banner img {
  width: 100%;
}
.jobcenterdetail-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 120px;
  margin-top: 120px;
}
.jobcenterdetail-text-inner {
  max-width: 648px;
  width: 100%;
}
.jobcenterdetail-about-company {
  margin-top: 120px;
}
.jobcenterdetail-about-company p {
  color: #667079;
}
.jobcenterdetail-data h3,
.jobcenterdetail-about-company h3 {
  margin-bottom: 24px;
}
.jobcenterdetail-data {
  margin-top: 64px;
}
.jobcenterdetail-data li {
  margin-left: 16px;
  color: #667079;
}
.jobcenter-jobcards a {
  border-top: 1px solid #f2f3f4;
}
.jobcenter-jobcard p {
  color: #667079;
  margin-top: 4px;
  font-size: 12px;
}
.jobcenter-jobcards a:last-child {
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
}

.jobcenterdetail-text-headline h2 {
  font-size: 40px;
}
.job_detail h3 {
  margin-bottom: 16px;
  margin-top: 24px;
}
.job_detail h3:first-child {
  margin-top: 0px;
}
.job_detail li {
  margin-left: 16px;
  color: #667079;
  margin-top: 8px;
}
.job_detail strong {
  margin-top: 16px !important;
  color: #001020;
}
.job_detail p {
  margin-top: 24px !important;
  margin-bottom: 16px;
  color: #667079;
}
.job_detail table td span {
  padding-bottom: 24px !important;
}

.jobcenterdetail-text-inner li {
  margin-left: 16px;
  color: #667079;
  margin-top: 8px;
}
.jobcenterdetail-text-inner ul li:last-child {
  margin-bottom: 24px;
}
.jobcenterdetail-text-inner h4 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
}
.jobcenterdetail-text-inner p {
  color: #667079;
}
.jobcenterdetail-text-inner h2 {
  margin-bottom: 16px;
  font-size: 48px;
  font-weight: 600;
}
.jobcenterdetail-text-inner h3 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
}
.jobcenterdetail-text-inner h4 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
}
.jobcenterdetail-text-inner h6 {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}
.JobDetail-htmlinclude-footer table {
  width: 100% !important;
  margin-bottom: 24px;
}
.JobDetail-htmlinclude-footer table td {
  color: #667079;
}
.JobDetail-htmlinclude-footer table td strong {
  color: #001020;
}
.JobDetail-htmlinclude-footer table td a {
  color: #04468c;
}
