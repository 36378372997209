.NeedHelp-wrap{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
}
.NeedHelp-inner{
    max-width: 1096px;
    width: 100%;
}
.NeedHelp-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
#NeedHelp-StartChat-resp{
    display: none;
}
.NeedHelp-card h3{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #001020;
    margin-bottom: 16px;
}
.NeedHelp-Head{
    display: flex;
    gap: 24px;
    background-color: #f2f3f4;
    padding: 16px;
    border-radius: 24px;
    position: relative;
  
    margin-bottom: 48px;
}
.NeedHelp-Head img{
    width: 120px;
    height: 120px;
    border-radius:8px;
}
.NeedHelp-img{
    display: flex;
    align-items: center;
}
.NeedHelp-card-actionline{
    display: flex;
}
.NeedHelp-card-actionline a , .NeedHelp-card-actionline button{
    margin-left: 18.75px;
    color: #001020;
    border: none;
    cursor: pointer;
    background-color: transparent;
}
.NeedHelp-card-actionline button{
    border-bottom: 1px solid #001020;
    font-size: 16px;
}
.NeedHelp-Head-text p{
    color: #667079;
}
.NeedHelp-Head-text h2{
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #001020;
    margin-bottom:16px ;
}
#NeedHelp-weird-square-with-rotation{
    position: absolute;
    width: 24px;
    left: 50px;
   bottom: -12px;
    transform: rotate(45deg);
    height: 24px;
    background-color: #f2f3f4;
    z-index: 1;
}
.NeedHelp-cards .NeedHelp-card:first-child a,.NeedHelp-cards .NeedHelp-card:last-child a,.NeedHelp-cards .NeedHelp-card:nth-child(2) a{
    border-bottom: 1px solid #001020;
}