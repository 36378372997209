.AdminCustomerOverview-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.AdminCustomerOverview-inner {
  width: 100%;
  max-width: 1320px;

  padding-bottom: 40px;
  padding-top: 120px;
}
.AdminCustomerOverview-impersonate {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: maroon !important;
}
#AdminCustomerOverview-Table-Head td {
  padding-bottom: 10px;
  border-bottom: 1px solid #667079;
  margin-bottom: 10px;
}
.AdminCustomerOverview-inner td {
  padding-left: 20px;
  padding-right: 20px;
  height: 48px;
}
.AdminCustomerOverview-gotouser {
  background-color: #04468c;
  padding: 8px 16px;
  border-radius: 24px;
  margin-top: 5px;
  color: white;
}

.AccountCustomerOverview-filterUsers {
  display: flex;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 16px;
}
.AccountCustomerOverview-filterUsers button {
  background-color: #04468c;
  padding: 8px 16px;
  border-radius: 24px;
  color: white;
}
.AdminCustomerOverview-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 16px;
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid #001020;
  align-items: center;
}
.AdminCustomerOverview-pagination span {
  font-weight: 600;
}
.AdminCustomerOverview-pagination button {
  background-color: #001020;
  padding: 8px;
  border-radius: 8px;
  color: white;
  border: none;
}
.AdminCustomerOverview-inner table {
  width: 100%;
}
