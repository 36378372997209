.SystemFinderEntry-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f2f3f4;
}
.SystemFinderEntry-inner {
  max-width: 1096px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.SystemFinderEntry-cards-inner {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.SystemFinderEntry-Card {
  display: flex;
  flex-direction: column;

  cursor: pointer;
}
.SystemFinderEntry-Card h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  max-width: 201px;
  background-color: #fff;
  padding-top: 19px;
  padding-left: 16px;
  max-height: 72px;
  margin-top: -3px;
  padding-bottom: 48px;
}
.SystemFinderEntry-Card:last-of-type {
  height: 215px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.SystemFinderEntry-Headline p {
  font-size: 16px;
  color: #667079;
  margin-bottom: 24px;
  margin-top: 16px;
  max-width: 800px;
}
.SystemFinderEntry-Headline h2 {
  font-size: 48px;
  font-weight: 600;
}
.SystemFinderEntry-cards h3:first-child {
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 24px;
}
.SystemFinderEntry-Card:last-child h3 {
  font-size: 16px !important;
  color: #667079;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.SystemFinderEntry-Card:last-child h3 span {
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.32px;
  display: block;
  width: 100%;
  text-decoration: underline;
  margin-bottom: 16px;
}
.SystemFinder-Breadcrumbs-wrap {
  padding-bottom: 24px;
}
.SystemFinderEntry-Card:hover {
  opacity: 0.7;
  transition: 0.3s;
  cursor: pointer;
}
