#Block404-Banner-Image img {
  width: 100%;
  margin-bottom: -5px;
  min-width: 720px;
  min-height: 200px;
}
#Block404-Banner-Image {
  width: 100%;
  position: relative;
}
#Block404-Banner-Text-wrap {
  position: absolute;
  margin: 0 auto;
  max-width: 1096px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#Block404-Banner-Text-wrap h2 {
  font-size: 72px;
  font-weight: 600;
  max-width: 548px;
  color: #001020;
}
#Block404-Banner-Text-wrap p {
  margin-top: 16px;
  color: #001020;
  max-width: 548px;
}
