.AboutBlock-wrap,
.AboutBlock-Head-Wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 120px;
}
.AboutBlock-Head-Wrap {
  background-color: #f2f3f4;
}

.AboutBlock-scroll-buttons-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: #f2f3f4;
}
.AboutBlock-scroll-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1096px;
  height: 64px;
  align-items: center;
}
.AboutBlock-scroll-buttons div ul {
  display: flex;
  justify-content: center;
  gap: 16px;
}
.AboutBlock-scroll-buttons div ul button {
  background-color: transparent;
  border: none;
  color: #667079;
  cursor: pointer;
}
.AboutBlock-scroll-buttons ul li {
  list-style: none;
}
.AboutBlock-careerbutton {
  background-color: #04468c;
  height: 32px;
  color: white;
  border-radius: 24px;
  font-size: 13px;

  text-align: center;
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  text-decoration: none;
}
.responsiveAboutBtn {
  display: none;
  height: unset !important;
  padding: 16px 24px !important;
}
.AboutBlock-wrap {
  margin-bottom: 0px !important;
}
.AboutBlock-inner {
  max-width: 1096px;
  width: 100%;
}
.About-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.AboutBlock-cards div {
}

.About-card {
  margin-bottom: 64px;
  padding-bottom: 64px;
  border-bottom: 1px solid #f2f3f4;
}
.AboutBlock-cards .About-card:last-child {
  border-bottom: 0px solid #f2f3f4;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.AboutBlock-cards div:last-child {
  margin-bottom: 0px;
}
.AboutBlock-cards {
  margin-top: 120px;
  margin-bottom: 120px;
}
.AboutBlock-cards h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #001020;
}
.AboutBlock-text {
  color: #667079;
}
.AboutBlock-texts p {
  margin-bottom: 16px;
}
.AboutBlock-texts p b {
  color: #667079;
}
.AboutBlock-texts p:last-child {
  margin-bottom: 0px;
}
.AboutBlock-Head {
  max-width: 1096px;
  width: 100%;
}
.AboutBlock-Head p {
  margin-top: 24px;
  color: #667079;
  max-width: 536px;
}
.AboutBlock-Head h2 {
  font-size: 72px;
  font-weight: 600;
  color: #001020;
}
.stickyscrollbar {
  position: absolute;
  top: 80px;
}
