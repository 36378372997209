#shipping-textpage #api-data p strong {
  line-height: 2;
  color: #001020 !important;
  font-weight: 600;
}
#shipping-textpage #api-data p span {
  color: #667079 !important;
}
#api-data h1 {
  font-weight: 600 !important;
  font-size: 32px !important;
}
#api-data h2 {
  font-size: 48px;
  font-family: 600;
  color: #001020;
  margin-bottom: 32px;
  margin-left: 0px;
}

#api-data h3 {
  font-size: 32px;
  font-family: 600;
  color: #001020;
  margin-bottom: 16px;
}
#api-data h4 {
  font-size: 24px;
  font-family: 600;
  margin-bottom: 16px;
  color: #001020;
}
#api-data h5 {
  font-size: 20px;
  font-family: 600;
  margin-bottom: 16px;
  color: #001020;
}
#api-data h6 {
  font-size: 16px;
  margin-bottom: 16px;
  font-family: 600;
  color: #001020;
}
#api-data ul {
  margin-top: 16px;
}
#api-data li {
  color: #667079;
  margin-left: 16px;
  margin-top: 8px;
}
#api-data p {
  color: #667079;
  margin-bottom: 8px;
}
