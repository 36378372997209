.Contact-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  margin-top: 120px;
}
.Contact-inner {
  max-width: 1096px;
  width: 100%;
}
.Contact-inner a {
  color: #04468c;
}
.ContactsQrailing h3,
.ContactsQrailing2 h3,
.ContactsQrailing3 h3,
.ContactsQrailing4 h3,
.ContactsQrailing5 h3 {
  display: none;
}
.ContactpageLineHq {
  display: flex;
  justify-content: right;
  margin-bottom: 32px;
}
.ContactpageLineHq div:last-child {
  color: #667079;
  width: 100%;
  max-width: 400px;
}
.ContactpageLineHq div:first-child {
  font-weight: 600;
  width: 100%;
  max-width: 224px;
}
#lastContactHQ2 div:last-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ContactsQrailing,
.ContactsQrailing3,
.ContactsQrailing4,
.ContactsQrailing5 {
  display: none;
}
.headlineContacts {
  margin-top: -23px;
  padding-bottom: 23px;
}
.visibleQR {
  display: block !important;
  margin-top: -32px;
}
.EmailLineContact {
  text-decoration: underline;
}
.Contact-SA h3,
.Contact-NA h3,
.Contact-Europe h3,
.Contact-Africa h3,
.Contact-Asia h3 {
  font-weight: 600;
  cursor: pointer;
}
.ContactsQrailingVisible {
  display: flex;
  padding-top: 32px;

  padding-bottom: 32px;
  border-top: 1px solid #f2f3f4;

  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.Contact-NA h2,
.Contact-SA h2,
.Contact-Europe h2,
.Contact-Asia h2,
.Contact-Africa h2 {
  font-size: 48px;
  font-weight: 600;
  color: #001020;
  margin-top: 32px;
  padding-top: 47px;
  border-top: 1px solid #f2f3f4;
  margin-bottom: 80px;
}
.Contact-Headline {
  font-size: 48px;
  font-weight: 600;
  color: #001020;
}
.Contact-Buttons {
  display: flex;
  gap: 8px;
}
.Contact-Buttons button {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  color: #001020;
  background: #f2f3f4;
  border-radius: 24px;
  border: none;
  cursor: pointer;

  height: 48px;
}
.activetogglebtn {
  background-color: #04468c !important;
  color: white !important;
}
.Contact-Header {
  display: flex;
  margin-bottom: 36px;
  padding-bottom: 36px;
  border-bottom: 1px solid #f2f3f4;
  justify-content: space-between;
}
.hidecontactblock {
  display: none !important;
}
.ContactsQrailingVisible button {
  width: 48px;
  height: 48px;
  background-color: #f2f3f4;
  border: none;
  border-radius: 24px;
  position: relative;
  z-index: 2;
  display: flex;
  color: #001020;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
