.ThreeCardGrid-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}
.ThreeCardGrid-inner {
  display: flex;
  width: 100%;
  max-width: 1096px;
  flex-direction: column;
  justify-content: center;
}
.ThreeCardGrid-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
#ThreeCardGridFour {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
#ThreeCardGrid-grey-wrap {
  background-color: #f2f3f4 !important;
}
#ThreeCardGridFour .ThreeCardGrid-card a {
  background-color: #04468c;
  color: white;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 24px;
  margin-top: 48px;
}
.ThreeCardGrid-card {
  text-align: center;
}
.ThreeCardGrid-card h3 {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 16px;
}
#ThreeCardGridFour h3 {
  font-size: 16px;
  margin-bottom: 36px;
  margin-top: 16px;
}
.ThreeCardGrid-card p {
  color: #667079;
}
.ThreeCardGrid-head {
  margin-bottom: 48px;
  width: 100%;
  text-align: center;
}
.ThreeCardGrid-head h4 {
  font-weight: 400;
  font-size: 24px;
}
.ThreeCardGrid-head h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 24px;
}
.ThreeCardGrid-head p {
  color: #667079;
}
