.TrainingsOverview-Link {
  text-decoration: none;
  color: #001020;
  padding: 16px 32px;
  font-size: 14px;
  border-radius: 24px;
  line-height: 16px;
  background-color: #f2f3f4;
}
.TrainingsOverview-Card-inner-left p {
  margin-top: 8px;
  color: #667079;
}
.TrainingsOverview-Card {
}
.TrainingsDetail-text {
  display: flex;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 64px;
  gap: 24px;
}
.TrainingsDetail-text div:first-child {
  max-width: 424px;
  width: 100%;
  font-size: 32px;
}
.TrainingsDetail-text li {
  margin-left: 16px;
  margin-top: 8px;
}
.TrainingsDetail-text div:last-child {
  max-width: 648px;
}
.TrainingsOverview-contact-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.TrainingsOverview-contact-inner h3 {
  font-size: 24px;
  color: #001020;
}
.TrainingsOverview-contact-inner a {
  padding: 16px 32px;
  background-color: #04468c;
  color: white;
  font-size: 13px;
  border-radius: 24px;
  cursor: pointer;
}
.TrainingsOverview-contact-inner {
  display: flex;
  flex-direction: column;
  max-width: 648px;
  align-items: center;
  margin-bottom: 64px;
}
.TrainingsOverview-contact-inner p {
  margin-top: 24px;
  margin-bottom: 32px;
  color: #667079;
}
#trainingsoverview-header {
  max-width: 1096px;
}
.jobcenterdetail {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}
.jobcenter-jobcards .jobcenter-jobcard {
  border-top: 1px solid #f2f3f4;
  padding-top: 24px;
  color: #001020;
  padding-bottom: 24px;
}
.jobcenter-jobcards .jobcenter-jobcard:last-child {
  border-bottom: 1px solid #f2f3f4;
}

.TrainingsOverview-Link-responsive {
  text-decoration: none;
  background-color: #f2f3f4;

  border-radius: 50%;
  cursor: pointer;
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
}
#hiddenid {
  display: none;
}
#AddPaddingId {
  padding-top: 64px;
}
