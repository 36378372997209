.VideoList-Headlineblock-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 48px;
}
.VideoList-Headlineblock-inner {
  max-width: 1096px;
  width: 100%;
}
.VideoList-Headlineblock-inner h2 {
  margin-bottom: 24px;
  font-size: 32px;
  text-align: center;
}
.VideoList-Headlineblock-inner p {
  font-size: 16px;
  color: #667079;
  margin-top: 8px;
}
.VideoList-Headlineblock-inner p b {
  color: #001020;
}
