.FormFileBlock-warp {
  display: flex;
  justify-content: center;
  width: 100%;
}
.FormFileBlock-inner {
  max-width: 648px;
  width: 100%;
  margin-bottom: 120px;
}
.FormFileBlock-Textinputs {
  display: flex;
  width: 100%;
  justify-content: center;
}
.FormFileBlock-Textinputs-inner {
  max-width: 648px;
  width: 100%;
  display: flex;

  justify-content: center;
}
#FormFileBlock-PrivacyPolicyLabel a {
  color: #04468c;
}
.FormFileBlock-Headline-Inner h3 {
  font-size: 40px;
  color: #001020;

  font-weight: 600;
}

.greyForm input,
.greyForm textarea,
.greyForm select {
  background-color: white !important;
}
.FormFileBlock-Textinputs-inner input,
.FormFileBlock-Textinputs-inner textarea {
  width: 100%;
  border: 0px;
  background-color: #f2f3f4;
  height: 48px;
  padding: 16px;
}
.select-container {
  margin-left: 0px !important;
}
.select-container {
  position: relative;
  display: inline-block;
}

.select-container::after {
  content: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/selecticon.png');
  position: absolute;
  right: 16px;
  top: 14px;
  font-size: 8px;
  width: 8px;

  transform: scale(0.25);
  pointer-events: none;
}

.select-container select {
  padding-left: 16px !important;
}

.select-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding-right: 30px; /* Make room for the custom arrow */
}
.FormFileBlock-Textinputs-inner textarea {
  height: 96px;
}
.FormFileBlock-Textinputs-inner div:last-child {
  margin-left: 32px;
}
#ApplyButton {
  padding: 16px 32px;
  background: #04468c;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  width: 210px;
  border: 0px;
  height: 48px;
}
.FormFileBlock-CatalogSelection div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  text-align: end;
}
.FormFileBlock-CatalogSelection {
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  margin-top: 48px;
}
.FormFileBlock-Uploadfields {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-right: 8px;
}
.FormFileBlock-CatalogSelection input {
  background-color: #04468c;
  width: 28px;
  height: 28px;
}
.FormFileBlock-CatalogSelection label {
  display: flex;
  gap: 24px;
  text-align: left;
  align-items: center;
  font-size: 14px;
}
#FormFileBlock-Salutation,
#FormFileBlock-Location,
#FormFileBlock-EnglishSkills,
#FormFileBlock-Channel {
  height: 48px;
}
.FormFileBlock-Textinputs-inner div {
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: space-between;
}
.FormFileBlock-Textinputs-inner div label {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
}
.FormFileBlock-Textinputs-inner div label span {
  margin-bottom: 8px;
  font-weight: 600;
  color: #001020;
}

.FormFileBlock-Textinputs-inner div label:first-child {
  margin-top: 0px;
}
.FormFileBlock-Fileupload {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.FormFileBlock-Fileupload-inner {
  max-width: 624px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.PrivacyJob {
  display: flex;
  gap: 24px;
}

#FormFileBlock-Document-Certificate,
#FormFileBlock-Document-Others,
#FormFileBlock-Document-Letter,
#FormFileBlock-Document-CV,
#FormFileBlock-Document {
  display: none;
}
#FormFileBlock-Document-label {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 52px;
  background: black;
  border-radius: 24px;
  cursor: pointer;
  color: white;
  width: 215px;
  border: 0px;
  position: relative;
}
#FormFileBlock-uploadpdf-text {
  position: absolute;
}
#FormFileBlock-PrivacyPolicyLabel {
  display: flex;

  flex-direction: column;
  gap: 8px;
}
#FormFileBlock-PrivacyPolicyLabel input {
  height: 32px;
  width: 32px;
  line-height: 48px;
}
#FormFileBlock-Document-label svg {
  position: absolute;
  top: 16px;
  right: 24px;
}
#docsError {
  color: #ff9494;
  width: 100%;
  margin-top: 16px;
  text-align: center;
}
.FormFileBlock-Headline {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.FormFileBlock-Headline-Inner {
  max-width: 648px;
  width: 100%;
}
.FormFileBlock-Inner h3 {
  font-size: 24px;
  color: #001020;
  width: 648px;
}

.FormFileBlock-CommentSection {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
}
.FormFileBlock-CommentSection-inner {
  max-width: 648px;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.FormFileBlock-CommentSection-inner label {
  display: flex;
  flex-direction: column;
  height: 128px;

  color: #001020;
}
.FormFileBlock-CommentSection-inner label span {
  font-weight: 600 !important;
  margin-bottom: 8px;
}
.FormFileBlock-CommentSection-inner label textarea {
  width: 100%;
  height: 100%;
  border: 0px;
  padding: 8px;
  background-color: #f2f3f4;
}
.greyForm .FormFileBlock-CommentSection {
  background-color: #f2f3f4;
}
.greyForm .FormFileBlock-CommentSection-inner textarea {
  background-color: white;
}
.FormFileBlock-semiwrap {
  max-width: 1096px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
