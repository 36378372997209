.statss3,
.statss6,
.statss7,
.statss10 {
}

.statss1,
.statss2,
.statss4,
.statss5,
.statss8,
.statss9,
.statss11,
.statss12 {
}
/*
.Projects-image-cards{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    
}*/
.project-img {
  width: 100%;
  height: 100%;
}

#proj-links {
  position: relative;
}
.proj-links-inner {
  position: absolute;
  z-index: 10;
  bottom: 0px;

  display: none;

  width: 100%;

  font-weight: 600;
}
.linkfix-proj {
  height: 100% !important;
  display: block;
}
.projectblock-col2 #proj-links .proj-links-inner {
  position: absolute;
  z-index: 10;
}
#proj-links:hover .proj-links-inner {
  display: flex;
  animation: openup2 0.4s ease-in-out;
}
#proj-links:hover .project-img {
  opacity: 0.9;
  cursor: pointer;
  transition: 0.3s;
}
.proj-links-inner p {
  text-decoration: none;
  font-size: 24px;
  color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  font-weight: 600;
  padding-left: 16px;
  padding-top: 120px;
  padding-bottom: 24px;
  width: 100%;

  opacity: 95%;

  max-width: 100%;
}

#GreyProjectGrid {
  background-color: #f2f3f4;
}
/*
.statss0 { grid-area: 1 / 1 / 2 / 2; }
.statss1 { grid-area: 2 / 1 / 3 / 2; }
.statss2 { grid-area: 1 / 2 / 3 / 4; }
.statss3 { grid-area: 1 / 4 / 2 / 5; }
.statss4 { grid-area: 2 / 4 / 3 / 5; }
.statss5 { grid-area: 1 / 5 / 3 / 7; }
.statss6 { grid-area: 3 / 1 / 5 / 3; }
.statss7 { grid-area: 3 / 3 / 4 / 4; }
.statss8 { grid-area: 4 / 3 / 5 / 4; }
.statss9 { grid-area: 3 / 4 / 5 / 6; }
.statss10 { grid-area: 3 / 6 / 4 / 7; }
.statss11 { grid-area: 4 / 6 / 5 / 7; }
.statss12 { grid-area: 5 / 1 / 6 / 2; }
.statss13 { grid-area: 6 / 1 / 7 / 2; }
.statss14 { grid-area: 5 / 2 / 7 / 4; }
.statss15 { grid-area: 5 / 4 / 6 / 5; }
.statss16 { grid-area: 6 / 4 / 7 / 5; }
.statss17 { grid-area: 5 / 5 / 7 / 7; }
.statss18 { grid-area: 7 / 1 / 9 / 3; }
.statss19 { grid-area: 7 / 3 / 8 / 4; }
.statss20 { grid-area: 8 / 3 / 9 / 4; }
.statss21 { grid-area: 7 / 4 / 9 / 6; }
.statss22 { grid-area: 8 / 6 / 9 / 7; }
.statss23 { grid-area: 7 / 6 / 8 / 7; }
.statss24 { grid-area: 9 / 1 / 10 / 2; }
.statss25 { grid-area: 10 / 1 / 11 / 2; }
.statss26 { grid-area: 9 / 2 / 11 / 4; }
.statss27 { grid-area: 9 / 4 / 10 / 5; }
.statss28 { grid-area: 10 / 4 / 11 / 5; }
.statss29 { grid-area: 9 / 5 / 11 / 7; }
.statss30 { grid-area: 11 / 1 / 13 / 3; }
.statss31 { grid-area: 11 / 3 / 12 / 4; }
.statss32 { grid-area: 12 / 3 / 13 / 4; }
.statss33 { grid-area: 11 / 4 / 13 / 6; }
.statss34 { grid-area: 11 / 6 / 12 / 7; }
.statss35 { grid-area: 12 / 6 / 13 / 7; }



*/

.morebtn1 {
  display: grid;
  grid-template-columns: 1px calc(100% - 1px);
}

.morebtn-btn {
  display: flex;
  justify-content: center;
}

.morebtn-btn button {
  padding: 16px 32px;
  font-size: 14px;
  background-color: #04468c;
  color: white;
  cursor: pointer;
  border: 0px;
  font-weight: 400;
  border-radius: 24px;

  margin-top: 48px;
}
.morebtn-btn button:hover {
  opacity: 0.9;
  transition: 0.3s;
}
.projects-attributes {
  display: block !important;
  background-color: white;
  margin-top: -110px;
  width: 75%;
}
.projects-inner-textside {
  display: none;
}
.Projects-head {
  font-size: 28px;
  color: #04468c;
}
.Projects-text {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 5px;
}
.Projects-Wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1096px;

  align-items: center;
  width: 100%;
  z-index: 0;
  background: transparent;
  position: relative;
}
.Projects-Filter {
  display: none;
}
.projectblock-page {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

/*
.projectblock-row1{
    display:grid;
    grid-template-columns: 312px calc(100% - 312px);
    gap: 24px;
}
.projectblock-row1 img{
   max-height: 656px;
}*/
.projectblock-row1 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);

  gap: 24px;
}
.projectblock-row1 img {
  max-height: 656px;
}

.projectblock-col1 {
  grid-area: 1 / 1 / 2/ 4;
}
.projectblock-col1 img {
}
.projectblock-col2 {
  grid-area: 1 / 4 / 2 / 11;
}
.projectblock-row2 {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.projectblock-col1 {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
}
.projectblock-head {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;

  justify-content: space-between;
}
.projectblock-head h2 {
  font-size: 48px;
  color: #001020;
  line-height: 56px;
  font-weight: 600;
}
.projectblock-text h2 {
  position: relative;
}
.projectblock-text h2 span {
  color: #cccfd2;
  position: absolute;
  font-size: 24px;
  top: -8px;
  margin-left: 8px;
}
.projectblock-filter {
  display: flex;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
  flex-wrap: wrap;
}
.projectblock-filter button {
  color: #001020;
  border: none;
  padding: 16px;
  height: 48px;
  background: #f2f3f4;
  border-radius: 24px;
  cursor: pointer;
}
.projectblock-filter button:hover {
  transform: scale(1);
  transition: 0.3s;
  opacity: 0.7;
}
.projectblock-outer-wrap {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
}
.projectblock-col1 {
  width: 100%;
  animation: openup2 1s ease-in-out;
}
.projectblock-page {
  margin-top: 24px;
}

.animateCard {
  animation: openup2 0.5s ease-in-out;
}
.Projectgrid-flags {
  display: flex;
  margin-top: 8px;
  background-color: none !important;
  flex-wrap: wrap;

  gap: 8px;
}
.proj-links-inner p {
  word-wrap: break-word;
}
.Projectsgrid-flag {
  font-size: 14px !important;
  font-weight: 300 !important;
  padding: 4px 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  color: rgba(255, 255, 255, 0.6) !important;
}
.Projects-Wrapper .activegridbtn {
  background-color: #04468c;
  color: white;
  border: 0px;
}
