.SwiperSlider-wrap,
.SwiperSlider2-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.SwiperSlider-inner {
  max-width: 1096px;
  width: 100%;
}
.SwiperSlider-text a {
  border-radius: 24px;
  padding: 16px 32px;
  font-size: 13px;
  background-color: #04468c;
  color: white;
  border: none;

  text-decoration: none;
  cursor: pointer;
}
.swiper-slide {
}
@media (min-width: 1024px) {
  .swiper-slide {
  }
}
.swiper-wrapper a {
  text-decoration: none;
  color: #001020;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #04468c;
}
.swiper-button-prev,
.swiper-button-next,
.swiper-button-disabled {
  opacity: 0 !important;
}
.swiper-scrollbar-drag {
  background-color: #04468c;
  background-color: #04468c;
}
.swiper-scrollbar {
  /*background: transparent;*/
}
.swiper-scrollbar {
  left: 0px !important;
}
#HovertextRailingEnter {
  position: relative;
  cursor: pointer;
  margin-left: 16px;
}
#product-card:hover {
  opacity: 0.8;
  transition: 0.5s;
}
#hovertextRailing {
  position: absolute;
  z-index: 1000;
  display: none;
  background-color: #f2f3f4;
  padding: 8px;
  box-shadow: 1px 2px 4px #667079;
}
#HovertextRailingEnter:hover #hovertextRailing {
  display: block;
}
.systemsliderSystemspan {
  font-weight: 600;
  margin-top: 8px;
  font-size: 18px;
}
.systemsliderSystemtext {
  margin-top: 8px;
  color: #667079;
}
.swiper {
  z-index: 0 !important;
}
@media (max-width: 768px) {
  .swiper-scrollbar {
    left: 16px !important;
    width: calc(100% - 32px) !important;
  }
}
.Swiper-CategorySlider .systemimageslider {
  background-color: #f2f3f4;
}
