.NewsOverview-grid {
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 48px;
  display: grid;
  column-gap: 81px;
}
.NewsOverviewNew-wrap {
  padding-top: 120px;
  padding-bottom: 120px;
}
.NewsOverview-card img {
  max-height: 200px;
}
.NewsOverview-pagination button {
  margin: 4px;
  background: white;
  border: none;
  font-size: 16px;
  padding: 8px;
  border-radius: 24px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.NewsOverview-card h3 {
  margin-top: 16px;
  color: #001020;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
}
.NewsOverview-card p {
  margin-top: 16px;
  color: #667079;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 16px;
  padding-right: 16px;
}
.NewsOverview-card h6 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  color: #667079;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -8px;
}
.NewsOverview-card {
}
.NewsOverview-card:hover {
  opacity: 1;
}
.NewsOverview-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f2f3f4;
  padding-top: 120px;
  padding-bottom: 120px;
}
.NewsOverview-inner {
  margin-top: 56px;
}
.NewsOverview-header h2 {
  color: #001020;
  font-size: 72px;
  font-weight: 600;
  margin-bottom: 16px;
}
.NewsOverview-header {
  width: 100%;
  max-width: 1096px;
}
.NewsOverview-pagination {
  width: 100%;
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.ActivePageButton {
  background-color: #04468c !important;
  color: white;
  width: 32px;
  height: 32px;
  font-weight: 600 !important;
}
.NewsDetailCustomText {
  margin-top: 120px;

  font-family: Inter, sans-serif;
  margin-bottom: 120px;
}
.NewsDetailCustomText img {
  max-width: 100%;
}
.NewsDetailCustomText td:last-child {
  margin-top: 12px;
}
.NewsDetailCustomText td strong {
  padding-bottom: 16px;
}
.NewsDetailCustomText p {
  font-size: 16px !important;
  color: #667079 !important;
  margin-bottom: 16px !important;
  letter-spacing: 0px !important;
}
.NewsDetailCustomText li {
  margin-left: 16px;
  margin-top: 8px;
  color: #667079;
}
.NewsDetailCustomText h6 {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 24px !important;
  color: #001020 !important;
}
.NewsDetailCustomText h3 {
  font-size: 32px !important;
  font-weight: 600 !important;
  margin-bottom: 24px !important;
  color: #001020 !important;
}
.NewsDetailCustomText h4 {
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-bottom: 24px !important;
  color: #001020 !important;
}
.NewsDetailCustomText h2 {
  font-size: 48px !important;
  font-weight: 600 !important;
  color: #001020 !important;
  margin-bottom: 24px !important;
}
.NewsDetail-inner {
  max-width: 648px;
  width: 100%;
}
.NewsDetail-wrap {
  width: 100%;
  justify-content: center;
  display: flex;
}
.NewsDetail-inner .NewsDetailCustomText div p {
  color: #667079 !important;
  line-height: 24px !important;
  margin-top: 16px;
}
.NewsDetail-inner .NewsDetailCustomText div h1 {
  font-size: 32px;
  font-weight: 600;
  color: #001020 !important;
}
.NewsDetail-inner .NewsDetailCustomText div strong {
  color: #001020 !important;
}
.NewsDetail-inner td {
  color: #667079;
}
#NewsDate {
  color: #667079;
  margin-bottom: -64px;
  margin-top: 64px;
  font-weight: 600;
  font-size: 18px;
}
