.Service-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 120px;
  background-color: #f2f3f4;
}
.Service-inner {
  max-width: 1096px;
  width: 100%;
}
.activebtndocs {
  background-color: #04468c !important;
  color: white;
}
.Service-wrap h2 {
  font-size: 72px;
  font-weight: 600;
  color: #001020;
}
.Service-menu-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f2f3f4;
  padding-top: 120px;
  padding-bottom: 120px;
}
.Service-menu-inner {
  width: 100%;
  max-width: 1096px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 48px;
}
.Service-menu-inner div:last-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  border-left: 1px solid #cccfd2;
}
.Service-menu-inner div:first-child {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Service-menu-inner div h2 {
  margin-bottom: 24px;
}
.Service-menu-inner-link {
  width: 100%;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 16px;
  align-items: center;
  color: #001020;
  text-decoration: none;
  justify-content: space-between;
}
#ServicePage-firstLink {
  margin-top: -8px !important;
}
.Service-menu-support-link {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
  color: #001020;
  margin-bottom: 16px;
}
.Service-menu-inner-link svg {
  display: none;
  margin-right: 16px;
}
@media (min-width: 1024px) {
  .Service-menu-inner-link:hover svg {
    display: block;
  }
  .Service-menu-inner-link:hover {
    border-left: 5px solid #04468c;
    background-color: #f2f3f4;
    padding-left: 16px;
  }
}

.Service-menu-inner h2 {
  font-size: 12px;
  font-style: normal;
  color: #667079;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.2px;
}
