.HeaderMainpage-texts {
}
.HeaderMainpage-text-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
}
.HeaderMainpage-texts {
  max-width: 1096px;
  height: 720px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.HeaderMainpage-text-left {
  max-width: 1096px !important;
  width: 100%;
}
#HeaderMainpageButton:hover {
  opacity: 0.8;
  transition: 0.3s;
}

#brand-line {
  /*margin-top: 111.5px !important;*/
  font-weight: 600;
  max-width: 1096px;
  width: 100%;
}
#brand-line-div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  bottom: 58px;
  display: flex;
  justify-content: center;
}
.HeaderMainpage-text-left div {
  margin-top: 204px;
  max-width: 742px;
}
.HeaderMainpage-ServiceDropdown-Card-Link-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderMainpage-Submenu-Card-Service .HeaderMainpage-CardWrap {
  justify-content: flex-start !important;
}
.HeaderMainpage-Submenu-Card-Service {
  padding: 16px;
}
.ShopmenuHeaderWrap .HeaderMainpage-Submenu-Card-Service {
  padding: 0px 16px;
}
.HeaderMainpage-ServiceDropdown-Card-Link-div .HeaderMainpage-whatsNewLink {
  white-space: nowrap;
  padding: 8px 16px;
  text-decoration: underline !important;
  border-radius: 24px;
  margin: 0px !important;
}
.HeaderMainpage-Submenu-Card-Service .HeaderMainpage-CardWrapInner {
  padding-left: 0px !important;
}
.HeaderMainpage-Submenu-Card-Service {
  gap: 16px;
}
.HeaderMainpage-Submenu-Card-Service h3 {
  margin-bottom: 0px !important;
}
.HeaderMainpage-Submenu-Card-Service p {
  display: none;
}
.HeaderMainpage-Card-text {
  margin-bottom: 8px;
}
.Dividerlink {
  display: flex;
  justify-content: space-between;
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
  align-items: center;
  color: #001020 !important;
}
.Dividerlink:hover {
  opacity: 0.8;
  transition: 0.3s;
}

/*Quickfix Category links*/

.Qrailingssubmenu-innerShop .HeaderMainpage-Submenudiv {
  display: none;
}

.Qrailingssubmenu-innerShop #HeaderMainpage-Submenudiv-1 {
  display: block;
}

#Header-RespShopMenu .HeaderMainpage-Submenu:last-of-type {
  display: none;
}
#Headerprojects-safety-text {
  font-size: 24px;
}
.HeaderShopCard {
  width: 204px;
  height: 204px;
  transition: 0.5s;
  background-color: rgb(204, 207, 210) !important;
}
.HeaderShopCard:hover {
  transform: scale(1.05);
  opacity: 0.8;
  transition: 0.5s;
}
.HeaderShopCard h4 {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
}
.Header-ShopCardGrid {
  display: grid;
  padding-left: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

@keyframes fadeInCards {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HeaderMainpageShopLinks {
  display: flex;
  flex-direction: column;
}
.HeaderMainpageShoplinksVisiblePart {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
#Header-RespShopMenu .HeaderMainpage-Submenu-Card-Service {
  display: none;
}
#Header-RespShopMenu .HeaderMainpage-Submenuul-headline {
  display: none;
}
.HeaderShopSubcategoryClass {
  display: none;
}
.HeaderShopSubcategoryShow {
  display: block;
  width: 100%;
}
.HeaderMainpage-ShopLinks span {
  font-weight: 600;
  font-size: 26px;
}
.HeaderShopSubcategoryButton {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}
#Header-RespShopMenu .HeaderMainpage-Submenu {
  gap: 16px;
}
.HeaderShopSubcategoryShow a h4 {
  font-size: 20px !important;
}
.HeaderShopSubcategoryShow .HeaderShopCard h4 {
  letter-spacing: normal;
}
