.InstallerRequest-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.InstallerRequest-inner,
.InstallerRequest-inner-2 {
  max-width: 1096px;
  width: 100%;
}
#InstallerRequestError {
  font-size: 10px;
  color: maroon;

  margin-top: 8px;
}
.InstallerRequest-inner-2 div {
  width: 100%;
  max-width: 648px;
  margin: 0 auto;
  margin-top: 48px;
  font-size: 32px;
}
.InstallerRequest-inner input::placeholder {
  padding-left: 8px;
}
.InstallerRequest-inner .Registerform-inputs {
  padding-top: 24px;
}
.InstallerRequest-inner-3 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.InstallerRequest-inner .Registerform-inputs {
  padding-bottom: 16px;
}
.InstallerRequest-inner-3-button {
  max-width: 1096px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.InstallerRequest-inner-3-button div {
  width: 100%;
  max-width: 648px;
  margin-bottom: 64px;
  font-size: 32px;
}
.InstallerRequest-inner .Registerform-inputs {
  margin: 0 auto;
  width: 100%;
  max-width: 648px;
}
.InstallerRequest-inner-3-button button {
  background-color: #04468c;
  color: white;
  font-size: 13px;
  border: 0px;
  padding: 16px 32px;
  border-radius: 24px;
}
#register-form-comment {
  height: 96px;
  width: 100%;
  background-color: #f2f3f4;
  margin-top: 8px;
  border: 0px;
  padding: 4px;
  max-width: 260px;
}
.InstallerRequest-inner-text {
  max-width: 1096px;
  margin-top: 64px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.InstallerRequest-inner-text div {
  max-width: 648px;
}
.InstallerRequest-inner-text h3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}
.InstallerRequest-inner-text p {
  color: #667079;
  padding-right: 60px;
}
.InstallerRequest-inner-2 h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
.InstallerRequest-banner h2,
.InstallerRequest-banner p {
  color: white !important;
  max-width: 648px !important;
}
