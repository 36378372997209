.NewsletterNew-wrap{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
}
.NewsletterNew-inner{
    max-width: 1096px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.NewsletterNew-left h3{
    font-size: 32px;
    font-weight: 600;
}
.NewsletterNew-left p{
    color: #667079;
    margin-top: 24px;
    font-size: 16px;
    margin-bottom: 32px;
}
.NewsletterNew-left input{
    padding: 16px 32px;
    background-color: #f2f3f4;
    border-radius: 24px;
    margin-right: 8px;
    border: none;
    max-width: 280px;

    width: 100%;
}
.NewsletterNew-left input::placeholder{
    color: #001020;
    font-size: 14px;
}
.NewsletterNew-left button{
    border-radius: 24px;
    padding: 16px 32px;
    font-size: 14px;
    background-color: #04468c;
    color: white;
    border: none;

}
.NewsletterNew-left button:hover{
    opacity: 0.9;
    transition: 0.3s;
}
.NewsletterNew-left form{
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}
.NewsletterNew-left form label{
    display: none;
}
