.systemgrid-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.systemgrid-inner {
  max-width: 1096px;
  width: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 48px;
}
.systemgrid-card a {
  display: flex;
  flex-direction: column;
}
.systemgrid-grid img {
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
}
.systemgrid-grid {
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}
.systemgrid-inner {
  margin-top: 64px;
  margin-bottom: 120px;
}
.systemgrid-head {
  display: flex;
}
.systemgrid-head-text p {
  margin-top: 24px;
  max-width: 400px;
  width: 100%;
}

.systemgrid-card:hover {
  opacity: 0.7;
  transition: 0.2s;
}
.Systemgrid-card-img {
  animation: openup2 0.5s ease-in-out;
  transition: 0.5s;
}

.systemgrid-card a {
  color: #001020;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  text-decoration: none;
}
.systemgrid-head .projectblock-filter {
  align-items: center;
}
.systemgrid-head-text h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.activegridbtn {
  /* background-color: #04468c !important;
  color: white !important;*/
  font-weight: 600;
  padding-left: 16px !important;
  border-left: 5px solid #04468c !important;
  opacity: 0.9;
  transition: 0.3s;
}

.systemgrid-head .projectblock-filter button {
  text-align: left;
  background-color: unset;
  width: 100%;
  border-radius: 0px;
}
.systemgrid-head .projectblock-filter button:hover {
  font-weight: 600;
  border-left: 5px solid #04468c;
  padding-left: 16px;
  opacity: 0.9;
  transition: 0.3s;
}

.systemgrid-head .projectblock-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@keyframes openup2 {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
.systemgrid-wrap .projectblock-filter button {
  padding-left: 0px;
}
