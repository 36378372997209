.howwework2-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f3f4;
  padding-top: 120px;
  padding-bottom: 120px;
}
.howwework2-inner {
  max-width: 1096px;
  width: 100%;
}
.howwework2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.howwework2-header h2 {
  font-size: 48px;
  font-size: 48px;
  font-weight: 600;
  color: #001020;
}
.howwework2-card span {
  font-size: 120px;
  font-weight: 600;
  line-height: 120px;
  color: #f2f3f4;
  display: flex;
  align-items: center;
}
.howwework2-cards .howwework2-card:nth-child(1) {
  padding-right: 32px;
  border-right: 3px solid #f2f3f4;
}
.howwework2-cards .howwework2-card:nth-child(2) {
  padding-left: 32px;
  padding-right: 32px;
}
.howwework2-cards .howwework2-card:nth-child(3) {
  padding-left: 32px;
  border-left: 3px solid #f2f3f4;
}
.howwework2-card h3 {
  margin-top: 24px;
  line-height: 32px;
  font-size: 24px;
  font-weight: 600;
  color: #001020;
  margin-bottom: 16px;
}
.howwework2-card p {
  color: #667079;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.howwework2-card a {
  color: #001020;
  text-decoration: underline;
}
#howwework2-button-resp {
  display: none;
  background-color: #04468c;
  color: white;
  font-size: 13px;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 24px;
  border: none;
  max-height: 45px;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 40px;
}
.howwework2-header div a {
  background-color: #04468c;
  color: white;
  font-size: 13px;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 24px;
  border: none;
  max-height: 45px;
}
#howwework2-button {
  display: none;
}
.howwework2-header {
  margin-bottom: 48px;
}
.howwework2-header div {
  display: flex;
  justify-content: space-between;
}
.howwework2-header h2 {
  margin-bottom: 24px;
}
.howwework2-header div p {
  flex-basis: 50%;
  color: #667079;
}
