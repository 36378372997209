.servicecards2-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f3f4;
  padding-top: 120px;
  padding-bottom: 120px;
}
.servicecards2-inner {
  max-width: 1096px;
  width: 100%;
}
.servicecards2-header h2 {
  font-size: 48px;
  line-height: 56px;
  color: #001020;
  font-weight: 600;
  margin-bottom: 24px;
}
.servicecards2-header p {
  color: #667079;
}
.servicecards-card-inner svg .servicecards2-header {
  margin-bottom: 48px;
}
.servicecards2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  gap: 24px;
}
.servicecards2-card a {
  color: #001020;
}
.servicecards2-card:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.servicecards2-card {
  padding: 40px, 24px, 40px, 24px;
  background-color: white;
  position: relative;
}

.servicecards2-card-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-bottom: 48px;
}
.servicecards2-card-inner svg {
  margin-top: 64px;
  margin-bottom: 24px;
  width: 80px;
  height: 80px;
}
.servicecards2-card p {
  margin-top: 8px;
  font-size: 13px;
  padding-left: 24px;
  padding-right: 24px;
  line-height: 20px;
}
.servicecards2-header {
  margin-bottom: 48px;
}
.servicecards2-header h2 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  color: #001020;
  text-align: left;
  margin-bottom: 24px;
}
.servicecards2-header p {
  max-width: 50%;
  text-align: left;
  color: #667079;
}
