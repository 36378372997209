.SystemFinder-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.SystemFinder-wrap-head {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.SystemFinder-inner {
  width: 100%;
  max-width: 1096px;
}

.SystemFinder-innerHead {
  max-width: 1096px;
  width: 100%;
}
.SystemFinder-innerHead p {
  color: #667079;
  margin-top: 16px;
  font-size: 16px;
}
.SystemFinder-innerHead h2 {
  font-size: 24px;
  color: #001020;
  margin-top: 16px;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 16px;
}

.systemgrid-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
  min-width: 324px;
}
.systemgrid-card {
  display: grid;
  grid-template-columns: 300px 1fr;
}
.container-text-center h3 {
  font-size: 24px;
  font-weight: 600;
  color: #001020;
}
.container-text-center {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.systemfinder-grid img {
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
}
.flowchart-BackBtnDiv {
  align-self: self-start;
}
.systemfinder-grid {
  gap: 16px;
  margin-top: 0px;
  padding-bottom: 32px;
  padding-top: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
  top: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.systemgrid-inner {
  margin-top: 120px;
  margin-bottom: 120px;
}
.systemgrid-head {
  display: flex;
  justify-content: space-between;
}
.systemgrid-head-text p {
  margin-top: 24px;
  max-width: 400px;
  width: 100%;
}

.systemgrid-card:hover {
  opacity: 1;
}
.Systemgrid-card-img {
  animation: openup2 0.5s ease-in-out;
  transition: 0.5s;
}

.systemgrid-card a {
  color: #001020;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  text-decoration: none;
}
.systemgrid-head .projectblock-filter {
  align-items: center;
}
.systemgrid-head-text h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.SystemFinder-wrap .swiper {
  padding-bottom: 24px;
}
.SystemFinder-wrap .activegridbtn {
  background-color: #04468c !important;
  color: white !important;
}
@keyframes openup2 {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.systemgrid-flowchart-wrap .flowchart {
  text-align: center;
}

.systemgrid-flowchart-wrap .cards {
  display: grid;
  grid-template-columns: 170px 170px 170px 170px 1fr;
  margin-top: 20px;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.systemgrid-flowchart-wrap .card {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.card p {
  font-weight: 600;
  margin-top: 16px;
}
.card img {
  width: 100%;
  height: 100%;
}
.systemgrid-flowchart-wrap .card:hover p {
  opacity: 0.7;
}

.systemgrid-flowchart-wrap button {
  background: #04468c;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
}
.systemgrid-flowchart-wrap button img {
  width: 100%;
}

.systemgrid-flowchart-wrap button:hover {
  background: #0056b3;
}

.appearCardAnimation {
  animation: appearCard 0.5s ease-in-out;
  transition: 0.5s;
}
.flowchart h1 {
  font-size: 48px;
}
.flowchart {
}
.flowchart button {
  max-width: 220px;
  width: 100%;
  margin-top: 16px;
}
.flowchart p {
  margin-top: 8px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  font-weight: 600;
  color: #667079;
}

@keyframes appearCard {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
#flowchart-background {
  /*height: calc(100vh - 80px);*/
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: center;
  align-items: center;
}
.Systemfinder-Links a {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  padding: 6px 12px;
  border-radius: 24px;
  background-color: unset;
  text-align: center;
  color: #667079;
}
.Systemfinder-Links a:hover {
  opacity: 0.96;
  transition: 0.3s;
  transform: scale(1.02);
}

.Systemfinder-Links {
  padding-bottom: 16px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;

  z-index: 1;
  gap: 8px;
}

.Systemfinder-Links-inner span svg {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  border-left: 1px solid #667079;
  margin-left: -1px;
  margin-top: -1px;
}
.Systemfinder-Links-inner a {
  display: flex;
  flex-direction: row;
  gap: 1px;
  text-decoration: underline;
}
.SystemFinder-grid-head-btns {
  display: flex;
  justify-content: space-between;
}
.SystemFinder-grid-head {
  padding-top: 16px;
}
.Systemfinder-Links-inner a:first-child {
  background-color: #04468c;
  color: white;
  padding: 16px 32px;
  border-radius: 24px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}
.SystemFinder-grid-head p {
  color: #667079;
  font-size: 16px;
  margin-top: 16px;
}
.SystemFinder-grid-head h2 {
  font-size: 32px;
  line-height: 40px;
}

.SystemFinder-grid-head-btns-inner a {
  padding: 16px 32px;
  font-size: 13px;
  background-color: #04468c;
  height: 48px;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  color: white;
  margin-left: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SystemFinder-wrap {
  padding-bottom: 120px;
}

.SystemFinder-wrap-head {
  padding-top: 32px;
}
.SystemFinder-grid-head-btns-inner button:first-child {
  transform: rotate(180deg);
  margin-right: 8px;
}
.SystemFinder-grid-head-btns-inner button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  min-width: 48px;
  max-width: 48px;
  display: flex;
  background-color: #f2f3f4;
  justify-content: center;
  align-items: center;
}
.SystemFinder-grid-head-btns-inner {
  display: flex;
  align-items: center;
}
.SystemFinder-grid-head-btns-inner button:first-child svg {
}
.SystemFinder-wrap .systemgrid-card:hover .Systemfinder-Links {
  display: flex;
}
.Systemfinder-Links-inner {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}
.Systemfinder-configbtn {
  order: 2;
}
.Systemfinder-Links-inner:nth-child(2) {
  background-color: #f2f3f4;
}
.Systemfinder-BackBtn {
  margin-top: 48px;
}
.Systemfinder-BackBtn button {
  max-width: 120px;
}
.SystemFinder-BreadcrumbButton {
  text-align: left;
  background-color: #f2f3f4;
  color: #001020 !important;
  padding: 8px 16px !important;
  font-weight: 600;
  font-size: 16px;
  max-width: unset !important;
  margin-top: 0px !important;
  border: none;
  min-height: 48px;
}

#activeSysFinderBtn {
  background-color: #f2f3f4 !important;
  border-left: 5px solid #04468c;
  color: #001020 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
#activeSysFinderBtn span svg {
  color: white;
}
#activeSysFinderBtn span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Systemfinder-Breadcrumbs p {
  font-size: 14px;
  color: #001020;
}
#Systemfinder-backbtnClick {
  display: none !important;
}
.SystemFinder-Breadcrumb-wrap div {
  line-height: 32px !important;
}
.Systemfinder-Breadcrumbs button {
  background-color: transparent;
  width: unset;
  padding: unset;
}
#Systemfinder-backbtnClick {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Systemfinder-backbtnClick svg {
  width: 32px;
  height: 32px;
}
.Systemfinder-NumberBtn {
  width: 32px;
  background-color: #04468c;
  height: 32px;
  border-radius: 50%;
}
.SystemFinderOptionImage {
  width: 170px;
  height: 120px;
}
.SystemFinder-Breadcrumb-wrap {
  display: flex;
  gap: 16px;

  align-items: center;
}

.SystemFinder-Breadcrumbs-wrap h2 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  margin-top: 16px;
}
.SystemFinder-BreadcrumbButton {
  animation: appearBtnSysfinder 0.3s ease-in-out;
}

#flowchart-background {
  animation: appearFlowchart 1s ease-in-out;
}
@keyframes appearFlowchart {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appearBtnSysfinder {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
.systemfinder-grid .systemgrid-card img {
  max-width: 300px;
  max-height: 208px;
}

.SystemFinderFindLongWord {
  letter-spacing: -0.52px;
  font-size: 14px !important;
}

.systemgrid-flowchart-wrap .cards .card {
  order: 0; /* Default order */
}

/* Change the order of specific cards */
.systemgrid-flowchart-wrap .cards .card:nth-child(1) {
  order: 3; /* This card will appear after those with order 0 or 1 */
}
.systemgrid-flowchart-wrap .cards .card:nth-child(2) {
  order: 1; /* This card will appear earlier */
}
.systemgrid-flowchart-wrap .cards .card:nth-child(3) {
  order: 0; /* This card will appear later */
}
.systemgrid-flowchart-wrap .cards .card:nth-child(4) {
  order: 2; /* Keep this at the default position */
}
