.LoadingOverlay-anchor {
  position: relative;
  background-color: #001020;
  width: 100%;
  height: 100%;
}

.LoadingOverlay-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: logo-animation calc(1s * var(--speed)) ease-in-out infinite;
}

@keyframes logo-animation {
  0% {
    transform: translate(-50%, -50%) scale(var(--scale));
    opacity: 0.7;
  }
  50% {
    transform: translate(-50%, -50%) scale(calc(var(--scale) + 0.1));
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(var(--scale));
    opacity: 0.7;
  }
}
