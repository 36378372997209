.AdminCustomerOrders-Orderlist li {
  display: flex;
  width: 100%;
}
.AdminCustomerOrders-Orderlist {
  width: 100%;
}

.AdminCustomerOrders-InnerTable td {
  padding-left: 24px;
  padding-top: 16px;
}
.AdminCustomerOrders-InnerTable {
}
.AdminCustomerOrders-Tablehead td {
  font-size: 20px;
  padding-left: 24px;
  padding-bottom: 16px;
}
.AdminCustomerOrders-Tablehead td:first-of-type,
.AdminCustomerOrders-InnerTable td:first-of-type {
  padding-left: 0px;
}
.AdminCustomerOrders-Tablehead span {
  font-weight: 600;
}
.AdminCustomerOrders-Orders h3 {
  font-size: 32px;
  padding-bottom: 24px;
}
.AdminCustomerOrders-wrap {
  width: 100%;
  display: flex;
  padding-top: 64px;
  background-color: #f2f3f4;
  padding-bottom: 64px;
  justify-content: center;
}
