.Registerform-wrap{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 80px;
}
.Registerform-inner{
    max-width: 1096px;
    width: 100%;
}
.Registerform-inputs{
    display: grid;
    max-width: 648px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 48px;
    padding-top: 48px;
    row-gap: 24px;
}
.Registerform-inputs h4 span{
    color: #667079;
    margin-left: 8px;
    font-size: 14px;
}
.Registerform-inputs input{
    width: 100%;
    max-width: 260px;
    background-color: #f2f3f4;
    border: none;
    height: 48px;
    margin-top: 8px;
}
.Registerform-inputs select{
    width: 100%;
    max-width: 260px;
    margin-top: 8px;
    background-color: #f2f3f4;
    border: none;
    height: 48px;
}
.Registerform-inner button{
    width: 100%;
    background-color: #04468c;
    border-radius: 24px;
    color: white;
    padding: 16px 32px;
    border: none;
    max-width: 585px;
}
.Registerform-radiobuttons{
    margin-top: 48px;
    display: flex;

}
.Registerform-attributes{
    margin-top: 8px;

}
.Registerform-attributes-wrap{
    padding-top: 24px;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: 32px;
}

.Registerform-radiobuttons .custom-radio{
    display: flex;
    align-items: center;

}
.Registerform-inner-middle-headline h3{
    font-size: 48px;
    margin-top: -40px;
}
.Registerform-radiobuttons .custom-radio span{
    margin-left: 8px;
    font-weight: 600;
}
.Registerform-radiobuttons .custom-radio:last-child{
    margin-left: 48px;
}

.Registerform-SuccesButton{
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #0C9400;
}
.Registerform-SuccesButton-wrap{
    display: flex;
    width: 100%;
    justify-content: center;
}
.Registerform-step2-text-wrap{
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.Registerform-step2-text-wrap-inner{
    max-width: 648px ;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Registerform-step2-text-wrap-inner2{
    max-width: unset;
}
.Registerform-step2-text-wrap-inner2 p{
    max-width: 648px;
}
.Registerform-step2-text-wrap-inner a{
    display: flex;
width: 343px;
padding: 16px 32px;
justify-content: center;
align-items: flex-start;
gap: 8px;
color: #001020;
margin-top: 16px;
border-radius: 24px;
background: var(--Light-Grey, #F2F3F4)
}
.Registerform-step2-text-wrap-inner p{
    color: #667079;
    margin-top: 16px;
}
.Registerform-step2-text-wrap-inner h2{
    font-size: 48px;
    font-weight: 600;
    margin-top: 32px;
    color: #001020
}
.Registerform-step2-wrap{
    margin-top: 120px;
}
.Registerform-RadioButtonText{
    margin-right: 24px;
    margin-left: 0px !important;
    font-weight: 400 !important;
}
.Registerform-step2-text-wrap #FormFileBlock-Document-label{
    width: unset;
    margin-top: 32px;
}
#Registerform-de-send-data{
    margin-top: 24px;
    display: flex;
width: 237px;
padding: 16px 32px;
justify-content: center;
align-items: flex-start;
gap: 8px;
border: none;
color: #001020;
border-radius: 24px;
background: var(--Light-Grey, #F2F3F4)
}
.Registerform-attributes svg{
    margin-right: 8px;
}
.Registerform-inner .jobcenter-header-subheader, .Registerform-attributes-wrap{
    color: #667079 !important;
}
#senderror-text{
    margin-top: 8px ;
    color: maroon;
    font-size: 10px;
}