.ProfilePage-wrap {
  width: 100%;
  display: flex;
  padding-top: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 48px;
}
.ProfilePage-impersonate h6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
}
.ProfilePage-impersonate h6 span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ProfilePage-impersonate h6 span svg {
  padding-top: 1px;
}
.ProfilePage-impersonate {
  width: 100%;
  height: 24px;
  white-space: nowrap;
  background-color: maroon;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.ProfilePage-impersonate span {
  text-decoration: underline;
}
.ProfilePage-inner,
.ProfilePage-headline {
  width: 100%;
  max-width: 1096px;
  display: flex;
  flex-direction: column;
}
.ProfilePage-headline {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.ProfilePage-headline img {
  width: 100px;
}
.ProfilePage-headline {
  padding-bottom: 16px;
}
.ProfilePage-headline h3 {
  font-size: 48px;
  font-weight: 600;
}
.ProfilePage-headline p {
  font-size: 16px;
  margin-top: 8px;
  color: #667079;
}
.ProfilePage-Auth0-Data {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ProfilePage-Db-Data p,
.ProfilePage-Auth0-Data p {
  color: #667079;
}
.ProfilePage-Db-Data span,
.ProfilePage-Auth0-Data span {
  font-weight: 600;
  margin-right: 8px;
  padding-left: 16px;
  color: #001020 !important;
  text-decoration: none !important;
}
.ProfilePage-Db-Data h3,
.ProfilePage-Auth0-Data h3 {
  padding-left: 8px;
}
.ProfilePage-Db-Data {
  padding-top: 24px;
  margin-top: 24px;
  display: flex;
  background-color: #f2f3f4;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.ProfilePage-Token {
  font-size: 12px;
  max-width: 1096px;
  word-break: break-all;
}
.ProfilePage-Token span {
  font-weight: 600;
}

.ProfilePage-headline-logout button {
  margin-top: 16px;
  background-color: #f2f3f4;
  padding: 8px 16px;
  border-radius: 24px;
  color: #001020;
  border: 1px solid #b4b4b4;
  max-width: 75px;
}
.ProfilePage-headline-logout {
  display: flex;
  flex-direction: column;
}
.ProfilePage-Navigation {
  width: 100%;
  max-width: 1096px;
  display: flex;
  gap: 8px;
  padding-bottom: 32px;
}
.ProfilePage-Navigation button,
.ProfilePage-Navigation a {
  background-color: #f2f3f4;
  border-radius: 24px;
  padding: 8px 16px;
  color: #001020;
  border: 1px solid #b4b4b4;
}
.activeProfileBtn {
  background-color: #001020 !important;
  border-radius: 24px;
  padding: 8px 16px;
  color: white !important;
  border: 1px solid #b4b4b4;
}
