.Searchresultspage-wrap {
  width: 100%;
  display: flex;
  /*box-shadow: 0 4px 18px  rgba(0, 0, 0, 0.2);*/
  flex-direction: column;
  align-items: center;
}
.Searchresults-container {
  width: 100%;
  max-width: 1320px;
  background-color: white;
  display: flex;

  flex-direction: column;
  align-items: center;
}

#Searchpage-inner-id .Searchresults-searchbar-buttons {
  display: none;
}
#searchpage-label-input {
  position: relative;
  width: 100%;
}
.SearchpageInput {
  display: flex;
  justify-content: center;
}
.SearchPageHeadline h3 {
  font-size: 48px;
  color: #001020;
  font-weight: 600;
  line-height: 56px;
}
.Searchresultspage-page-wrap {
  align-items: center;
  flex-direction: column;
  background-color: #f2f3f4;
  padding-top: 80px;
  padding-bottom: 120px;
}
.Searchresultspage-page-wrap .SearchPageHeadline {
  text-align: center;
}
.Searchpage-filter-wrap {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 64px;
  flex-wrap: wrap;
  margin-bottom: 64px;
}
.Searchpage-filter-wrap button {
  background-color: #f2f3f4;
  color: #001020;
  padding: 16px 16px;
  cursor: pointer;
  border-radius: 24px;
  border: 0px;
}
.Searchpage-filter-wrap .activefilterbtn {
  background-color: #04468c;
  color: white;
}
#searchpage-label-input button {
  position: absolute;
  right: 32px;
  background-color: transparent;
  border: 0px;
  top: 16px;
  z-index: 1;
}
.Searchresultspage-page-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
#searchpage-searchbtn {
  padding: 16px 32px;
  background-color: #04468c;
  color: white;
  border-radius: 24px;
  border: 0px;
}
.SearchpageInput {
  margin-top: 32px;
}
#searchpage-label-input input {
  width: 360px;
  border: 0px;
  padding: 16px 24px;
  border-radius: 24px;
  margin-right: 8px;
}

.product-cardy {
  padding: 8px;
  background: white;

  position: relative;
}
.product-cardy svg {
  position: absolute;
  right: 16px;
  bottom: 8px;
}
.product-cardy span {
  font-size: 16px;
  color: #667079;
  margin-bottom: 32px;
}
.product-cardy h3 {
  font-size: 16px;
  margin-top: 8px;
  color: #001020;
  font-weight: 600;
  margin-bottom: 8px;
}
.Searchresults-searchbar-inner {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  height: 72px;
}
.Searchresults-searchbar-inner input {
  border: none;
  color: #001020;
}

.Searchresults-searchbar-inner input:focus {
  outline: none;
}
.Searchresults-searchbar-buttons button:first-child {
  background-color: #04468c;
  color: white;
  margin-right: 24px;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 20px;
  width: 95px;
  border: none;
}
.Searchresults-searchbar-buttons button:last-child {
  background-color: transparent;
  color: #001020;
  padding-left: 24px;
  border: none;
  margin-right: -40px;
  padding-top: 24px;
  cursor: pointer;
  font-size: 18px;
}
.Searchresults-searchbar-buttons {
  display: flex;
  padding-right: 8px;
  align-items: center;
  height: 72px;
  background-color: white;
}
.Searchresults-search {
  width: 100%;
  position: relative;
  display: flex;
  height: 72px;
}
#CloseSearchButton {
  position: absolute;
  right: 0px;
}
.Searchresults-search input {
  width: calc(100%);
  margin-right: 32px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}
.Searchresults-search input:focus {
  border: none;
}
.Searchresults-search input:active {
  border: none;
}
.Searchresults-Systems-Grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}
.Searchresults-searchbar-inner img {
  margin-left: 40px;
  margin-right: 24px;
}
.searchresults-link-systems {
  text-align: left;
  color: #001020;
}

.Searchresults-Products h2 span,
.Searchresults-Systems h2 span,
.Searchresults-Downloads h2 span,
.Searchresults-Sites h2 span {
  position: absolute;
  margin-top: 5px;
  font-size: 24px;
  margin-left: 4px;
  color: #cccfd2;
}
.searchresults-link-systems h4 {
  font-weight: 600;
  margin-top: 12px;
  color: #001020;
}
#searchbar {
  background-color: transparent;
}
.searchresults-link-systems p {
  color: #667079;
  margin-top: 8px;
}
.searchresults-link-systems img {
}
.Searchresults-results {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  padding-bottom: 120px;
}
.activepage {
  background-color: #04468c !important;
  color: white !important;
  font-weight: 600;
}
.paginationproducts {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
}
.paginationproducts button {
  border: none;
  margin: 8px;
  background-color: #f2f3f4;
  color: #001020;
  cursor: pointer;
  padding: 8px 12px;
  font-weight: 400;
  border-radius: 24px;
  max-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-cardy {
  display: flex;
  flex-direction: column;
  width: unset;
  align-items: left;

  width: 100%;
  border: 0px solid #001020;
}
.Searchresults-Products-Wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 16px;
}
#Searchpage-inner-id .Searchresults-Products {
  text-align: left;
}
.Searchresults-Systems,
#Searchpage-inner-id .Searchresults-Downloads {
  text-align: left;
  margin-top: 64px;
  margin-bottom: 64px;
}
.SearchResultSiteLinks {
  color: #001020;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  justify-content: space-between;
}
#Searchpage-inner-id .Searchresults-Sites {
  text-align: left;
  margin-top: 64px;
}
.asearchlink {
  margin-left: 110px;
  color: black;

  width: 350px !important;
  position: relative;
}
.Searchresults-Sites .SearchResultSiteLinks {
  border-top: 1px solid #f2f3f4;
  padding: 16px 0px 16px 0px;
}
.Searchresults-Sites .SearchResultSiteLinks:last-child {
  border-bottom: 1px solid #f2f3f4;
}
.Searchresults-Sites-Wrap {
  margin-top: 20px;
  margin-bottom: 20px;
}
.docs-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f2f3f4;
  padding-top: 16px;
  padding-bottom: 16px;
}
.docs-card span {
  font-weight: 600;
  font-size: 16px;
  color: #001020;
}
.docs-card svg {
  margin-right: 16px;
}
.step6select {
  /*min-height: 820px;*/
}
.product-cardy-link {
  text-decoration: none;
  color: black;
}
.product-cardy-link:hover {
  z-index: 333;
  opacity: 0.6;
  transition: 0.3s;
}
.read-pdf-box {
  display: flex;
  flex-direction: column;
}
.read-pdf-box a:last-child {
  margin-top: 4px;
}
.read-pdf-box a {
  padding: 5px 5px;
  gap: 8px;
  color: white;
  border: 0px;
  border-radius: 10px;
  font-size: 13px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  background: #04468c;
}
.Searchresults-Products {
  margin-bottom: 64px;
}
.Searchresults-Products h2,
.Searchresults-Systems h2,
.Searchresults-Sites h2,
.Searchresults-Downloads h2 {
  margin-bottom: 48px;
}
.Searchresults-results h2 {
  color: #001020;
  font-size: 48px;
  font-weight: 600;
}
#SearchResults-topsystems {
  margin-bottom: 32px;
}
.Searchresults-systemtopcat-name {
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
}
.active_pagebtn {
  font-weight: 600 !important;
  text-decoration: underline;
}
.points-divider-search {
  margin-top: 16px;
}
