@media (min-width: 1620px) {
  .header-image {
    background-position-y: 50% !important;
  }
  .Submenu-buttons-Shop {
    left: calc(50% - 548px);
  }
  .SearchDropdown-Systembtn-inner a {
    left: calc(50% - 548px);
  }
}

@media (min-width: 1024px) {
  .header-submenu-headline h2 {
    font-size: 14px !important;
    line-height: 19px;
    color: #667079 !important;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  #ProductCategoryGrid-filter-headline {
    display: none;
  }

  .header-selection-menu div {
    width: 100%;
  }
  .header-selection-menu div a svg {
    min-width: 32px;
    min-height: 32px;
  }
  .header-selection-menu div:nth-child(2) {
    margin-left: 16px !important;
    max-width: calc(100% - 32px);
    margin-right: 16px !important;
  }
  #responsivemenuitems {
    display: none !important;
  }
  #SearchDropdown-results-ok {
    max-height: 517px;
  }
  #Qrailingservice {
    padding-right: 24px;
    padding-left: 8px;
  }
  #QrailingInspiration {
    padding-left: 16px;
    padding-left: 8px;
    padding-right: 16px;
  }
  #Qrailingsconfigurator {
    padding-left: 8px;
    padding-right: 16px;
  }
  #Qrailingshop {
    padding-right: 16px;
  }
  #Qrailings {
    padding-right: 16px;
    padding-left: 8px;
  }
  .hiddenSliderBtn {
    display: none !important;
  }
  .HeaderMainpage-Submenuul li a {
    font-size: 32px !important;
    line-height: 32px;
  }
  .Qrailingssubmenu-innerShop ul:first-child li,
  .Qrailingssubmenu-innerService ul:first-child li {
    padding-left: 0px !important;
  }
  .Qrailingssubmenu-innerService li a {
    font-size: 24px !important;
    font-weight: 600;
    letter-spacing: -0.48px;
  }
}
@media (max-width: 1620px) {
  .header-texts {
    display: block;
  }
  .header-selection-menu {
    width: 45%;
  }
  #modelcanvas2 {
  }
}

@media (max-width: 1320px) {
  .configuratorsteps {
    justify-content: flex-start !important;
    margin-left: 24px;
  }
  .rotationbox {
    justify-content: flex-start !important;
  }
  #inspect-block-headline {
    transform: translate(-50%, 80px) !important;
  }
  .Searchresults-Products-Wrap {
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  #modelcanvas2 {
    margin-left: -320px;
  }
  .systemgrid-head {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
  .systemgrid-grid {
    padding-left: 16px;
    padding-right: 16px;
  }

  #searchmenu {
    margin-right: 16px;
  }
  .Searchresults-Systems-Grid {
    grid-template-columns: 1fr 1fr !important;
  }
  .Searchresults-Systems-Grid img {
    width: 100%;
    max-width: 320px;
  }
}

@media (max-width: 1224px) {
  #Qrailingsconfigurator .Qrailingssubmenu-inner,
  #Qrailings .Qrailingssubmenu-inner {
    grid-template-columns: 260px 1fr 500px !important;
  }
  .HeaderMainpage-Submenuul li a {
    font-size: 24px !important;
  }
}

@media (max-width: 1140px) {
  #footer-second-row-3address {
    grid-template-columns: 1fr 1fr !important;
    gap: 48px !important;
  }
  .SystemFinderEntry-cards-inner {
    grid-template-columns: 208px 208px !important;
  }
  .SystemFinderEntry-Card:last-of-type {
    grid-column: span 2;
    height: unset !important;
    background-color: #f2f3f4 !important;
  }
  .SystemFinderEntry-Card:last-child h3 {
    max-width: 100% !important;
    text-align: center !important;
    display: flex;
    background-color: #f2f3f4 !important;
    justify-content: center;
    margin-top: unset !important;
    text-decoration: none !important;
    align-items: center;
  }
  .SystemFinderEntry-Card:last-child h3 span {
    margin-bottom: 0px !important;
    margin-top: 48px;
    background-color: #04468c;
    padding: 16px 32px;
    text-decoration: none !important;
    color: white !important;
    border-radius: 32px !important;
    width: unset !important;
    max-width: 230px;
    font-weight: 400 !important;
    font-size: 13px !important;
  }

  .SystemFinderEntry-Card:last-child h3 span svg {
    display: none !important;
  }
  .SystemFinderEntry-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .SystemFinderEntry-Card:nth-child(3),
  .SystemFinderEntry-Card:nth-child(4) {
    margin-top: 4px;
  }
  .SystemFinderEntry-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
}

@media (max-width: 1024px) {
  .systemheader-text,
  .SystemFinderEntry-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Blackoverlay {
    height: calc(100% - 48px) !important;
  }
  #searchmenu {
    margin-right: 0px;
  }
  #brand-line {
    display: none;
  }

  .ConfiguratorPrivateForm-wrap {
    top: 53px !important;
  }

  #ProductCategoryGrid-rework {
    grid-template-columns: 1fr !important;
  }
  #ProductCategoryGrid-filter-headline {
    padding-bottom: 24px !important;
    border-top: 1px solid #f2f3f4;
    border-bottom: 1px solid #f2f3f4;
    padding-top: 24px !important;
    margin-bottom: 34px !important;
    margin-top: 24px !important;
  }
  #ProductCategoryGrid-filter {
    display: none;
  }
  #ProductCategoryGrid-filter-headline {
    display: flex;
    padding-bottom: 32px;
  }

  #CategoryGridPage-Headline-wrap .Service-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  #CategoryGridPage-Headline-wrap .Service-inner h2 {
    font-size: 40px !important;
  }
  #ProductCategoryGrid-rework .ProductCategoryGrid-GridFilter #ProductCategoryGrid-filter .activegridbtn {
    border-left: 0px !important;
  }
  #ProductCategoryGrid-rework .ProductCategoryGrid-GridFilter #ProductCategoryGrid-filter button {
    padding-left: 0px !important;
  }
  .MainpageCard #CategoryGridPage-Headline-wrap {
    padding-bottom: 48px !important;
    padding-top: 64px !important;
  }
  #ProductCategoryGrid-filter {
    padding-bottom: 24px !important;
  }
  .faq-wrapper {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .InspectProduct-headline-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .InspectProduct-headline-inner h2 {
    font-size: 32px !important;
  }
  .NeedHelp-Head {
    margin-right: 16px;
    margin-left: 16px;
  }
  .ThreeCardGrid-grid {
    grid-template-columns: 1fr 1fr !important;
  }

  #SearchDropdown-wrap2 {
    height: unset !important;
  }
  .Searchresults-search {
    height: 50px !important;
    margin-top: -2px;
  }
  .Searchresults-searchbar-buttons {
    height: 50px !important;
  }
  .Searchresults-searchbar-inner {
    height: unset !important;
  }
  .paginationproducts {
    margin-top: 24px;
    justify-content: left !important;
    flex-wrap: wrap;
  }
  .paginationsites {
    position: unset !important;
  }
  .Searchresults-Systems-Grid {
    grid-template-columns: 1fr !important;
  }
  #SearchDropdown-seeSearchpage {
    display: none !important;
  }
  .SearchDropdown-resp-seeallbutton {
    display: block !important;
  }
  .Searchresults-search {
    position: fixed !important;

    background-color: white;
  }
  #searchpage-label-input button {
    right: 24px !important;
  }

  .projectblock-col2 {
    grid-area: unset !important;
    height: 100%;
    width: 100%;
  }
  .header-top-wrap {
    height: 47px !important;
  }
  .header-banner {
    margin-bottom: 60px !important;
    margin-top: -72px !important;
  }
  .header-image {
    height: 652px !important;
    background-position: center;
  }

  .SiteScrollMenu-wrap {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    z-index: 3;
  }
  .projectblock-row1 {
    display: block !important;
  }
  .projectblock-row1 img,
  .projectblock-row2 img {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .projectblock-col1 {
    row-gap: 0px !important;
  }

  .projectblock-row2 {
    display: flex !important;
    flex-direction: column;
    gap: 0px !important;
    width: 100%;
    margin-top: 0px !important;
  }
  .SystemAccessoriesGrid-wrap .project-img {
    max-height: unset !important;
  }
  #ThreeCardGridFour {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 24px;
  }

  .SystemAccessoriesGrid-wrap .projectblock-col1 img {
    margin-bottom: 24px;
  }
  .SystemAccessoriesGrid-wrap .project-img {
    margin-bottom: 24px;
  }
  .SystemAccessoriesGrid-wrap .projectblock-row2 {
    margin-top: 24px;
  }
  .SystemAccessoriesGrid-wrap .proj-links-inner p {
    display: none !important;
  }

  .projectblock-page {
    margin-top: 0px !important;
    align-items: center;
  }
  .HeaderMainpage-Submenu-Cards2 {
    width: calc(100% - 16px);
  }
  .baseclampsglassdistribution .inoutchoice {
    justify-content: flex-start !important;
    gap: 24px;
  }
  .HeaderMainpage-Submenu div h4 {
    margin-bottom: 16px;
  }
  .Header-top div ul {
    height: unset !important;
  }
  .HeaderMainpage-Submenu li:first-child {
    margin-top: 16px !important;
  }
  #modelcanvas2 {
    margin-left: 0px;
  }
  .Header-menu2 {
    display: none !important;
  }
  .configurator-wrap {
    padding-right: 30px;
  }
  .configuratorsteps {
    justify-content: center !important;
    margin-left: 24px;
  }
  #modelcanvas2 {
    margin-left: 0px;
  }
  .header-text-left div {
    margin-left: 16px;
  }
  #resp-menu {
    display: flex !important;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  #responsivemenuitems {
    margin-top: 47px !important;
  }
  #resp-menu li:first-child {
    margin-left: 15px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #resp-menu li:last-child {
    margin-right: 16px;
  }
  .NewsOverview-header h2 {
    font-size: 32px !important;
  }
  .HeaderSlim-wrapper {
    height: unset !important;
  }
  .Header-wrapper .sticky {
    height: unset !important;
  }
  .Searchresults-search {
    display: flex;
    justify-content: space-between;
  }
  .product-cardy-link {
    height: unset !important;
    width: unset !important;
    margin-left: 0px !important;
  }
  .Searchresults-results h2 {
    text-align: left;
  }
  .VideoList-Headlineblock-wrap {
    padding-top: 64px !important;
    padding-bottom: 32px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .VideoList-wrap {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 64px !important;
  }
  .SearchDropdown-wrap .product-cardy-link img {
    height: 151px !important;
    width: 151px !important;
  }
  .SearchDropdown-ProductCategories {
    width: 100%;
  }
  .product-cardy-link #product-card-link-icon {
    width: 13px !important;
    height: 13px !important;
  }

  #searchLogo {
    width: unset !important;
    margin-left: 16px;
  }
  .Prefix-grid-texts {
    padding-left: 16px;
    padding-right: 16px;
    grid-template-columns: 1fr 1fr !important;
  }
  #closesearchbar-btn {
    padding: 0px !important;
    padding-top: 8px !important;
    width: unset;
    margin-right: 0px !important;
  }
  .Searchresults-searchbar-inner img {
    display: none;
  }
  .suggestmainpage {
    margin-top: -100px;
    top: 25px;
    padding-top: 2px;
    margin-left: 0px !important;
    left: 0px !important;
  }

  #searchmenu {
    justify-content: flex-start !important;
    width: 100% !important;
  }
  .Header-menu1 {
    width: 100%;
  }
  .asearchlink {
    height: unset !important;
    margin-top: 32px !important;
    font-size: 14px !important;
    justify-content: flex-start !important;
  }
  .asearchlink span {
    margin-left: -12px !important;
  }
  .asearchlink span img {
    width: 20px !important;
    height: 20px !important;
    margin-top: 5px;
  }
  .Systems-slider-top-header h2 {
    font-size: 32px !important;
  }
  .UsedComponents-inner {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .UsedComponents-Cards {
    grid-template-columns: 1fr !important;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .Used-Components-Card img {
    max-width: 320px;
    max-height: 320px;
  }
  #searchtext {
    padding: 5px;
  }
  #searchmenu img {
    margin-left: 16px !important;
  }
  #mainmenu {
    display: none;
  }
  .HeaderMainpage-Submenu li {
    margin-bottom: 8px !important;
  }
  .Header-top {
    display: flex !important;
    grid-template-columns: 1px calc(50% - 50px) calc(50% - 50px) 50px;
  }
  .seperator {
    display: none !important;
  }
  .Header-menu1 ul li:nth-child(1) {
    padding-left: 5px;
  }
  .Header-menu1 ul li:nth-child(2) {
    padding-left: 5px;
  }

  .Block404-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Servicesearch-inner {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .stickyScroll {
    top: 47px !important;
  }
  .HeaderMainpage-Submenuul-headline {
    padding-left: 0px !important;
    color: #667079;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
  }
  .SiteScrollMenu-wrap {
    margin-top: -93px;
  }
  .SystemFinder-wrap,
  .SystemFinder-wrap-head {
    padding-left: 16px;
    padding-right: 16px;
  }

  .SystemFinder-wrap {
    padding-bottom: 64px !important;
  }

  .SystemFinder-wrap-head {
    padding-top: 64px !important;
  }

  .servicesearchcard {
    padding-left: 16px;
    padding-right: 16px;
  }
  .SystemDetailBlock-wrap {
    margin-top: 0px !important;
    margin-bottom: 64px !important;
  }
  .configuratorsteps {
    top: 5px !important;
    transform: translate(-55%) !important;
  }
  .Selects-configu {
    width: 100%;
    max-width: unset !important;
  }
  .header-text-left div p {
    margin-top: 40px;
  }
  #SelectLangMenuSmall {
    position: absolute;
    left: 75% !important;
  }

  .footer-mobile-row-buttons-inner {
    position: relative;
  }
  #TextBlockSystems-Seo {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Prefix-BannerText h2 {
    font-size: 32px !important;
  }
  .SelectLangMenuSmall-Square {
    left: calc(50% - 8px) !important;
  }
  .header-texts {
    margin-top: 0px;
  }
  .header-texts {
    grid-template-columns: 1fr !important;
  }
  .header-text-left h2,
  .header-text-left h1 {
    font-size: 48px !important;
    line-height: 56px;
  }
  .header-selection-menu div a svg {
    margin-right: 24px;
  }
  .SearchDropdown-right {
    margin-top: 120px;
  }
  .Searchresults-Downloads {
    margin-top: 64px;
  }
  .FindInstallerEntry-card {
    background-color: rgba(255, 255, 255, 0.85) !important;
    margin-left: 16px;
    margin-right: 16px;
  }
  .header-selection-menu {
    height: 192px !important;

    margin-top: -130px !important;
  }
  .FrenchBalconySystemCard button {
    max-height: unset !important;
  }
  .SearchDropdown-wrap {
    flex-direction: column;
  }
  .header-selection-menu {
    display: grid !important;
    grid-template-columns: 1fr !important;
    width: calc(100% - 16px) !important;
    float: left !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .header-selection-menu div a {
    justify-content: space-between;

    width: 100%;
    font-size: 16px !important;
  }

  .header-selection-menu p {
    margin-top: 16px !important;
    padding-left: 16px;
  }
  .header-selection-menu div div {
    width: 100%;
  }
  .seotopicblock-inner-text h2 {
    font-size: 40px !important;
  }
  .seotopicblock-first-text {
    font-size: 20px !important;
  }
  .Searchresults-Products-Wrap {
    flex-wrap: wrap;
    position: relative;
  }
  .Searchresults-results {
    grid-template-columns: 1fr !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .SearchDropdown-wrap {
    overflow-y: scroll;
  }
  .SearchDropdown-Systembtn-wrap {
    display: none !important;
  }
  .systemgrid-grid {
    grid-template-columns: 1fr 1fr !important;
  }
  #header-video {
    height: 745px !important;
  }

  .Q-disc-animation-block-wrap2 {
  }
  .Q-disc-animation-inner3 {
    flex-direction: column-reverse !important;
  }
  .systemgrid-head-text p {
    max-width: 540px;
  }
  .Searchresults-Products {
    max-width: 100%;
  }

  .TwoColBlock-wrap {
    padding-top: 64px !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 64px !important;
  }
  .SearchDropdown-right .searchresults-link-systems {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .SearchDropdown-right .searchresults-link-systems img {
    width: 90px !important;
    height: 90px !important;
  }
  .TwoColBlock-wrap-first {
    padding-top: 128px !important;
  }
  .SearchDropdown-right .Searchresults-Downloads a .docs-card {
    width: 100% !important;
  }
  .SearchDropdown-wrap {
    padding-top: 48px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    position: fixed;
    background-color: white;
    height: 100vh;
    top: 48px;
  }
  .Q-disc-animation-text {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Header-SystemDetail-wrap {
    border-bottom: 0px !important;
  }

  .Q-disc-animation-text h2 {
    font-size: 32px !important;
  }
  .CatalogGrid-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .SearchDropdown-wrap {
    gap: 0px !important;
  }
  .SearchDropdown-right {
    margin-top: 48px;
  }
  .Searchresults-searchbar-buttons {
    padding-right: 16px !important;
  }
  .HeaderMainpage-text-left div {
    margin-top: 148px !important;
  }

  .header-text-projects div {
    margin-top: 196px !important;
  }

  .header-banner {
    height: 590px !important;
  }

  .stickybannernobar {
    margin-top: -23px !important;
  }
  .stickybanner {
    margin-top: -23px !important;
  }
  .SystemHeadline-text {
    padding-left: 16px;
    padding-right: 16px;
  }
  .lang-dev-wrap {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .Contenttext-inner h2 {
    font-size: 32px !important;
    word-break: break-all;
  }
  .Contenttext-wrap-head {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .SearchDropdown-wrap {
    background-color: #f2f3f4;
  }
  #inspect-block-headline {
    transform: translate(-50%, 20px) !important;
    font-size: 32px !important;
  }
  .Configure-block-cards {
    padding-left: 16px;
    padding-right: 16px;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
  .Configure-block-card {
    padding-left: 24px;
    padding-right: 24px;
  }
  /*.SearchDropdown-ProductCategories {
    grid-template-columns: 1fr 1fr !important;
  }
  .SearchDropdown-Noresults-Headline {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .SearchDropdown-Noresults {
    height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
  }

  .HeaderDropdown-Componentgrid-lastitem {
    grid-column: 1 / -1;
  }*/
}

@media (max-width: 840px) {
  .FormFileBlock-Inner h3 {
    width: 100% !important;
  }

  .service-block-card {
    display: flex !important;
    max-height: unset !important;
    flex-direction: column !important;
  }
  .HeaderDropdown-CategoryGridCardWrap img {
    display: none;
  }
  .SearchDropdown-wrap .product-cardy-link img {
    display: none;
  }
  .SearchDropdown-ProductCategories {
    grid-template-columns: 1fr !important;
    gap: 24px !important;
    min-width: unset !important;
  }
  .HeaderDropdown-ShowMoreSystems {
    padding-left: 0px !important;
    text-decoration: underline !important;
    font-size: 12px !important;
  }
  .Searchresults-Products-Wraps {
    padding-top: 8px;
  }
  .HeaderDropdown-CategoryGridCardWrap h3 {
    font-size: 14px !important;
    font-weight: 400 !important;
    position: relative !important;
    margin-left: -16px !important;
  }
  .Searchresults-Products-Wraps .SearchDropdown-Products-card-inner h3 {
    font-weight: 400 !important;
  }
  .SearchDropdown-right h4 {
    font-weight: 400 !important;
  }
  .HeaderDropdown-Componentgrid-lastitem:first-child {
    margin-bottom: 24px !important;
  }
  .HeaderDropdown-Componentgrid-lastitem {
    min-width: unset !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    display: block !important;
    background-color: unset !important;
    text-align: left !important;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .SystemFinder-Breadcrumb-wrap {
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  .ResponsiveNavigation {
    display: flex !important;
  }
  .SearchDropdown-right {
    margin-top: 0px;
  }
  .Searchresults-ProductCategories {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
  }
  .SearchDropdown-right .SearchDropdown-Systems-card-New h4 {
    padding-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
  .SearchDropdown-Products-card-inner {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .SearchDropdown-Systems-card-inner {
    margin-left: 0px !important;
  }
  .SearchDropdown-wrap h2 {
    font-size: 20px !important;
    padding-top: 16px;
    margin-top: 8px;
    border-top: #cccfd2 1px solid;
  }
  .SearchDropdown-wrap h2:first-child {
    font-size: 20px !important;
    padding-top: 16px;
    margin-top: 8px;
    border-top: #cccfd2 0px solid;
  }
  .SearchDropdown-right h2 {
    border-top: #cccfd2 1px solid !important;
  }
  .SearchDropdown-right h2 {
    margin-left: 0px !important;
  }
  .Searchresults-Products-Wraps {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .service-block-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .SearchDropdown-left .product-cardy {
    flex-direction: row !important;
  }
  .HeaderDropdown-Componentgrid-lastitem a {
    display: inline-block !important;
  }

  .SearchDropdown-left .product-cardy .SearchDropdown-Products-card-inner {
    flex-direction: row !important;
    max-width: 100% !important;
  }
  .HeaderDropdown-Componentgrid-lastitem h3 {
    display: block !important;
    max-width: 100% !important;
  }
  .service-block-card-2 {
    margin-top: 0px !important;
  }
  .service-block-text div h3 {
    margin-top: 52px;
    margin-bottom: 24px;
  }
  .service-block-card-2 div:first-child {
    order: 2;
  }
  .service-block-text button {
    margin-top: 32px;
    margin-bottom: 52px;
  }
  .TwoColBlock-inner {
    grid-template-columns: 1fr !important;
    gap: 48px !important;
  }
  .ImageServiceBlock {
    display: flex;
    background-color: white;
    justify-content: center;
  }
  .proj-links-inner {
    display: block !important;
  }
  #proj-links {
    margin-top: 32px !important;
  }
  .proj-links-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .SystemFinderEntry-Headline h2 {
    font-size: 32px !important;
  }

  /*Config privat form*/

  .ConfiguratorPrivatForm-inner {
    flex-direction: column;
  }
  .ConfiguratorPrivateForm-right {
    width: 100% !important;
    padding-bottom: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 48px !important;
  }
  .ConfiguratorPrivateForm-left img {
    width: 100% !important;
  }
  .ConfiguratorPrivateForm-right-inner {
    padding-right: 0px !important;
  }
  .ConfiguratorPrivateForm-left {
    width: 100% !important;
    justify-content: left !important;
    padding-bottom: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .ConfiguratorPrivateForm-left-inner .overview-table {
    padding-left: 0px !important;
  }
}

@media (max-width: 768px) {
  .footer-third-row-buttons button:last-child svg {
    margin-right: 0px !important;
  }
  .Configurator-step11-confilink #configlink {
    max-width: 270px;
    margin-left: 34px;
  }
  .Configurator-step11-confilink #configlink svg {
    min-width: 16px;
  }
  .systemgrid-flowchart-wrap .cards {
    grid-template-columns: 1fr 1fr !important;
  }
  .SystemFinderOptionImage {
    width: 100% !important;
    height: 100% !important;
  }
  #Block404-Banner-Text-wrap {
    padding-left: 16px;
  }
  .TrainingsDetail-text {
    padding: 0px 16px;
    display: block !important;
  }
  .TrainingsDetail-text div:first-child h3:first-child {
    margin-bottom: 32px !important;
  }
  .FormFileBlock-Textinputs {
    justify-content: center !important;
  }
  .Configure-block-wrap {
    padding-bottom: 64px !important;
  }
  .ResponsiveBufferAdjustmentGrey {
    height: 54px !important;
    background-color: #f2f3f4;
  }
  .ResponsiveBufferAdjustmentWhite {
    height: 54px !important;
    background-color: #fff;
  }
  .Q-disc-discount-inner {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
  #Q-disc-discount-sticker {
    position: unset !important;
  }
  .SystemDetailBlock-BildLinks img {
    order: 2;
    margin-top: 24px;
  }
  .SystemDetailBlock-BildRechts {
    margin-top: 64px !important;
  }
  .SystemDetailBlock-BildLinks {
    margin-top: 64px !important;
  }
  .SystemDetailBlock-BildRechts .SystemDetailBlock-Card-wrap {
    padding-left: 16px !important;
  }
  .SystemDetailBlock-BildRechts img {
    order: 2;
    margin-top: 24px;
  }
  .projectdetail-header h2 {
    font-size: 40px !important;
  }
  .product-card2 {
    margin-left: 16px;
  }
  .Recentprojects-wrap,
  .servicecards2-wrap,
  .SwiperSlider-wrap,
  .SwiperSlider2-wrap {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .SystemDownloads-downloads .Dll-categorie-choice:first-child {
    padding-top: 32px !important;
  }
  .LightBoxProjects-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  #LightBoxProjects-close-btn {
    right: 16px !important;
    top: -48px !important;
  }
  .VideoOverview-section,
  .VideoOverview-Headline {
    grid-template-columns: 1fr !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .TextBlockSystems-wrap {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .SystemDownloads-headline {
    margin-bottom: 32px !important;
  }
  .Systems-slider-top-text div:last-child {
    flex-direction: row !important;
    margin-top: 24px;
  }
  .overviewsystems {
    display: block !important;
    text-align: center !important;
    margin-top: 64px !important;
  }
  .NeedHelp-cards {
    grid-template-columns: 1fr !important;
    margin-left: 16px;
  }
  #specifics h2 {
    word-break: break-all;
  }
  #proj-links-inner-spezfix p {
    width: 100%;
    height: 100%;
  }

  #specifics {
    padding-left: 16px;
    padding-right: 16px;
  }
  .specificsrow {
    display: block !important;
  }
  .specificsrow p:last-child {
    margin-top: 8px;
  }
  #footer-company-toggle-menu li,
  #footer-information-toggle-menu li,
  #footer-support-toggle-menu li {
    margin-top: 24px !important;
  }
  .systemheader-text h2,
  .systemheader-text h1 {
    font-size: 48px !important;
    line-height: 56px !important;
    font-weight: 200 !important;
  }
  .Contenttext-inner-inner {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .Contenttext-inner h2 {
    margin-left: 16px;
  }
  #api-data {
    padding-left: 16px;
    padding-right: 16px;
  }
  .systemheader-text h2 h1 {
    font-size: 64px !important ;
  }
  .Prefix-grid-headline-inner {
    flex-direction: column;
    gap: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .howwework-cards {
    grid-template-columns: 1fr !important;
  }
  .Seotopics-texts h2 {
    margin-bottom: 24px;
    font-size: 32px !important;
  }
  .systemgrid-grid {
    grid-template-columns: 1fr 1fr !important;
  }
  #NeedHelp-StartChat-resp {
    display: block !important;
  }
  #NeedHelp-StartChat {
    display: none !important;
  }
  .jobcenter-jobcard {
    padding-left: 16px;
    padding-right: 16px;
  }
  #Block404-Banner-Text-wrap h2 {
    font-size: 48px !important;
    font-weight: 600;
  }

  #Block404-Banner-Text-wrap p {
    margin-right: 16px;
  }
  .lang-dev-wrap {
    grid-template-columns: 1fr 1fr !important;
  }

  .Service-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  #jobcenter-list-headline {
    padding-left: 16px;
    padding-right: 16px;
    text-align: left !important;
    font-size: 32px !important;
  }
  .jobcenterdetail-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .UsedComponents-Headline h2 {
    font-size: 48px !important;
  }
  .Seotopics-texts {
    padding-left: 16px;
    padding-right: 16px;
    grid-template-columns: 1fr !important;
    margin-bottom: 80px !important;
  }
  .jobcenter-header {
    margin-bottom: 64px !important;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }

  .jobcenter-header h1 {
    font-size: 48px !important;
  }
  .jobcenter-header-subheader {
    display: block !important;
  }
  .systemgrid-inner {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .jobcenter-header-subheader div {
    margin-top: 16px;
  }
  .systemgrid-inner {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }

  .SystemHeadline-text h2 {
    font-size: 48px !important;
    line-height: 56px !important;
  }
  .seotopicblock-inner-text {
    grid-template-columns: 1fr !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .seotopicblock-inner-text h2 {
    margin-bottom: 24px;
  }
  .Seotopic-inner {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .projectblock-head {
    flex-direction: column;
    gap: 16px;
    align-items: unset !important;
    margin-left: 32px !important;
  }
  .projectblock-filter {
    padding: 16px;
    margin-bottom: 24px;
  }
  .Catalogue-blöcke {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
  .Catalogue-wrap {
    padding-top: 64px !important;
  }
  .Cataloge-view-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .Cataloge-view-buttons a:first-child {
    max-width: 325px;
  }
  .Cataloge-view-buttons a:last-child {
    margin-left: 0px !important;
    max-width: 325px;
  }
  .Catalogue-Benefits-wrap div {
    margin-left: 0 !important;
    padding: 8px;
  }
  .Catalogue-image img {
    max-width: 100%;
    height: 100%;
  }
  .Catalogue-image {
    margin-top: 64px;
  }
  .systemgrid-head .projectblock-filter {
    padding: 0px;
  }
  .Registerform-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Registerform-inputs input,
  .Registerform-inputs select {
    max-width: unset !important;
  }
  .ThreeCardGrid-grid {
    gap: 32px !important;
    grid-template-columns: 1fr !important;
  }

  .ThreeCardGrid-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .Registerform-inner-middle-headline h3 {
    margin-top: 0px !important;
    font-size: 32px !important;
  }
  .Registerform-inner .jobcenter-header {
    padding: 0px !important;
  }
  .Registerform-inputs {
    grid-template-columns: 1fr !important;
  }
  .header-text-left div h2,
  .header-text-left div h1 {
    font-size: 64px;
  }
  .Recentprojects-projects {
    grid-template-columns: 1fr !important;
    display: block !important;
  }
  .Recentprojects-project-card img {
    height: 100%;
  }
  .Recentprojects-project-card {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 32px;
  }
  .faq-question-warp div:first-child p {
    font-size: 18px !important;
    max-width: 75% !important;
  }
  .faq-question-warp div:last-child p {
    max-width: 100% !important;
  }

  .ServiceFaqButtons {
    flex-wrap: wrap;
    margin-bottom: 32px !important;
  }
  .Service-inner h2 {
    font-size: 48px !important;
  }
  .SystemAccessoriesGrid-Headline {
    padding-left: 16px;
    padding-right: 16px;
  }

  .TrainingsOverview-Card {
    display: flex !important;
  }
  .Service-menu-inner {
    padding-left: 16px;
    padding-right: 16px;
    display: block !important;
  }
  .Service-menu-inner div:last-child {
    margin-top: 24px;
    padding-top: 24px;
    border-left: 0px !important;
    border-top: 1px solid #f2f3f4;
    padding-left: 0px !important;
  }
  .faq-question-warp div:first-child button {
    width: 32px !important;
    height: 32px !important;

    margin-right: 16px !important;
  }
  .Recentprojects-head h2 {
    font-size: 40px !important;
    line-height: 48px !important;
  }
  .Recentprojects-head {
    padding-left: 16px;
    padding-right: 16px;
  }
  .NewsletterNew-left form button {
    margin-left: 0px !important;
  }
  .suggestoutput {
    position: fixed !important;
    margin-top: 22px;
  }
  .configuratorsteps {
    width: 600px !important;
  }

  .Configure-block-head h2 {
    padding-left: 24px !important;
    padding-right: 24px !important	;
    font-size: 40px !important;
  }

  .service-block-header h2 {
    text-align: center;
  }
  .howwework2-cards {
    grid-template-columns: 1fr !important;
  }
  .NeedHelp-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .howwework2-cards .howwework2-card:nth-child(1) {
    padding-right: 0px !important;
    border-right: none !important;
    padding: 16px !important;
    padding-bottom: 32px !important;
    border-bottom: 1px solid #f2f3f4;
    margin-bottom: 32px !important;
  }
  .howwework2-cards .howwework2-card:nth-child(2) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .howwework2-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  #howwework2-button {
    display: none !important;
  }
  #howwework2-button-resp {
    display: none !important;
  }
  .howwework2-header div {
    display: block !important;
  }
  .howwework2-cards .howwework2-card:nth-child(3) {
    padding-left: 0px !important;
    border-left: none !important;
    padding: 16px !important;
    border-top: 1px solid #f2f3f4;
    padding-top: 32px !important;
    margin-top: 32px !important;
  }
  .howwework2-header div p {
    flex-basis: 80%;
  }
  .howwework2-header {
    padding-left: 16px;
    padding-right: 16px;
  }
  .jobcenterdetail-detail {
    padding-left: 16px;
    padding-right: 16px;
  }
  .servicecards-cards,
  .servicecards2-cards {
    grid-template-columns: 1fr 1fr !important;
  }
  .servicecards2-header p {
    max-width: 100% !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-align: center;
  }
  .servicecards2-header h2 {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }
  .servicecards-inner {
    margin-bottom: 64px;
  }
  .servicecards-wrap {
    height: unset !important;
  }
  .thecompareitems div {
    width: 100% !important;
  }

  .Systems-slider-top-text div:last-child {
    flex-direction: column;

    justify-content: flex-start !important;
  }
  .Systems-slider-top-text div:last-child button:first-child {
    margin-bottom: 8px;
  }
  .jobcenterdetail-text {
    display: block !important;
    margin-top: 64px !important;
  }
  .jobcenterdetail-text h2 {
    margin-bottom: 24px;
  }

  /*SearchPage responsive*/
  #searchpage-label-input input {
    width: 100% !important;
  }
  #searchpage-searchbtn {
    max-width: 180px;
    width: 100%;
  }
  .SearchpageInput {
    flex-direction: column !important;
    gap: 16px;
    align-items: center;
  }
  .Searchresultspage-page-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .paginationsites {
    position: unset !important;
  }

  /*ServiceSearch responsive*/

  .Servicesearch-inner input {
    width: 100% !important;
    max-width: unset !important;
  }
  .Servicesearch-inner h2 {
    text-align: center !important;
  }
  .ServiceSearch-x-button-label {
    width: 100% !important;
  }

  .footer-first-row,
  .footer-second-row,
  .footer-third-row,
  .footer-last-row {
    display: none !important;
  }
  .footer-mobile-design {
    display: block !important;
  }

  .NewsDetailCustomText {
    margin-top: 64px;
    margin-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .footer-third-row-icons {
    margin-top: 24px;
    margin-bottom: 24px;
    justify-content: center;
  }
  .service-block-text div {
    width: 100% !important	;
    padding-left: 48px;
    padding-right: 48px;
  }

  .service-block-card img {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
    height: unset;
  }
  .footer-mobile-design {
    display: block !important;
  }
  .projectblock-outer-wrap {
    margin-top: 64px !important;
    padding-top: 128px !important;
    padding-bottom: 64px !important;
  }
  .Recentprojects-head-link {
    padding-left: 16px;
    max-width: 220px;
    justify-content: flex-start !important;
  }
  .Systems-slider-top-inner {
    padding: 0px 16px !important;
  }
  .SystemDetailBlock-BildLinks,
  .SystemDetailBlock-BildRechts {
    flex-direction: column !important;
  }
  .SystemDetailBlock-BildRechts div {
    order: 2;
  }
  .SystemDetailBlock-Bannerbild {
    display: none;
  }
  .SystemDetailBlock-Card-wrap h3 {
    margin-top: 24px;
  }
  .FormFileBlock-Textinputs-inner {
    display: block !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .jobcenterdetail {
    flex-wrap: wrap;
  }
  .FormFileBlock-Headline-Inner,
  .FormFileBlock-Fileupload-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .FormFileBlock-Textinputs-inner div:last-child {
    margin-left: 0px !important;
  }
  .TrainingsDetail-text {
    margin-top: 64px !important;
  }
  .jobcenterdetail h2 {
    text-align: left;
  }
  .configuratorsteps-btnrow {
    position: absolute;
    left: 16px;
    width: 640px !important;
  }
  .configuratorsteps button {
    font-size: 12px;
  }
  .configuratorsteps {
    margin-left: 0px !important;
  }
  .Systems-slider-top-wrap a {
    display: none !important;
  }
  .ResponsiveButtonSystems {
    display: flex !important;
  }
  .draganddropslider-wrap-systems {
    margin-left: 16px;
  }
  .Systems-slider-top-text {
    grid-template-columns: 1fr !important;
  }
  .overlay {
    top: 32% !important;
  }
  .About-card {
    grid-template-columns: 1fr !important;
  }

  .About-card div:first-child {
    margin-bottom: 16px !important;
  }
  .contactmenu {
    padding-bottom: 16px !important;
  }
  .AboutBlock-cards,
  .AboutBlock-wrap,
  .AboutBlock-Head-Wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .AboutBlock-Head-Wrap {
    padding-bottom: 80px !important;
  }
  .AboutBlock-wrap {
    margin-bottom: 24px !important;
  }
  .AboutBlock-scroll-buttons div ul {
    flex-wrap: wrap !important;
  }
  .AboutBlock-scroll-buttons {
    padding-left: 16px;
    padding-right: 16px;
  }
  .AboutBlock-cards h3 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .AboutBlock-careerbutton {
    display: none !important;
  }
  .responsiveAboutBtn {
    display: block !important;
    width: 120px;
    text-align: center;
    margin-top: 24px;
  }
  .NewsOverview-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .AboutBlock-cards {
    margin-bottom: 64px !important;
  }
  .AboutBlock-cards,
  .AboutBlock-Head {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .AboutBlock-Head h2 {
    font-size: 48px !important;
  }
  .SystemDownloads-downloads h3 {
    font-size: 24px !important;
    word-break: break-all;
  }
  .SystemDownloads-downloads button {
    min-width: 48px;
  }
  .AboutBlock-cards {
    margin-top: 0px !important;
  }
  .About-card {
    margin-bottom: 64px !important;
  }
  .ContactpageLineHq {
    display: block !important;
  }
  .Contact-inner div:nth-child(3) {
    margin-top: 0px !important;
  }
  .visibleQR {
    display: block !important;
    margin-top: -0px !important;
  }
  .Contact-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Contact-Header {
    display: block;
  }
  .Contact-Buttons {
    flex-wrap: wrap;
    margin-top: 32px;
  }
  .SystemDownloads-grid {
    display: block !important;
  }
  .SystemDownloads-wrap {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .SiteScrollMenu-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .systemheader-text h2 h3,
  .systemheader-text h1 h3 {
    font-size: 48px !important;
  }
  .systemheader-text {
    margin-top: 185px !important;
  }
  #HeaderSystemDetail-buynowbutton {
    display: flex !important;

    font-size: 13px;
  }
  #HeaderSystemDetail-Configure {
    display: flex !important;

    font-size: 13px;
  }
  .SiteScrollMenu-inner div:last-child {
    display: none !important;
  }
  .videos-card div div {
    margin-top: 16px;
  }
  .videos-card span {
    font-size: 32px !important;
  }
  #Language-Slide-Menu {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 48px;
  }
  .SystemAccessoriesGrid-Headline {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .Searchresults-Products-Wrap {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
  .SystemAccessoriesGrid-wrap {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .projectdetail-header {
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 48px !important;
    margin-top: 64px !important;
  }
  .SearchDropdown-wrap .Searchresults-Products-Wrap {
    grid-template-columns: 1fr !important;
  }
  .SearchDropdown-wrap {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .Used-Components-Card {
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 0px !important;
  }
  .UsedComponents-Cards {
    gap: 64px !important;
  }
  .Used-Components-Card div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .UsedComponents-Block {
    padding-left: 16px;
    padding-right: 16px;
  }
  .SearchDropdown-wrap #responsivemenuitems li a:first-child {
    margin-left: 0px !important;
  }
  .projectdetail-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Prefix-customtext-wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .FormFileBlock-semiwrap {
    flex-direction: column;
  }
  .DownloadsInSystems-inner {
    padding: 0px 16px;
  }
  .CatalogItem-Links {
    display: flex;
  }
  .SystemSearchLinks {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .Systemfinder-Links-inner {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .Systemfinder-Links-inner a:first-child {
    padding: 8px 16px !important;
  }
  #RegisterLinkResponsive {
    padding-left: 16px !important;
  }
  #SearchDropdown-inputbuffer {
    width: 2px !important;
  }

  #RegisterLinkResponsive a {
    margin-left: 0px !important;
    width: 100% !important;
  }
  #RegisterLinkResponsive a svg {
    margin-right: 14px !important;
  }
  .Contact-Header {
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .Lightbox-buttons-wrap {
    width: calc(100% - 32px) !important;
  }
  .NewsletterNew-wrap {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  #Q-disc-img-animation-text-visible {
    flex-direction: column !important;
  }
  .Recentprojects-head-wrap {
    grid-template-columns: 1fr !important;
  }
  .systemgrid-grid {
    grid-template-columns: 1fr !important;
  }
  .ConfiguratorPrivateConnect-InstallerLi {
    height: unset !important;
  }
  .ConfiguratorPrivateConnect-InstallerLi p span:last-of-type {
    white-space: nowrap;
  }
  .ConfiguratorPrivatForm-LastStep {
    padding-left: 16px;
    padding-right: 16px;
  }
  .ConfiguratorPrivatForm-LastStep .Configurator-step11-final-text-error {
    font-size: 24px !important;
  }
  .ConfiguratorPrivatForm-LastStep h5 {
    font-size: 20px !important;
  }
}
@media (max-width: 640px) {
  .Prefix-wrap,
  .Prefix-header,
  .Roadshow-wrap,
  .Roadshow-header {
    padding-left: 16px;
    padding-right: 16px;
  }

  .systemfinder-grid .systemgrid-card img {
    max-width: unset !important;
    max-height: unset !important;
  }

  .Prefix-wrap .Prefix-customtext p img {
    width: 100%;
    height: 100%;
  }
  .header-text-left div h2,
  .header-text-left div h1 {
    font-size: 40px;
  }
  .specificsrow div:first-child p {
    margin-bottom: 8px;
  }
  .videos-inner,
  .videos-headline {
    padding-left: 16px;
    padding-right: 16px;
  }

  .systemgrid-card {
    grid-template-columns: 1fr !important;
  }
  .Systemfinder-Links-inner {
    margin-top: 24px;
    flex-direction: row !important;
  }
  .systemgrid-card img {
    width: 100%;
  }
  .configuratorsteps .rotationbox button {
    display: block;
  }
  .rotationbox {
    margin-left: 0px;
  }
  .configuratorsteps {
    width: 100% !important;
    background-color: transparent !important;
  }
  .videos-card button {
    display: block !important;
  }
  .videos-card button img {
    width: 100% !important;
  }

  .NewsOverview-card img {
    max-height: unset !important;
    max-width: unset !important;
  }
  .ProjectGridDetail-inner-imagegrid {
    display: grid !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .Prefix-grid-texts {
    grid-template-columns: 1fr !important;
  }
  .FormFileBlock-CommentSection-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  .FormFileBlock-Fileupload-inner {
    display: block !important;
  }
  #ApplyButton {
    margin-top: 24px;
  }
  #inspect-block-headline {
    transform: translate(-50%, 10px) !important;
    font-size: 24px !important;
  }

  .Configure-block-cards {
    padding-left: 16px;
    padding-right: 16px;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .Configure-block-card {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 540px) {
  .SiteScrollMenu-inner div:last-child {
    padding-top: 24px !important;
  }
  .systemgrid-inner {
    display: flex !important;
    flex-direction: column !important;
  }
  .systemgrid-head .projectblock-filter {
    flex-wrap: wrap !important;
    flex-direction: row !important;
  }

  #ProductCategoryGrid-filter {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .ProductCategoryGrid-GridFilter .Service-inner {
    padding-left: 0px !important;
  }
  .systemgrid-head .projectblock-filter button:hover {
    border: 0px solid transparent !important;
  }
  .activegridbtn {
    background-color: #04486c !important;
    color: white !important;
    border-radius: 24px !important;
  }
  .systemgrid-head .projectblock-filter button {
    width: unset !important;
  }
  .NewsletterNew-left {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Searchresults-Products-Wrap {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
  .UsedComponents-Headline h2 {
    font-size: 32px !important;
  }
}

@media (max-width: 480px) {
  .NeedHelp-Head {
    /*display: block !important;*/
    gap: 16px !important;
  }
  #Block404-Banner-Image img {
    margin-left: -240px;
  }
  .CatalogItem {
    flex-direction: column !important;
  }
  .lang-dev-wrap {
    grid-template-columns: 1fr !important;
  }
  .NeedHelp-img img {
    width: 90px !important;
    height: 90px !important;
  }
  .NeedHelp-img {
    display: block !important;
  }
  .Roadshow-customtext .benefits {
    display: grid !important;
    grid-template-columns: 1fr !important;
    margin-left: -16px;
  }
  .Roadshow-customtext .benefits .col {
    width: 100% !important;
    margin-right: 32px !important;
  }
  .HeaderMainpage-text-left div button,
  #brand-line {
    display: none;
  }
  .Qdiscbtn {
    display: block !important;
    margin-top: 24px !important;
  }
  #Q-disc-scroll {
    display: block;
  }
  #jobcenter-list-headline span {
    font-size: 22px !important;
  }
  .TrainingsOverview-Link-responsive {
    display: flex !important;
  }
  .TrainingsOverview-Link {
    display: none !important;
  }
  .product-cardy-link {
    display: flex !important;
    justify-content: center !important;
  }

  .Searchresults-Products-Wraps .product-cardy-link {
    justify-content: flex-start !important;
  }

  .NeedHelp-Head img {
    float: left;
    margin-right: 8px;
  }
  .header-text-left div {
    margin-left: 10px;
  }
  .HeaderMainpage-text-left div {
    margin-left: 16px;
    margin-right: 16px;
  }
  .servicecards-cards,
  .servicecards2-cards {
    padding-left: 16px;
    padding-right: 16px;
    grid-template-columns: 1fr !important;
  }
  #scroll-video {
    width: 100% !important;
    height: 100% !important;
  }
  .servicecards-header {
    margin-top: 64px !important;
  }
  .servicecards-header p {
    margin-left: 16px;
    margin-right: 16px;
  }
  .UsedComponents-Cards {
    grid-template-columns: 1fr !important;
  }
  .header-text-left div h1 span {
    white-space: unset !important;
  }
  .Configure-block-card h3 {
    font-size: 18px !important;
    max-width: 265px !important;
  }

  .Configure-block-card-btnrow {
    height: 74px !important;
  }
  .Configure-block-card {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .TrainingsOverview-contact-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
  #ThreeCardGridFour {
    grid-template-columns: 1fr !important;
  }
  .TrainingsOverview-inner-headline {
    margin-top: 64px !important;
    margin-bottom: 24px !important;
    text-align: center !important;
  }
  .product-cardy {
    width: 100% !important;
  }
  .product-cardy img {
    width: 100% !important;
    height: 100% !important;
    max-width: 100px !important;
    max-height: 100px !important;
  }

  .product-cardy span {
    font-size: 16px;
    font-weight: 600;
    max-width: 200px;
  }
  .projectdetail-table tr {
    height: unset !important;
    display: grid !important;
  }
  .projectdetail-table tr .spacer {
    display: none !important;
  }
  .flowchart p {
    font-size: 16px !important;
  }
  .projectdetail-table tr td:first-child {
    padding-top: 24px;
  }
  .projectdetail-table tr td:last-child {
    padding-bottom: 24px;
    padding-top: 8px;
  }
  #Block404-Banner-Text-wrap h2 {
    font-size: 32px !important;
  }
  #ConfigMountingOptions {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 140px !important;
    gap: 16px;
  }

  .Systemfinder-Links a {
    padding: 6px 0px !important;
  }
  .Systemfinder-Links-inner {
    gap: 10px;
  }
  #ConfigMountingOptions button {
    height: 100% !important;
    flex-direction: column;
    padding: 0px !important
    ;
    position: relative;
  }
  #ConfigMountingOptions button span {
    order: 1;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #ConfigMountingOptions button span img {
    width: 100% !important;
    height: 100% !important;
    max-width: unset !important;
    max-height: unset !important;
    object-fit: cover;
    padding: 3px !important;
  }
  #ConfigMountingOptions button:last-child {
    margin-top: 0px !important;
  }
  .SystemFinderEntry-cards:first-child {
    width: 100% !important;
    text-align: left;
  }
  .SystemFinderEntry-Subheadline {
    width: 100%;
  }
  .FormFileBlock-CatalogSelection div {
    grid-template-columns: 1fr !important;
    padding-left: 16px;
  }

  #ConfigMountingOptions button p {
    order: 2;
    position: absolute;
    bottom: -20px;
    font-weight: 600;
    font-size: 13px !important;
    left: 0px;
  }
  .articletable tr td {
    padding-right: 2px !important;
  }
  .articletable tr td:last-child {
    max-width: 40px;
    border-left: 1px solid #f2f3f4;
    padding-left: 4px;
    min-width: 20px;
    border-bottom: 1px solid #f2f3f4;
  }
  .ConfigPrivateForm-NameRow {
    flex-direction: column !important;
  }
  .articletable tr td:nth-child(1) {
    max-width: 100px;
    padding-left: 8px;
    min-width: 80px;
    padding-right: 8px;
    border-bottom: 1px solid #f2f3f4;
  }
  .SystemFinder-innerHead h2 {
    margin-top: 0px !important;
  }
  .flowchart .hidden {
    display: none !important;
  }
  .articletable tr td:nth-child(2) {
    max-width: 100px;
    padding-left: 4px;
    min-width: 80px;
    border-left: 1px solid #f2f3f4;
    border-bottom: 1px solid #f2f3f4;
    padding-right: 8px;
  }
  .articletable tr td:nth-child(3) {
    max-width: 80px;
    border-left: 1px solid #f2f3f4;
    border-bottom: 1px solid #f2f3f4;
    word-break: break-all;
    min-width: 80px;
    padding-left: 4px;
  }
  .SystemFinderEntry-cards-inner {
    grid-template-columns: 50% 50% !important;
    padding-right: 16px;
  }
  .SystemFinderEntry-Card img {
    width: 100% !important;
    height: 100% !important;
    max-width: 201px !important;
    max-height: 143px !important;
  }
  .SystemFinderEntry-Card:nth-child(3),
  .SystemFinderEntry-Card:nth-child(4) {
    margin-top: 8px;
  }
  #Headerprojects-second-system {
    margin-top: 4px !important;
    margin-left: 0px !important;
  }
  .header-text-projects div {
    margin-top: 142px !important;
  }
  .ProjectsNewFlags {
    margin-top: 16px !important;
  }

  .ProjectsNewFlag-flag {
    margin-top: 0px !important;
  }
  .SystemSearchLinks {
    grid-template-columns: 1fr 1fr !important;
  }
  .Config-step10-modal-ButtonLine {
    flex-direction: column;
    justify-content: flex-start;
  }
  .Configurator-Modal-step10-outer {
    width: 95%;
  }
}

@media (max-width: 415px) {
  h2 {
    hyphens: auto;
  }
  .SystemFinderEntry-Card:nth-child(3),
  .SystemFinderEntry-Card:nth-child(4) {
    margin-top: 24px;
  }
  .flowchart p {
    font-size: 14px !important;
  }
  .systemfinder-grid .systemgrid-card img {
    max-width: 300px !important;
    max-height: 208px !important;
  }
}

@media (max-width: 375px) {
  .Searchresults-Products-Wrap {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  #Block404-Banner-Image img {
    margin-left: -340px;
  }
  .NeedHelp-Head img {
    float: unset !important;
    margin-right: 8px;
  }
  .Searchresults-Products-Wrap {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .Servicesearch-search {
    flex-direction: column;
  }
  .Servicesearch-search button {
    margin: 0 auto;
  }
  .flowchart p {
    font-size: 14px !important;
  }
  .SystemFinderEntry-Card:nth-child(3),
  .SystemFinderEntry-Card:nth-child(4) {
    margin-top: 32px;
  }
  .SystemFinderEntry-cards-inner {
    padding-left: 16px !important;
  }
}

@media (max-width: 360px) {
  .Systemfinder-Links-inner {
    flex-direction: column !important;
  }
}

@media (max-width: 330px) {
  .Configurator-step11-confilink #configlink {
    margin-left: 16px !important;
  }
  .NeedHelp-Head {
    display: block !important;
    gap: 16px !important;
  }
  .bigsize-button span {
    text-align: left !important;
  }
  .SystemFinderEntry-Card:nth-child(3),
  .SystemFinderEntry-Card:nth-child(4) {
    margin-top: 0px;
  }
  .SystemFinderEntry-cards-inner {
    padding-left: 0px !important;
  }
  .Used-Components-Card img {
    max-width: 100% !important;
    min-width: unset !important;
  }
  .SystemFinderEntry-cards-inner {
    display: flex !important;
    flex-direction: column !important;
  }
}

/*  Responsive for HEIGHT restrictions */

@media (max-height: 750px) {
  .HeaderMainpage-Submenu li a {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .HeaderMainpage-Submenu {
    padding-bottom: 72px;
  }
}

@media (max-height: 590px) {
  .ResponsiveMenuHeaderLink-button {
    height: 54px !important;
  }
}
