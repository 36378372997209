.TwoColBlock-wrap {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
}

.TwoColBlock-inner {
  max-width: 1096px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px;
  justify-content: space-between;
}

.TwoColBlock-inner ul {
  margin-top: 8px;
  margin-bottom: 8px;
}
.TwoColBlock-inner li {
  position: relative;
  margin-top: 8px;
  color: #667079;
  list-style: none;
  margin-left: 0px;
  padding-left: 16px;
}
.TwoColBlock-inner li::before {
  content: '•';
  color: #667079;
  position: absolute;
  left: 0px;
  top: 0;
}
.TwoColBlock-right h1,
.TwoColBlock-left h1 {
  font-size: 48px;
  color: #001020;
  font-weight: 600;
}
.TwoColBlock-left img,
.TwoColBlock-right img {
  width: 100%;
  height: 100%;
}
.TwoColBlock-right p,
.TwoColBlock-left p {
  color: #667079;
  margin-top: 8px;
}
.TwoColBlock-right,
.TwoColBlock-left {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2px;
}
.TwoColBlock-right div {
  display: flex;
  gap: 8px;
}
.TwoColBlock-right div a:last-child {
  background-color: #001020;
}
.TwoColBlock-right a,
.TwoColBlock-left a {
  background-color: #04468c;
  text-decoration: none;
  font-weight: 400;
  color: white;
  padding: 16px 32px;
  border-radius: 24px;
  max-width: 180px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  margin-top: 24px;
}

#TwoColBlockGrey {
  background-color: #f2f3f4;
}
.TwoColBlock-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TwoColBlock-left-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
#TwoColBlock-top {
  align-items: flex-start;
}
