.ProfileConfigurations-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 120px;
}

.ProfileConfigurations-inner {
  max-width: 1096px !important;
  width: 100%;
}
.ProfileConfigurations-inner table {
  width: 100%;
}
.ProfileConfigurations-inner table tr td {
  padding-top: 16px;
  padding-bottom: 8px;
}
.ProfileConfigurations-table-head td {
  padding-bottom: 4px;
  border-bottom: 1px solid #f2f3f4;
}
.ProfileConfigurations-inner table td a {
  padding: 8px 16px;
  background-color: #04468c;
  color: white;
  border-radius: 4px;
  border: 0px;
}
.ProfileConfigurations-inner table td a:hover {
  opacity: 0.5;
  transition: 0.3s;
}
