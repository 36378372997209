.faq-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f2f3f4;
  padding-top: 120px;
  padding-bottom: 120px;
}
.faq-inner {
  max-width: 1096px;
  width: 100%;
}
.faq-inner h2 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
}
.faq-question-warp .visibilityclass div {
  flex-direction: column;
  align-items: flex-start !important;
}
.faq-question-warp {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #f2f3f4;
}
.faq-question-warp:last-child {
  border-bottom: 1px solid #f2f3f4;
}

.faq-toggle-buttons:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.faq-toggle-buttons svg {
  position: absolute;
}
#faq-grey-quest {
  border-top: 1px solid #cccfd2;
}
#faq-grey-quest:last-child {
  border-bottom: 1px solid #cccfd2;
}
#faq-grey-quest button {
  background-color: white;
}
#faq-white-wrap {
  background-color: white;
}
#faq-white-quest {
  border-top: 1px solid #f2f3f4;
}
#faq-white-quest:last-child {
  border-bottom: 1px solid #f2f3f4;
}
#faq-white-quest button {
  background-color: #f2f3f4;
}

.faq-question-warp div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ServiceFaqButtons button {
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 24px;
}
.ServiceFaqButtons {
  display: flex;
  width: 100%;
  margin-bottom: 64px;
  gap: 8px;
}
.faq-question-warp div:first-child button {
  width: 48px;
  height: 48px;
  background-color: #f2f3f4;
  border-radius: 24px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
}
.faq-question-warp div:first-child p {
  font-size: 24px;
  font-weight: 600;
  max-width: 820px;
  cursor: pointer;
}
.faq-question-answers {
  display: none;
  margin-top: 16px;
}
.activebtnService {
  background-color: #04468c;
  color: white;
}
.faq-inner h2 {
  margin-bottom: 48px;
}

.visibilityclass {
  display: block !important;
  animation: faqopen 150ms;
}
.faq-active-btn {
  background-color: #04468c !important;
  color: white;
}
.faq-question-answers div:last-child p {
  font-size: 16px !important;
  color: #667079;
  font-weight: 400 !important;
}
.faq-question-answers div a {
  color: #667079;
}
#FAQ-shadowdiv {
  width: 100%;
  height: 240px;
}
.faq-question-question {
  cursor: pointer;
}
.faq-question-answers ul {
  margin-top: 8px !important;
}
.faq-question-answers ul li,
.faq-question-answers ol li {
  margin-left: 16px;
  margin-top: 4px;
  color: #667079;
}

.faq-question-answers p {
  margin-top: 24px !important;
}
.faq-question-answers p:first-child {
  margin-top: 0px !important;
}

@keyframes faqopen {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
