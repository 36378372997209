.ProfileAddressBook-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 120px;
}
.ProfileAddressBook-inner {
  width: 100%;
  max-width: 1096px;
  display: flex;
  flex-direction: column;
}

.ProfileAddressBook-Addresses h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}
.ProfileAddressBook-Addresses h4 {
  font-size: 16px;
}
.ProfileAddressBook-Addresses span {
  white-space: nowrap;
  display: flex;
  margin-top: 8px;
}
.ProfileAddressBook-Addresses span p:first-of-type {
  min-width: 150px;
}
.ProfileAddressBook-Addresses span p:last-of-type {
  font-weight: 600;
}
.ProfileAddressBook-Addresses {
  display: flex;
  gap: 128px;
}
