#CategoryGridPage-Headline-subheader {
  margin-top: 16px;
  color: #667079;
}
#CategoryGridPage-grid-inner {
  margin-top: 64px;
}
#CategoryGridPage-Headline-wrap {
  padding-bottom: 64px;
  background-color: unset !important;
}
.CategoryGridPage-filter {
  margin-top: 48px !important;
  margin-bottom: 0px !important;
}
#ProductCategoryGrid-rework {
  display: grid;
  grid-template-columns: 300px 1fr;
}
#ProductCategoryGrid-rework .ProductCategoryGrid-GridFilter #ProductCategoryGrid-filter {
  flex-direction: column;
  align-items: flex-start;
  padding-right: 24px;
}
#ProductCategoryGrid-rework .ProductCategoryGrid-GridFilter #ProductCategoryGrid-filter button {
  background-color: unset;
  text-align: left;
  font-size: 16px;
}
#ProductCategoryGrid-rework .ProductCategoryGrid-GridFilter #ProductCategoryGrid-filter .activegridbtn {
  color: #001020 !important;
  font-weight: 600;
  background-color: unset !important;
  height: unset !important;
  border-left: 4px solid #04468c;
  padding-left: 16px;
}
#ProductCategoryGrid-rework .ProductCategoryGridCard {
  background-repeat: no-repeat;
  position: relative;
}
.CategoryGirdPage-LinkIntoShop {
  position: absolute;
  bottom: 16px;
  left: 20px;
  font-size: 12px;
  border-bottom: 1px solid #667079;
  /* border: 1px solid #667079;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);*/
  color: #667079 !important;
}
.CategoryGirdPage-LinkIntoShop svg {
  padding-top: 1px;
}
#ProductCategoryGrid-rework .CategoryGridPage-filter {
  margin-top: 0px !important;
}
#ProductCategoryGrid-rework .CategoryGridPage-filter button {
  height: auto !important;
  gap: 8px !important;
  border-radius: 0px;
  padding-top: 8px;
  padding-left: 0px;
  padding-bottom: 8px;
}
#ProductCategoryGrid-rework .CategoryGridPage-filter button:hover {
  border-radius: 0px;
  border-left: 4px solid #04468c;
  transition: 0.3s;
  padding-left: 16px;
}
