.AdminCustomerDetail-innerData p {
  font-weight: 600;

  color: #001020;
}
.AdminCustomerDetail-innerData span {
  font-weight: 400;
  color: #667079;
}
.AdminCustomerDetail-innerData h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.AdminCustomerDetail-DataUnit {
  display: flex;
  max-width: 640px;
  width: 100%;
  justify-content: space-between;
}
.AdminCustomerDetail-AvData {
  display: flex;
  gap: 24px;
}

.AdminCustomerDetail-innerData button {
  background-color: #04468c;
  padding: 8px 16px;
  border-radius: 24px;
  margin-top: 48px;
  color: white;
  border: none;
}
.AdminCustomerDetail-innerData:nth-child(2) {
  padding-top: 48px;
  padding-top: 48px;
}
#BackToAdminAreaLink {
  background-color: #001020;
  color: #fff;
  padding: 16px 32px;
  border-radius: 24px;
}
#BackToAdminAreaLink svg {
  transform: rotate(-90deg);
  margin-bottom: -2px;
  margin-right: 8px;
}
#AdminCustomerOverview-ResetFilter {
  background-color: #001020;
  border: 0px;
}
